<template>
  <div class="vouchers-view mb-3">
    <page-title :title="translation('my_vouchers')" :showFiltersIcon="true" icon="gift"/>
    <voucher-filters-bar :showStatus="true"/>
    <vouchers-items-list />
    <voucher-transfer-modal />
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import VoucherFiltersBar from '@/components/vouchers/VoucherFiltersBar'
import { mapGetters } from 'vuex'
import VouchersItemsList from '@/components/vouchers/VouchersItemsList'
import VoucherTransferModal from '@/components/vouchers/VoucherTransferModal.vue'

export default {
  name: 'VouchersPanel',
  components: { VoucherTransferModal, VouchersItemsList, VoucherFiltersBar, PageTitle },
  computed: {
    ...mapGetters(['translation'])
  }
}
</script>

<style scoped>

</style>
