export default {
  purchases: [],
  currentAd: null,
  filters: {
    name: '',
    id: '',
    code: ''
  },
  orderOptions: [
    { value: 'created', text: 'buying_date' },
    { value: 'total_paid', text: 'price' }
  ],
  order: 'DESC',
  orderField: 'created',
  perPage: 16,
  page: 0,
  purchasesInfiniteId: +new Date() + 270000
}
