<template>
  <b-row class="vouchers-list">
    <voucher-card
      v-for="(voucher, idx) of vouchers"
      :key="idx"
      :voucher="voucher"
    />
  </b-row>
</template>

<script>
import VoucherCard from './VoucherCard'
import { mapGetters } from 'vuex'

export default {
  name: 'VouchersList',
  components: { VoucherCard },
  computed: {
    ...mapGetters('vouchers', ['vouchers'])
  }
}
</script>

<style scoped>

</style>
