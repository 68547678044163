<template>
  <div
    v-if="showButton"
    class="btn add-to-cart-btn d-flex"
    :class="{ 'justify-content-center': isMobileView }"
    :style="buttonStyles(hovering)"
    ref="addToCart"
  >
    <div class="form-inline">
      <count-select :count="itemsCount" :max="maxUnits" @change="changeCount"/>
    </div>
    <div
      class="px-2"
      @click.stop="addProductToCart"
    >
      <font-awesome-icon :icon="icon" class="px-2 py-2"/>
      <span v-if="showLabel && !shortLabel && atGiftsSection" class="ml-2">{{ translation('select_gifts') }}</span>
      <span v-if="showLabel && shortLabel && atGiftsSection" class="ml-2">{{ translation('gifts') }}</span>
      <span v-if="showLabel && !shortLabel && !atGiftsSection" class="ml-2">{{ translation('add_to_cart') }}</span>
      <span v-if="showLabel && shortLabel && !atGiftsSection" class="ml-2">{{ translation('add') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CountSelect from '@/components/cart/CountSelect'

export default {
  name: 'AddCartBtn',
  components: { CountSelect },
  props: ['ad', 'section', 'inCard'],
  data: function () {
    return {
      itemsCount: 1,
      isMounted: false,
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'isMobileView']),
    ...mapGetters('clients', ['buttonStyles', 'client', 'clientInfo']),
    ...mapGetters('auth', ['role', 'invited']),
    ...mapGetters('ads', ['doNotSelectGifts']),
    shortLabel () {
      if (this.isMounted && this.$refs.addToCart) {
        return this.$refs.addToCart.clientWidth < 220
      }
      return true
    },
    atGiftsSection () {
      return this.section === 'gifts'
    },
    icon () {
      return this.section === 'gifts' ? 'gift' : 'cart-plus'
    },
    maxUnits () {
      return this.ad.stock
    },
    showButton () {
      return this.ad.canBeBought && (!this.invited || this.atGiftsSection)
    },
    showLabel () {
      return !this.isMobileView || !this.inCard
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('aggregatableAds', ['setCurrentCount']),
    addProductToCart: function () {
      if (!this.doNotSelectGifts && this.ad.can_aggregate) {
        this.setCurrentCount(this.itemsCount)
        this.$router.push(`/ad-select/${this.client}/${this.ad.id_ad}`)
      } else {
        this.addToCart({
          id: this.ad.id_ad,
          price: parseFloat(this.ad.to_pay_vat_included),
          count: this.itemsCount
        })
      }
      // var message = this.translation('added_to_cart')
      // message_box1(this.translation('information'), message.replace('%s', this.ad,name), {timeout: 1500, type: 'success'})
    },
    changeCount: function (count) {
      this.itemsCount = count
    }
  },
  mounted () {
    const obj = this
    setTimeout(() => {
      obj.isMounted = true
    }, 0)
  }
}
</script>

<style lang="scss" scoped>
.add-to-cart-btn {
  &:hover {
    opacity: 0.7;
  }
}
</style>
