<template>
  <div v-if="summaryData" class="">
    <div class="summary mb-3">
      <div class="pb-2 d-flex flex-column flex-sm-row">
        <div>
          Solicitudes enviados: <span class="text-primary">{{ summaryData.sent }}</span>
        </div>
        <div class="pl-sm-3">
          Solicitudes recibidos: <span class="text-success">{{ summaryData.received }}</span>
        </div>
        <div class="pl-sm-3">
          Resultado: <span class="text-danger">{{ totalResultSign }}{{ Math.abs(totalResult) }}</span>
        </div>
      </div>
      <div class="py-2 d-flex flex-column border-bottom flex-sm-row">
        <div>
          Validados enviados: <span class="text-primary">{{ summaryData.completedSent }}</span>
        </div>
        <div class="pl-sm-3">
          Validados recibidos: <span class="text-success">{{ summaryData.completedReceived }}</span>
        </div>
        <div class="pl-sm-3">
          Resultado: <span class="text-danger">{{ resultSign }}{{ Math.abs(result) }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="!isMobileView"
      class="filter-links d-flex flex-column d-md-block pb-2"
    >
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'pending' }"
        @click="toggleFilter('pending')"
      >
        <font-awesome-icon icon="circle" class="text-warning"/>
        Pendiente
        <span class="status-name ml-1">{{ summaryData.pending }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'reserved' }"
        @click="toggleFilter('reserved')"
      >
        <font-awesome-icon icon="circle" class="text-success"/>
        Reservado
        <span class="status-name ml-1">{{ summaryData.reserved }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'didNotAssist' }"
        @click="toggleFilter('didNotAssist')"
      >
        <font-awesome-icon icon="circle" class="text-dark"/>
        No asistencia a la visita
        <span class="status-name ml-1">{{ summaryData.didNotAssist }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'disabled' }"
        @click="toggleFilter('disabled')"
      >
        <font-awesome-icon icon="circle" class="text-danger"/>
        Deshabilitado
        <span class="status-name ml-1">{{ summaryData.disabled }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'completed' }"
        @click="toggleFilter('completed')"
      >
        <font-awesome-icon icon="circle" class="text-primary"/>
        Completado
        <span class="status-name ml-1">{{ summaryData.completed }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'contracted' }"
        @click="toggleFilter('contracted')"
      >
        <font-awesome-icon icon="circle" class="text-info"/>
        Contratado
        <span class="status-name ml-1">{{ summaryData.contracted }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 mr-md-2 filter-link d-md-inline-block"
        :class="{ 'active': activeFilter === 'rejected' }"
        @click="toggleFilter('rejected')"
      >
        <font-awesome-icon icon="circle" class="text-secondary"/>
        Rechazado
        <span class="status-name ml-1">{{ summaryData.rejected }}</span>
      </div>
      <div
        class="mb-2 mb-md-0 icon-link d-md-inline-block"
        @click="clearFilters()"
        title="Inicializar el filtro"
      >
        <font-awesome-icon icon="sync-alt"/>
        <span class="d-inline d-md-none ml-2">Inicializar filtro</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralStatus',
  computed: {
    ...mapGetters('vouchers', ['summary', 'activeFilter']),
    ...mapGetters(['isMobileView']),
    summaryData () {
      return this.summary
    },
    result () {
      return this.summary.completedReceived - this.summary.completedSent
    },
    resultSign () {
      if (this.result > 0) {
        return '+'
      }
      if (this.result < 0) {
        return '-'
      }
      return ''
    },
    totalResult () {
      return this.summary.received - this.summary.sent
    },
    totalResultSign () {
      if (this.totalResult > 0) {
        return '+'
      }
      if (this.totalResult < 0) {
        return '-'
      }
      return ''
    }
  },
  methods: {
    ...mapActions('vouchers', ['toggleFilter', 'clearFilters'])
  }
}
</script>

<style lang="scss" scoped>
  .filter-link {
    padding: 0.1rem;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    &.active {
      background-color: #eee;
    }
  }
</style>
