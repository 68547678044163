<template>
  <div id="home-page">
    <iframe
      v-if="homeIframeURL"
      :src="homeIframeURL"
      style="width: 100%; max-width: 100%; height: 100vh"
      allow="fullscreen"
      title=""
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeView',
  computed: {
    ...mapGetters(['translation', 'activePage']),
    ...mapGetters('clients', ['defaultPage', 'homeIframeURL'])
  },
  methods: {
    ...mapActions(['setActivePage', 'setSidebarVisibility']),
    ...mapActions('clients', ['initClient', 'initClientBySlug'])
  },
  created () {
    this.setSidebarVisibility(false)
    if (this.$route.params.slug) {
      this.initClientBySlug(this.$route.params.slug)
        .then(() => {
          // this.$router.push(this.defaultPage)
        })
    } else if (this.$route.params.id) {
      this.initClient(this.$route.params.id)
        .then(() => {
          // this.$router.push(this.defaultPage)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  iframe {
    border: none;
  }
</style>
