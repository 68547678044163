<template>
  <div class="collaborators-view mb-3">
    <page-title :title="translation('collaborators_network')" :icon="'handshake-simple'"/>
    <suggested-users v-if="showSuggested"/>
    <collab-panel/>
    <user-profile/>
  </div>
</template>

<script>
import SuggestedUsers from '@/components/clients/SuggestedUsers'
import CollabPanel from '@/components/clients/CollabPanel'
import UserProfile from '@/components/clients/UserProfile'
import PageTitle from '@/components/common/PageTitle'
import { mapGetters } from 'vuex'

export default {
  name: 'CollaboratorsPanel',
  components: { PageTitle, UserProfile, CollabPanel, SuggestedUsers },
  data () {
    return {
      showSuggested: false
    }
  },
  computed: {
    ...mapGetters(['translation'])
  },
  mounted () {
    const obj = this
    setTimeout(() => {
      obj.showSuggested = true
    }, 500)
  }
}
</script>

<style scoped>

</style>
