import Vue from 'vue'

export default {
  initAggregatableAds: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      commit('SET_SELECTED_CHANGED', true)
      setTimeout(() => {
        commit('SET_SELECTED_CHANGED', false)
        const requestData = getters.requestData(payload)
        Vue.http.post(`${rootGetters.siteUrl}/sponsor/get_items`, requestData)
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
          .then(data => {
            commit('SET_AGGREGATABLE_ADS', data.ads)
            commit('categories/SET_CATEGORIES', data.filters.categories, { root: true })
            resolve(data.ads)
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
      })
    })
  },
  loadAggregatableAds: ({ getters, commit, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      commit('SET_SELECTED_CHANGED', true)
      setTimeout(() => {
        commit('SET_SELECTED_CHANGED', false)
        Vue.http.post(`${rootGetters.siteUrl}/sponsor/get_items`, getters.requestData(payload))
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
          .then(data => {
            commit('ADD_AGGREGATABLE_ADS', data.ads)
            resolve(data.ads)
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
      })
    })
  },
  setAdTypes: ({ commit }, adTypes) => {
    commit('SET_AD_TYPES', adTypes)
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initAggregatableAds')
  },
  resetFilters: ({ commit, dispatch }) => {
    commit('RESET_FILTERS')
    dispatch('initAggregatableAds')
  },
  setOrder: ({ commit, dispatch }, order) => {
    commit('SET_ORDER', order)
    dispatch('initAggregatableAds')
  },
  setOrderField: ({ commit, dispatch }, orderField) => {
    commit('SET_ORDER_FIELD', orderField)
    dispatch('initAggregatableAds')
  },
  setCurrentCount: ({ getters, commit, dispatch }, count) => {
    if (getters.currentCount > count) {
      dispatch('resetSelected')
    }
    commit('SET_SELECTED_CHANGED', true)
    commit('SET_CURRENT_COUNT', count)
    setTimeout(() => {
      commit('SET_SELECTED_CHANGED', false)
    })
    dispatch('initAggregatableAds')
  },
  addSelected: ({ commit, dispatch }, ad) => {
    commit('ADD_SELECTED', ad)
    dispatch('initAggregatableAds')
  },
  removeSelected: ({ commit, dispatch }, ad) => {
    commit('REMOVE_SELECTED', ad)
    commit('SET_PAGE', 0)
    dispatch('initAggregatableAds')
  },
  resetSelected: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_SELECTED_ADS', [])
      commit('SET_AGGREGATABLE_ADS', [])
      commit('SET_SELECTED_BY_ID', {})
      commit('SET_SELECTED_COUNT', {})
      commit('RESET_FILTERS')
      commit('SET_PAGE', 0)
      commit('SET_SELECTED_CHANGED', true)
      setTimeout(() => {
        commit('SET_SELECTED_CHANGED', false)
      })
      resolve()
    })
  }
}
