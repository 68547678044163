<template>
  <div
    v-show="visible"
    id="collab-panel"
    ref="users-list"
  >
    <b-navbar type="dark" variant="light" class="page-title mb-3">
      <b-nav card-header pills>
        <collab-nav-button
          section="approved"
          :label="'my_collaborators'"
          :action="showApproved"
          :active="active"
          :count="approvedCount"
        />
        <collab-nav-button
          section="to_approve"
          :label="'to_be_approved'"
          :action="showToApprove"
          :active="active"
          :count="toApproveCount"
        />
        <collab-nav-button
          section="pending"
          :label="'my_requests'"
          :action="showPending"
          :active="active"
          :count="pendingCount"
        />
        <collab-nav-button
          section="search"
          :label="'search_for_companies'"
          :active="active"
          :action="showSearch"
        />
      </b-nav>
    </b-navbar>
    <div v-if="bodyVisible">
      <div v-if="active === 'approved'">
        <approved-list/>
      </div>
      <div v-if="active === 'to_approve'">
        <to-approve-list/>
      </div>
      <div v-if="active === 'pending'">
        <pending-list/>
      </div>
      <search-panel v-if="active === 'search'"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApprovedList from './ApprovedList'
import PendingList from './PendingList'
import SearchPanel from './SearchPanel'
import ToApproveList from './ToApproveList'
import CollabNavButton from '@/components/clients/CollabNavButton.vue'

export default {
  name: 'CollabPanel',
  components: { CollabNavButton, ToApproveList, SearchPanel, PendingList, ApprovedList },
  data () {
    return {
      active: ''
    }
  },
  mounted () {
    this.initSummary()
    this.showApproved()
  },
  computed: {
    ...mapGetters('relations', ['approvedCount', 'pendingCount', 'toApproveCount']),
    ...mapGetters('users', ['selectedUser']),
    ...mapGetters('clients', ['customBandStyles']),
    bodyVisible () {
      return (this.active === 'approved' && this.approvedCount > 0) || (this.active === 'pending' && this.pendingCount > 0) ||
        (this.active === 'to_approve' && this.toApproveCount > 0) || this.active === 'search'
    },
    visible () {
      return this.selectedUser === null
    }
  },
  methods: {
    ...mapActions('relations', ['initSummary', 'initApproved', 'initToApprove', 'initPending']),
    showApproved () {
      if (this.active === 'approved') {
        this.active = ''
      } else {
        this.active = 'approved'
        this.initApproved()
      }
    },
    showToApprove () {
      if (this.active === 'to_approve') {
        this.active = ''
      } else {
        this.active = 'to_approve'
        this.initToApprove()
      }
    },
    showPending () {
      if (this.active === 'pending') {
        this.active = ''
      } else {
        this.active = 'pending'
        this.initPending()
      }
    },
    showSearch () {
      if (this.active === 'search') {
        this.active = ''
      } else {
        this.active = 'search'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
