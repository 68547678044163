import { render, staticRenderFns } from "./AdSelectItem.vue?vue&type=template&id=9d8bc152&scoped=true&"
import script from "./AdSelectItem.vue?vue&type=script&lang=js&"
export * from "./AdSelectItem.vue?vue&type=script&lang=js&"
import style0 from "./AdSelectItem.vue?vue&type=style&index=0&id=9d8bc152&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8bc152",
  null
  
)

export default component.exports