export default {
  currentUser: 0,
  selectedUser: null,
  foundUser: null,
  users: [],
  usersCount: 0,
  perPage: 16,
  page: 0,
  infiniteId: +new Date() + 120000,
  filters: {
    name: '',
    email: '',
    phone: ''
  }
}
