<template>
  <b-card
    v-if="ad"
    class="ad-marker p-0 border-0"
    no-body
  >
    <b-card-body class="p-0">
      <b-media class="mb-2">
        <template #aside>
          <b-img
            v-if="ad.preview"
            :src="ad.preview"
            width="80"
            :alt="ad.name"
          />
        </template>
        <div class="">
          <h6 class="mt-0">{{ ad.name }}</h6>
          <small>{{ ad.activity_name }}</small>
        </div>
      </b-media>
      <b-card-text>
        {{ ad.address }}
      </b-card-text>
      <b-button
        :href="ad.gmap_url"
        target="_blank"
        size="sm"
        variant="primary"
      >
        <font-awesome-icon icon="map-location-dot"/>
        {{ translation('directions') }}
      </b-button>
      <b-button
        :href="ad.view_url"
        size="sm"
        variant="primary"
        class="ml-2"
      >
        <font-awesome-icon icon="eye"/>
        {{ translation('view') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdMarker',
  props: ['ad'],
  computed: {
    ...mapGetters(['translation'])
  }
}
</script>

<style lang="scss" scoped>
.ad-marker {
  width: 14rem;
  max-width: 14rem;
}
</style>
