<template>
  <b-modal
    id="voucher-send-modal"
    :title="translation('send_voucher')"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div v-if="ad">
      <b-media class="mb-3">
        <template #aside>
          <b-img :src="ad.image" width="60" :alt="ad.name"></b-img>
        </template>
        <div class="mb-3">
          {{ ad.name }}
        </div>
      </b-media>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group>
          <b-input-group>
            <template #prepend>
              <b-button variant="outline-secondary" disabled>
                <font-awesome-icon icon="phone"/>
              </b-button>
            </template>
            <b-input
              :placeholder="translation('phone')"
              @input="clearFoundUser"
              v-model="phone"
            />
            <template #append>
              <b-button
                variant="success"
                :disabled="phone === ''"
                @click="searchPhone"
              >
                <font-awesome-icon icon="search"/>
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-input-group>
            <template #prepend>
              <b-button variant="outline-secondary" disabled>
                <font-awesome-icon icon="envelope"/>
              </b-button>
            </template>
            <b-input
              :placeholder="translation('email')"
              @input="clearFoundUser"
              v-model="email"
            />
            <template #append>
              <b-button
                variant="success"
                :disabled="email === ''"
                @click="searchEmail"
              >
                <font-awesome-icon icon="search"/>
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-input
            v-show="!foundUser && validEmail"
            v-model="name"
            :placeholder="translation('name')"
          />
        </b-form-group>
        <b-form-row v-if="foundUser">
          <b-col cols="12" sm="6">
            <b-form-group label="Nombre">
              <b-input disabled :value="foundUser.full_name"/>
            </b-form-group>
          </b-col>
          <b-col v-if="!email" cols="12" sm="6">
            <b-form-group :label="translation('email')">
              <b-input disabled :value="foundUser.email"/>
            </b-form-group>
          </b-col>
          <b-col v-if="!phone && foundUser.phone" cols="12" sm="6">
            <b-form-group :label="translation('phone')">
              <b-input disabled :value="foundUser.phone"/>
            </b-form-group>
          </b-col>
        </b-form-row>
      </form>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        v-if="showSendButton"
        size="sm"
        variant="success"
        @click="ok()"
      >
        <font-awesome-icon icon="check"/>
        {{ translation('send') }}
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        <font-awesome-icon icon="ban"/>
        {{ translation('cancel') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'VoucherSendModal',
  data () {
    return {
      email: '',
      phone: '',
      name: ''
    }
  },
  computed: {
    ...mapGetters(['busy', 'translation']),
    ...mapGetters('ads', ['currentAd']),
    ...mapGetters('sysUsers', ['foundUser']),
    ad () {
      return this.currentAd ? this.currentAd : null
    },
    validEmail () {
      return this.email && this.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    },
    showSendButton () {
      return !this.busy && (this.foundUser || (this.validEmail && this.name))
    }
  },
  methods: {
    ...mapActions('vouchers', ['sendVoucher']),
    ...mapActions('sysUsers', ['clearFoundUser', 'searchUser']),
    resetModal () {
      this.email = ''
      this.phone = ''
      this.name = ''
      this.clearFoundUser()
    },
    searchEmail () {
      this.searchUser({ email: this.email })
      this.phone = ''
    },
    searchPhone () {
      this.searchUser({ phone: this.phone })
      this.email = ''
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      if (this.foundUser || (this.email && this.name)) {
        this.$bvModal.hide('voucher-send-modal')
        const data = {
          id: this.currentAd.id_ad,
          name: this.name
        }
        if (this.foundUser) {
          data.email = this.foundUser.email
        } else if (this.email) {
          data.email = this.email
        }
        this.sendVoucher(data)
          .then(response => {
            return response.json()
          })
          .then(data => {
            if (data.success === 'true') {
              this.makeToast(data.heading, data.message, 'success')
              this.$nextTick(() => {
                this.$bvModal.hide('voucher-send-modal')
                this.resetModal()
              })
            } else {
              this.makeToast(data.heading, data.message, 'danger')
            }
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
