import Vue from 'vue'

export default {
  setCurrentUser: ({ commit }, user) => {
    commit('SET_CURRENT_USER', user)
  },
  setSelectedUser: ({ commit }, user) => {
    commit('SET_SELECTED_USER', user)
  },
  clearSelectedUser: ({ commit }) => {
    commit('SET_SELECTED_USER', null)
  },
  setUsers: ({ commit }, users) => {
    commit('SET_USERS', users)
  },
  setUsersCount: ({ commit }, usersCount) => {
    commit('SET_USERS_COUNT', usersCount)
  },
  setFilters: ({ commit }, filters) => {
    commit('SET_FILTERS', filters)
  },
  resetFilters: ({ commit }, filters) => {
    commit('SET_FILTERS', {
      name: '',
      activity: '',
      distance: '',
      city: '',
      not_collaborating: 1
    })
  },
  updateInfiniteId: ({ commit }) => {
    commit('UPDATE_INFINITE_ID')
  },
  initCurrentUser: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${rootGetters.siteUrl}/admin/profiles/my_profile`, { user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get user profile!'))
        })
        .then(data => {
          commit('SET_CURRENT_USER', data.user)
          // console.log('My profile: ', data.user)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get user profile!'))
        })
    })
  },
  initUsers: ({ getters, commit, dispatch, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      Vue.http.post(`${rootGetters.siteUrl}/admin/profiles/search`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          dispatch('initFilterValues')
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get users!'))
        })
        .then(data => {
          commit('SET_USERS', data.users)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get users!'))
        })
    })
  },
  loadUsers: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      Vue.http.post(`${rootGetters.siteUrl}/admin/profiles/search`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get users!'))
        })
        .then(data => {
          commit('ADD_USERS', data.users)
          resolve(data.users)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get users!'))
        })
    })
  },
  initFilterValues: ({ getters, commit, dispatch, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const requestData = getters.requestData
      Vue.http.post(`${rootGetters.siteUrl}/admin/profiles/getFilters`, requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get users!'))
        })
        .then(data => {
          if (data.filters.activities) {
            dispatch('activities/setActivities', data.filters.activities, { root: true })
            delete data.filters.activities
          }
          commit('SET_FILTER_VALUES', data.filters)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get users!'))
        })
    })
  },
  updateUser: ({ commit }, userData) => {
    commit('UPDATE_USER', userData)
  }
}
