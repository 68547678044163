import Vue from 'vue'

export default {
  initNotifications: ({ commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      Vue.http.post(`${rootGetters.siteUrl}/notifications/get`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get notifications!'))
        })
        .then(data => {
          commit('SET_NOTIFICATIONS', data.notifications)
          resolve(data.notifications)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get notifications!'))
        })
    })
  },
  loadNotifications: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      Vue.http.post(`${rootGetters.siteUrl}/notifications/get`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get notifications!'))
        })
        .then(data => {
          commit('ADD_NOTIFICATIONS', data.notifications)
          resolve(data.notifications)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get notifications!'))
        })
    })
  },
  doAction: ({ commit, getters, dispatch, rootGetters }, notification) => {
    return new Promise((resolve, reject) => {
      if (notification.id_notification && notification.uri) {
        commit('SET_BUSY', true, { root: true })
        Vue.http.post(`${rootGetters.siteUrl}${notification.uri}`)
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not apply action!'))
          })
          .then(data => {
            if (data.success === 'true') {
              dispatch('setActionDone', notification.id_notification)
              resolve(data)
            } else {
              reject(new Error('Could not apply action!'))
            }
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not apply action!'))
          })
      } else {
        reject(new Error('Could not apply action!'))
      }
    })
  },
  deleteById: ({ commit, getters, rootGetters }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const params = {
        id_notification: id,
        id_client: rootGetters['clients/client'],
        value: 1
      }
      Vue.http.post(`${rootGetters.siteUrl}/notifications/delete`, params)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set delete notification!'))
        })
        .then(data => {
          if (data.success === 'true') {
            commit('UPDATE_NOTIFICATION', {
              id_notification: id,
              deleted: true
            })
            resolve(data)
          } else {
            reject(new Error('Could not delete notification!'))
          }
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not delete notification!'))
        })
    })
  },
  setRead: ({ commit, getters, rootGetters }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const params = {
        id_notification: id,
        id_client: rootGetters['clients/client'],
        value: 1
      }
      Vue.http.post(`${rootGetters.siteUrl}/notifications/setRead`, params)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set notification read status!'))
        })
        .then(data => {
          if (data.success === 'true') {
            commit('UPDATE_NOTIFICATION', {
              id_notification: id,
              read: true
            })
            resolve(data)
          } else {
            reject(new Error('Could not set notification read status!'))
          }
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set notification read status!'))
        })
    })
  },
  setActionDone: ({ commit, getters, rootGetters }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const params = {
        id_notification: id,
        id_client: rootGetters['clients/client'],
        value: 1
      }
      Vue.http.post(`${rootGetters.siteUrl}/notifications/setActionDone`, params)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set action status!'))
        })
        .then(data => {
          if (data.success === 'true') {
            commit('UPDATE_NOTIFICATION', {
              id_notification: id,
              action_done: true
            })
            resolve(data)
          } else {
            reject(new Error('Could not set action status!'))
          }
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set action status!'))
        })
    })
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initNotifications')
  },
  setOrder: ({ commit, dispatch }, order) => {
    commit('SET_ORDER', order)
    dispatch('initNotifications')
  },
  setOrderField: ({ commit, dispatch }, orderField) => {
    commit('SET_ORDER_FIELD', orderField)
    dispatch('initNotifications')
  },
  setShowDeleted: ({ commit, dispatch }, value) => {
    commit('SET_SHOW_DELETED', value)
    dispatch('initNotifications')
  }
}
