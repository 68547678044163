<template>
  <div class="purchases-view mb-3">
    <page-title :title="translation('my_purchases')" icon="cart-plus"/>
    <purchase-filters-bar/>
    <purchases-list/>
    <purchase-transfer-modal/>
  </div>
</template>

<script>
import PurchaseFiltersBar from '@/components/purchases/PurchaseFiltersBar'
import PurchasesList from '@/components/purchases/PurchasesList'
import PageTitle from '@/components/common/PageTitle'
import PurchaseTransferModal from '@/components/purchases/PurchaseTransferModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PurchasesPanel',
  components: { PurchaseTransferModal, PageTitle, PurchasesList, PurchaseFiltersBar },
  computed: {
    ...mapGetters(['translation'])
  }
}
</script>

<style scoped>

</style>
