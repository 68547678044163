<template>
  <div id="cart">
    <b-navbar type="dark" variant="light" class="page-title mb-3">
      <b-navbar-nav>
        <h1 class="mb-0">
          {{ translation('my_selection') }}
          <span
            class="badge badge-pill"
            :style="customBandStyles"
          >
            {{ totalAmount | toCurrency }}
          </span>
        </h1>
      </b-navbar-nav>
    </b-navbar>
    <ads-cart v-if="clientInfo"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdsCart from '@/components/cart/AdsCart'

export default {
  name: 'CartView',
  components: { AdsCart },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('cart', ['totalAmount']),
    ...mapGetters('clients', ['customBandStyles', 'clientInfo'])
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('clients', ['initClient'])
  },
  created () {
    if (!this.clientInfo) {
      this.initClient(this.$route.params.id)
    }
    this.setSidebarVisibility(false)
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin-left: -15px;
  margin-right: -15px;
  padding: 1rem;
}

h1 {
  text-transform: uppercase;
  line-height: 1.8rem;
  margin: 0;
  padding: 0;
}
</style>
