export default {
  vouchers: [],
  currentVoucher: null,
  voucherHosts: [],
  influencers: [
    {
      id_user: 1,
      full_name: 'Pepe'
    },
    {
      id_user: 1,
      full_name: 'Carlos'
    },
    {
      id_user: 1,
      full_name: 'Clara'
    }
  ],
  orderOptions: [
    { value: 'name', text: 'name' },
    { value: 'id_status', text: 'status' },
    { value: 'reservation', text: 'reservation' }
  ],
  activityOptions: [],
  ownerOptions: [],
  order: 'DESC',
  orderField: 'name',
  page: 0,
  perPage: 16,
  filters: {
    pagFilter: '',
    creator: 0,
    idClient: 0,
    voucherHost: 0,
    idOwner: 0,
    idAd: '',
    createdMinDate: '',
    createdMaxDate: '',
    sentMinDate: '',
    sentMaxDate: '',
    validatedMinDate: '',
    validatedMaxDate: '',
    reservedMinDate: '',
    reservedMaxDate: '',
    contractedMinDate: '',
    contractedMaxDate: '',
    pending: false,
    reserved: false,
    didNotAssist: false,
    disabled: false,
    completed: false,
    contracted: false,
    rejected: false
  },
  filtersLocked: false,
  activeFilter: '',
  summary: {
    pending: 0,
    reserved: 0,
    didNotAssist: 0,
    expired: 0,
    disabled: 0,
    completed: 0,
    contracted: 0,
    rejected: 0,
    completedSent: 0,
    completedReceived: 0,
    sent: 0,
    received: 0
  },
  onlyMyVouchers: true,
  resendSuccess: false,
  rejectSuccess: false,
  reservationSuccess: false,
  validationSuccess: false,
  infiniteId: +new Date()
}
