import Vue from 'vue'

export default {
  initInvited: ({ commit, dispatch, rootGetters }) => {
    return new Promise((resolve, reject) => {
      if (rootGetters['clients/client']) {
        Vue.http.post(`${rootGetters.siteUrl}/codes/is_invited`, { id_client: rootGetters['clients/client'] })
          .then(response => {
            return response.json()
          }, () => {
            reject(new Error('Could not get invitation status!'))
          })
          .then(data => {
            commit('SET_INVITED', data.invited)
            dispatch('checkAuthentication')
            resolve(data.invited)
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get invitation status!'))
          })
      } else {
        reject(new Error('No client ID!'))
      }
    })
  },
  registerUser: ({ commit, getters, rootGetters, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      payload.id_client = rootGetters['clients/client']
      payload.invitation = rootGetters['auth/invited']
      const url = rootGetters.exclusiveSite ? `${rootGetters.siteUrl}/userauth/invitation_register` : `${rootGetters.siteUrl}/userauth/user_register`
      Vue.http.post(url, payload)
        .then(response => {
          return response.json()
        }, () => {
          reject(new Error('Could not register!'))
        })
        .then(data => {
          commit('SET_REGISTERED', true)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not register!'))
        })
    })
  },
  loginUser: ({ commit, getters, rootGetters, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      payload.id_client = rootGetters['clients/client']
      Vue.http.post(`${rootGetters.siteUrl}/userauth/user_login`, payload)
        .then(response => {
          return response.json()
        }, () => {
          reject(new Error('Could not login!'))
        })
        .then(data => {
          if (data.success === 'true') {
            commit('SET_ROLE', data.role)
            commit('SET_ACTIVATED', true)
          } else if (data.reason === 'not_activated') {
            commit('SET_ROLE', '')
            commit('SET_ACTIVATED', false)
          }
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not login!'))
        })
    })
  },
  logoutUser: ({ commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${rootGetters.siteUrl}/userauth/logout/${rootGetters['clients/client']}`)
        .then(response => {
          return response.json()
        }, () => {
          reject(new Error('Could not logout!'))
        })
        .then(data => {
          if (data.success === 'true') {
            commit('SET_ROLE', '')
          }
          if (rootGetters.exclusiveSite) {
            window.location.replace('/')
          }
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not logout!'))
        })
    })
  },
  requestNewPassword: ({ commit, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      payload.id_client = rootGetters['clients/client']
      Vue.http.post(`${rootGetters.siteUrl}/userauth/user_forgot_password`, payload)
        .then(response => {
          return response.json()
        }, () => {
          reject(new Error('Could not recover password!'))
        })
        .then(data => {
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not recover password!'))
        })
    })
  },
  sendAgain: ({ commit, rootGetters }, email) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${rootGetters.siteUrl}/userauth/user_send_again/${rootGetters['clients/client']}`, { email: email })
        .then(response => {
          return response.json()
        }, () => {
          reject(new Error('Could not send activation email again!'))
        })
        .then(data => {
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not send activation email again!'))
        })
    })
  },
  createNewPassword: ({ commit, getters, rootGetters, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      payload.clientId = rootGetters['clients/client']
      const params = {
        new_password: payload.newPassword,
        confirm_new_password: payload.confirmNewPassword
      }
      Vue.http.post(`${rootGetters.siteUrl}/userauth/user_reset_password/${payload.userId}/${payload.key}/${payload.clientId}`, params)
        .then(response => {
          return response.json()
        }, () => {
          reject(new Error('Could not create a new  password!'))
        })
        .then(data => {
          dispatch('clients/resetClientInfo', null, { root: true })
          dispatch('clients/initClient', payload.clientId, { root: true })
            .then(() => {
              resolve(data)
            })
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not create a new  password!'))
        })
    })
  },
  checkAuthentication: ({ getters, rootGetters }) => {
    // console.log('rootGetters.exclusiveSite: ', rootGetters.exclusiveSite, ' - getters.invited: ', getters.invited, ' - getters.registered: ', getters.registered, ' - getters.isAuthenticated: ', getters.isAuthenticated)
    if (!rootGetters.noAuth && rootGetters.exclusiveSite && !getters.invited && !getters.registered && !getters.isAuthenticated) {
      window.location.replace('/')
    }
  },
  setAccountType: ({ commit }, accountType) => {
    commit('SET_ACCOUNT_TYPE', accountType)
  }
}
