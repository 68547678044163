<template>
  <b-col
    v-if="voucher"
    cols="12"
    sm="6"
    md="6"
    lg="4"
    xl="3"
    class="category-item package-item ribbon-item"
    :class="{ 'mb-2': isMobileView, 'mb-3': !isMobileView }"
  >
    <b-card
      no-body
      border-variant="secondary"
      :class="{ 'shadow-lg': hovering }"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <b-card-body class="px-0 pb-0">
        <ad-card-header :ad="voucher.ad_info" :singleColumn="true"/>
        <hr>
        <voucher-status-line :voucher="voucher" class="px-2"/>
        <b-link :href="voucher.view_url">
          <b-img :src="voucher.ad_info.preview" alt="" fluid/>
        </b-link>
        <div class="p-2">
          <div class="pack-name">
            <h5 class="text-left">{{ voucher.name }}</h5>
          </div>
          <b-form-row v-if="voucher.distance_friendly" class="mb-2">
            <b-col class="distance-label text-right">Distancia:</b-col>
            <b-col class="distance-label pl-0">{{ voucher.distance_friendly }}</b-col>
          </b-form-row>
          <div v-if="showSummary">
            {{ voucher.summary }}
          </div>
          <b-form-row v-if="voucher.id_status === 'p' || voucher.id_status === 'v'">
            <b-col class="text-right">Código:</b-col>
            <b-col v-if="codeVisible" class="code-value pl-0">{{ voucher.code }}</b-col>
            <b-col v-if="!codeVisible" class="show-code-link pl-0">
              <b-link @click.stop="showCode">
                Mostrar código
              </b-link>
            </b-col>
          </b-form-row>
        </div>
        <b-button-group class="w-100 text-right">
          <b-link
            class="btn view-btn pt-2"
            :href="voucher.view_url"
            :style="customBandStyles"
          >
            <font-awesome-icon icon="eye"/>
            {{ translation('view') }}
          </b-link>
          <b-link
            v-if="voucher.id_status === 'p'"
            class="btn view-btn pt-2"
            @click="transfer"
            :style="customBandStyles"
          >
            <font-awesome-icon icon="arrow-up-from-bracket"/>
            {{ translation('transfer') }}
          </b-link>
        </b-button-group>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import AdCardHeader from '@/components/ads/AdCardHeader'
import { mapActions, mapGetters } from 'vuex'
import VoucherStatusLine from '@/components/vouchers/VoucherStatusLine'

export default {
  name: 'VoucherItem',
  components: { VoucherStatusLine, AdCardHeader },
  props: ['voucher'],
  data () {
    return {
      hovering: false,
      codeVisible: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'isMobileView']),
    ...mapGetters('clients', ['customBandStyles', 'client']),
    showSummary () {
      return this.voucher.id_type === 'i'
    },
    viewUrl () {
      return `/ad-details/${this.client}/${this.voucher.id_ad}/voucher`
    }
  },
  methods: {
    ...mapActions('vouchers', ['setCurrentVoucher']),
    showCode () {
      this.codeVisible = true
      setTimeout(() => {
        this.codeVisible = false
      }, 10000)
    },
    transfer () {
      this.setCurrentVoucher(this.voucher)
      this.$bvModal.show('voucher-transfer-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .distance-label {
    font-size: 0.9rem;
  }

  .view-btn {
    &:hover {
      opacity: 0.7;
    }
  }

  .code-value {
    font-weight: bold;
  }
</style>
