<template>
  <div class="sale-card">
    <div class="d-block d-sm-none mb-3">
      <b-img :src="item.image" class="mb-3 w-100" :alt="item.name"></b-img>
      <div class="d-flex justify-content-between">
        <div class="">
          <div class="mb-2">
            <b-link href="#">{{ item.name }}</b-link>
          </div>
          <div class="mb-2">
            <span>{{ translation('owner') }}: {{ item.commercial_name }}</span>
          </div>
          <div class="mb-2">
            <div class="mb-2">{{ translation('count') }}: {{ totalCount }}</div>
            <div class="mb-2">{{ translation('pending') }}: {{ pending }}</div>
            <div class="mb-2">{{ translation('validated_p') }}: {{ validated }}</div>
            <div class="mb-2">{{ translation('reserved_p') }}: {{ reserved }}</div>
          </div>
        </div>
      </div>
    </div>
    <b-media class="d-none d-sm-block mb-3">
      <template #aside>
        <b-img :src="item.image" width="120" :alt="item.name"></b-img>
      </template>
      <div class="d-flex justify-content-between">
        <div class="">
          <div class="mb-2">
            <b-link href="#">{{ item.name }}</b-link>
          </div>
          <div class="mb-2">
            <span>{{ translation('owner') }}: {{ item.commercial_name }}</span>
          </div>
          <div class="mb-2">
            <span>{{ translation('count') }}: {{ totalCount }}</span>
            <span class="ml-3">{{ translation('pending') }}: {{ pending }}</span>
            <span class="ml-3">{{ translation('validated_p') }}: {{ validated }}</span>
            <span class="ml-3">{{ translation('reserved_p') }}: {{ reserved }}</span>
          </div>
        </div>
      </div>
    </b-media>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SaleAd',
  props: ['item'],
  computed: {
    ...mapGetters(['translation']),
    totalCount () {
      return this.pending + this.validated + this.reserved
    },
    pending () {
      return this.item.validations.p ? this.item.validations.p.length : 0
    },
    validated () {
      return this.item.validations.v ? this.item.validations.v.length : 0
    },
    reserved () {
      return this.item.validations.r ? this.item.validations.r.length : 0
    }
  }
}
</script>

<style scoped>

</style>
