<template>
  <div>
    <b-media
      v-if="!isMobileView || singleColumn"
      class="mb-2"
    >
      <template #aside>
        <b-img
          v-if="ad.owner_info.logo"
          :src="ad.owner_info.logo"
          width="64"
          class="mx-2"
          :alt="ad.owner_info.commercial_name"
        />
      </template>
      <div
        :class="{ 'ml-3': !ad.owner_info.logo }"
      >
        <h6 class="my-0">{{ ad.owner_info.commercial_name }}</h6>
        <small>{{ ad.categories }}</small>
      </div>
    </b-media>
    <b-img
      v-if="!singleColumn && isMobileView && ad.owner_info.logo"
      :src="ad.owner_info.logo"
      width="64"
      class="mx-2"
      :alt="ad.owner_info.commercial_name"
    />
    <div
      class="mb-1"
      v-if="!singleColumn && isMobileView"
    >
      <small>{{ ad.categories }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdCardHeader',
  props: ['ad', 'nameMargin', 'singleColumn'],
  computed: {
    ...mapGetters(['isMobileView'])
  }
}
</script>

<style scoped>
  h6 {
    text-transform: uppercase;
  }
</style>
