<template>
  <b-form-group
    class="activities-filter border-top pt-2"
  >
    <b-navbar
      v-if="activities.length > 0"
      type="dark"
      variant="light"
      class="page-title mb-3"
    >
      <b-nav card-header pills>
        <label class="mb-0">
          Seleccionar actividad
          <small
            v-if="!showAll"
            class="activity-link"
            @click.stop="showAll = true"
          >
            - Ver todas
          </small>
        </label>
      </b-nav>
    </b-navbar>
    <b-form-row v-if="showAll">
      <b-col
        cols="12" sm="6" xl="4"
        v-for="activity of activities"
        :key="activity.id_activity"
        class="p-1 mb-1"
        v-show="activity.count > 0 || (activity.children && activity.children.length > 0)"
      >
        <activity-item
          :activity="activity"
          level="0"
          :expand="false"
          @change="selectActivity"
        />
      </b-col>
    </b-form-row>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ActivityItem from '@/components/common/ActivityItem'

export default {
  name: 'ActivitiesFilter',
  components: { ActivityItem },
  data () {
    return {
      expanded: {},
      showAll: true
    }
  },
  created () {
    this.setUsers([])
    this.activities.forEach(activity => {
      this.expanded[`activity${activity.id_activity}`] = false
    })
  },
  computed: {
    ...mapGetters('activities', ['activities', 'selectedActivity'])
  },
  methods: {
    ...mapActions('users', ['setUsers']),
    selectActivity () {
      this.$emit('change', this.selectedActivity)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
