<template>
  <b-form-group class="pt-2">
    <b-form-row>
      <b-col
        cols="12" sm="6" md="4" xl="3"
        class="p-1 mb-1"
        v-for="category of categories"
        :key="category.id"
      >
        <category-item
          :category="category"
          :parent="null"
          @change="$emit('change')"
        />
      </b-col>
    </b-form-row>
  </b-form-group>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoryItem from '@/components/categories/CategoryItem'

export default {
  name: 'CategoriesStructure',
  components: { CategoryItem },
  computed: {
    ...mapGetters('categories', ['categories'])
  }
}
</script>

<style lang="scss">

</style>
