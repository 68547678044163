<template>
  <div>
    <b-form-group class="mb-1">
      <b-input-group>
        <template v-slot:prepend>
          <b-input-group-text>
            <font-awesome-icon icon="search"/>
          </b-input-group-text>
        </template>
        <b-form-input
          v-model="filters.pagFilter"
          type="text"
          class="form-control"
          placeholder="Escriba lo que desea buscar..."
          @change="updateFilters"
        />
      </b-input-group>
    </b-form-group>
    <b-form-row>
      <b-col cols="6">
        <b-form-group
          label="Muro de origen"
          class="mb-1"
        >
          <select
            v-model="filters.idClient"
            class="form-control"
            id="source-client"
            :disabled="clients.length === 0"
            @change="updateFilters"
          >
            <option value=""></option>
            <option
              v-for="(client, idx) of clients"
              :key="idx"
              :value="client.id"
            >
              {{ client.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Muro de destino"
          class="mb-1"
        >
          <select
            v-model="filters.voucherHost"
            class="form-control"
            id="destination-client"
            :disabled="voucherHosts.length === 0"
            @change="updateFilters"
          >
            <option value=""></option>
            <option
              v-for="(client, idx) of voucherHosts"
              :key="idx"
              :value="client.id"
            >
              {{ client.name }}
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-row>
      <b-col cols="6">
        <b-form-checkbox
          v-model="pending"
          id="pending-filter"
          class="toggle-filter text-warning text-truncate"
        >
          Pendientes
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="reserved"
          id="reserved-filter"
          class="toggle-filter text-success text-truncate"
        >
          Reservadas
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="didNotAssist"
          id="not-assist-filter"
          class="toggle-filter text-truncate"
        >
          No asisten
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="disabled"
          id="expired-filter"
          class="toggle-filter text-danger text-truncate"
        >
          Deshabilitadas
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="completed"
          id="completed-filter"
          class="toggle-filter text-primary text-truncate"
        >
          Completadas
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="contracted"
          id="contracted-filter"
          class="toggle-filter text-info text-truncate"
        >
          Contratadas
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="rejected"
          id="rejected-filter"
          class="toggle-filter text-secondary text-truncate"
        >
          Rechazadas
        </b-form-checkbox>
      </b-col>
    </b-row>
    <date-filter
      :toggle="true"
      :label="'Creada'"
      :field-min="'createdMinDate'"
      :field-max="'createdMaxDate'"
      :filters="filters"/>
    <date-filter
      :toggle="true"
      :label="'Enviada'"
      :field-min="'sentMinDate'"
      :field-max="'sentMaxDate'"
      :filters="filters"/>
    <date-filter
      :toggle="true"
      :label="'Validada'"
      :field-min="'validatedMinDate'"
      :field-max="'validatedMaxDate'"
      :filters="filters"/>
    <date-filter
      :toggle="true"
      :label="'Contratada'"
      :field-min="'contractedMinDate'"
      :field-max="'contractedMaxDate'"
      :filters="filters"/>
    <date-filter
      :toggle="true"
      :label="'Rechazada'"
      :field-min="'rejectedMinDate'"
      :field-max="'rejectedMaxDate'"
      :filters="filters"/>
  </div>
</template>

<script>
import DateFilter from './DateFilter'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdvancedFilters',
  props: ['show'],
  components: {
    DateFilter
  },
  computed: {
    ...mapGetters('clients', ['clients']),
    ...mapGetters('vouchers', ['voucherHosts', 'filters']),
    pending: {
      get () {
        return this.filters.pending
      },
      set () {
        this.toggleFilter('pending')
      }
    },
    reserved: {
      get () {
        return this.filters.reserved
      },
      set () {
        this.toggleFilter('reserved')
      }
    },
    didNotAssist: {
      get () {
        return this.filters.didNotAssist
      },
      set () {
        this.toggleFilter('didNotAssist')
      }
    },
    completed: {
      get () {
        return this.filters.completed
      },
      set () {
        this.toggleFilter('completed')
      }
    },
    rejected: {
      get () {
        return this.filters.rejected
      },
      set () {
        this.toggleFilter('rejected')
      }
    },
    contracted: {
      get () {
        return this.filters.contracted
      },
      set () {
        this.toggleFilter('contracted')
      }
    },
    disabled: {
      get () {
        return this.filters.disabled
      },
      set () {
        this.toggleFilter('disabled')
      }
    }
  },
  methods: {
    ...mapActions('vouchers', ['updateFilters', 'toggleFilter'])
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    background-color: #88BD2F;
    color: white;

    .vdp-datepicker__calendar {
      right: 0;
    }
  }

  .hide-filters-link {
    margin-top: 4px;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  .toggle-filter {
    &:hover {
      cursor: pointer;
    }

    label, input {
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
