<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <b-row>
      <product-card
        v-for="(product, idx) of products"
        :key="idx"
        :product="product"/>
    </b-row>
    <infinite-loading @infinite="infiniteHandler" :identifier="productsInfiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-overlay>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

import { mapActions, mapGetters } from 'vuex'
import ProductCard from '@/components/products/ProductCard'

export default {
  name: 'ProductsList',
  components: { ProductCard, InfiniteLoading },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('products', ['products', 'productsInfiniteId']),
    ...mapGetters('clients', ['clientInfo'])
  },
  created () {
    this.initClient(this.$route.params.id)
      .then(() => {
        this.setOnlyForClient(false)
        this.clearFilters()
      })
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('products', ['initProducts', 'loadProducts', 'setOnlyForClient', 'clearFilters']),
    infiniteHandler ($state) {
      this.loadProducts()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
