import Vue from 'vue'

export default {
  initAds: ({ commit, getters, rootGetters }, payload) => {
    if (rootGetters['clients/client']) {
      return new Promise((resolve, reject) => {
        commit('SET_BUSY', true, { root: true })
        commit('SET_PAGE', 0)
        commit('UPDATE_INFINITE_ID')
        commit('SET_INFO_WINDOW_OPEN', false, { root: true })
        const requestData = getters.requestData(payload)
        Vue.http.post(`${rootGetters.siteUrl}/sponsor/get_items`, requestData)
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
          .then(data => {
            if (payload && payload.id_ad) {
              commit('SET_CURRENT_AD', data.ads.length > 0 ? data.ads[0] : null)
              resolve(data.ads[0])
            } else {
              commit('SET_ADS', data.ads)
              commit('categories/SET_CATEGORIES', data.filters.categories, { root: true })
              resolve(data.ads)
            }
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
      })
    }
  },
  loadAds: ({ getters, commit, rootGetters }, payload) => {
    if (rootGetters['clients/client']) {
      return new Promise((resolve, reject) => {
        commit('SET_BUSY', true, { root: true })
        commit('SET_PAGE', getters.page + 1)
        commit('SET_INFO_WINDOW_OPEN', false, { root: true })
        Vue.http.post(`${rootGetters.siteUrl}/sponsor/get_items`, getters.requestData(payload))
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
          .then(data => {
            commit('ADD_ADS', data.ads)
            commit('categories/SET_CATEGORIES', data.filters.categories, { root: true })
            resolve(data.ads)
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not get ads!'))
          })
      })
    }
  },
  setCurrentAd: ({ commit }, ad) => {
    commit('SET_CURRENT_AD', ad)
  },
  setAdTypes: ({ commit }, adTypes) => {
    commit('SET_AD_TYPES', adTypes)
  },
  setOnlyAllowingGifts: ({ commit }, value) => {
    commit('SET_ONLY_ALLOWING_GIFTS', value)
    commit('SET_ALLOWED_AT_GIFTS', value)
  },
  setAllowedAtMarketplace: ({ commit }, value) => {
    commit('SET_ALLOWED_AT_MARKETPLACE', value)
  },
  setOnlyVisibleAtMyWalls: ({ commit }, value) => {
    commit('SET_ONLY_VISIBLE_AT_MY_WALLS', value)
  },
  setDoNotSelectGifts: ({ commit }, value) => {
    commit('SET_DO_NOT_SELECT_GIFTS', value)
  },
  setShowPromoPrice: ({ commit }, value) => {
    commit('SET_SHOW_PROMO_PRICE', value)
  },
  setAdsCount: ({ commit }, value) => {
    commit('SET_ADS_COUNT', value)
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initAds', { section: filters.section })
  },
  resetFilters: ({ commit, dispatch }, payload) => {
    commit('RESET_FILTERS')
    commit('categories/RESET_SELECTED', null, { root: true })
    if (payload.init === undefined || payload.init) {
      dispatch('initAds', { section: payload.section })
    }
  },
  setOrder: ({ commit, dispatch }, payload) => {
    commit('SET_ORDER', payload)
    dispatch('initAds', { section: payload.section })
  },
  setOrderField: ({ commit, dispatch }, payload) => {
    commit('SET_ORDER_FIELD', payload)
    dispatch('initAds', { section: payload.section })
  },
  setCurrentAdImage: ({ commit }, image) => {
    commit('UPDATE_IMAGE', image)
  },
  updateAd: ({ commit }, payload) => {
    commit('UPDATE_AD', payload)
  },
  updateViewingAd: ({ commit }, id) => {
    commit('UPDATE_VIEWING_ADD', id)
  }
}
