export default {
  categories: state => {
    return state.categories
  },
  selectedCategories: state => {
    return state.selectedCategories
  },
  selectedCategoriesList: state => {
    return state.selectedCategories.join(',')
  },
  isSelected: (state) => (id) => {
    const result = state.selectedCategories.findIndex(category => category === id)
    return result >= 0
  },
  sortChildren: (state) => (categories) => {
    categories.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    })
    return categories
  },
  modifiedChildren: (state, getters) => (payload) => {
    const result = []
    let newParent = null
    if (payload.categories && payload.categories.length > 0) {
      payload.categories.forEach(category => {
        if (category.id !== payload.category.id) {
          const newCategory = { ...category }
          const params = {
            ...payload,
            categories: newCategory.children,
            level: payload.level + 1
          }
          newCategory.children = getters.modifiedChildren(params)
          result.push(newCategory)
          if (payload.parent && category.id === payload.parent.id) {
            newParent = newCategory
          }
        }
      })
      if (newParent) {
        const newCategory = { ...payload.category }
        const params = {
          ...payload,
          categories: newCategory.children,
          level: payload.level + 1
        }
        newCategory.children = getters.modifiedChildren(params)
        newParent.children.push(newCategory)
        newParent.children = getters.sortChildren(newParent.children)
      }
    }
    return result
  },
  modifiedActivities: (state, getters) => (payload) => {
    let result = []
    if (state.categories && state.categories.length > 0) {
      state.categories.forEach(category => {
        if (category.id !== payload.category.id) {
          const newCategory = { ...category }
          let newChild = null
          const params = {
            ...payload,
            categories: category.children,
            level: 1
          }
          newCategory.children = getters.modifiedChildren(params)
          if (payload.parent && category.id === payload.parent.id) {
            params.categories = payload.category.children
            newChild = { ...payload.category }
            newChild.children = getters.modifiedChildren(params)
            newCategory.children.push(newChild)
            newCategory.children = getters.sortChildren(newCategory.children)
          }
          result.push(newCategory)
        }
      })
      if (!payload.parent) {
        const newCategory = { ...payload.category }
        const params = {
          ...payload,
          categories: newCategory.children,
          level: 1
        }
        newCategory.children = getters.modifiedChildren(params)
        result.push(newCategory)
      }
      result = getters.sortChildren(result)
      return result
    }
  },
  childrenList: (state, getters) => (categories, parentId) => {
    const result = []
    if (categories) {
      categories.forEach(category => {
        result.push({
          id: category.id,
          id_parent: parentId,
          name: category.name
        })
        const children = getters.childrenList(category.children, category.id)
        children.forEach(child => {
          result.push(child)
        })
      })
    }
    return result
  },
  categoriesList: (state, getters) => {
    const result = []
    state.categories.forEach(category => {
      result.push({
        id: category.id,
        id_parent: 0,
        name: category.name
      })
      const children = getters.childrenList(category.children, category.id)
      children.forEach(child => {
        result.push(child)
      })
    })
    return result
  }
}
