import { render, staticRenderFns } from "./PurchasesList.vue?vue&type=template&id=b02b4a32&scoped=true&"
import script from "./PurchasesList.vue?vue&type=script&lang=js&"
export * from "./PurchasesList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b02b4a32",
  null
  
)

export default component.exports