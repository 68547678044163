export default {
  products: [],
  currentProduct: null,
  currentOrder: null,
  onlyForClient: false,
  filters: {
    pagFilter: '',
    creator: 0,
    idClient: 0,
    adHost: 0,
    idOwner: 0,
    idAd: '',
    createdMinDate: '',
    createdMaxDate: '',
    validatedMinDate: '',
    validatedMaxDate: '',
    reservedMinDate: '',
    reservedMaxDate: '',
    status: '',
    pending: false,
    reserved: false,
    didNotAssist: false,
    completed: false
  },
  filtersLocked: false,
  activeFilter: '',
  summary: {
    pending: 0,
    reserved: 0,
    didNotAssist: 0,
    completed: 0
  },
  orderOptions: [
    { value: 'name', text: 'name' },
    { value: 'id_status', text: 'status' },
    { value: 'reservation', text: 'reservation' }
  ],
  activityOptions: [],
  ownerOptions: [],
  adHosts: [],
  order: 'DESC',
  orderField: 'name',
  perPage: 16,
  page: 0,
  productsInfiniteId: +new Date() + 570000
}
