export default {
  ads: [],
  currentAd: null,
  filters: {
    name: '',
    distance: null,
    categories: []
  },
  orderOptions: [
    { value: 'name', text: 'name' },
    { value: 'distance', text: 'distance' }
  ],
  distanceOptions: [
    { value: null, text: 'distance' },
    { value: '0.5', text: '500m' },
    { value: '1', text: '1Km' },
    { value: '2', text: '2Km' },
    { value: '5', text: '5Km' },
    { value: '10', text: '10Km' },
    { value: '50', text: '50Km' },
    { value: '100', text: '100Km' },
    { value: '500', text: '500Km' }
  ],
  order: 'ASC',
  orderFields: {
    marketplace: {
      field: '',
      order: 'DESC'
    },
    info: {
      field: 'distance',
      order: 'ASC'
    },
    gifts: {
      field: '',
      order: 'DESC'
    },
    vouchers: {
      field: '',
      order: 'DESC'
    }
  },
  orderField: 'name',
  adTypes: 'a',
  onlyAllowingGifts: false,
  onlyVisibleAtMyWalls: false,
  doNotSelectGifts: false,
  allowedAtGifts: false,
  allowedAtMarketplace: false,
  showPromoPrice: false,
  adsCount: {
    marketplace: 0,
    gifts: 0,
    vouchers: 0,
    info: 0
  },
  perPage: 24,
  page: 0,
  adsInfiniteId: +new Date() + 250000,
  viewingAd: null
}
