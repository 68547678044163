export default {
  notifications: state => {
    if (state.showDeleted) {
      return state.notifications.filter((notification) => notification.deleted === 1)
    }
    return state.notifications.filter((notification) => notification.deleted === 0)
  },
  filters: state => {
    return state.filters
  },
  requestData: (state, getters, rootState, rootGetters) => {
    const filters = {
      ...state.filters,
      offset: getters.page * getters.perPage,
      limit: getters.perPage
    }
    if (getters.showDeleted) {
      filters.deleted_yes = 1
    } else {
      filters.deleted_no = 1
    }
    filters.id_client = rootGetters['clients/client']
    filters.order = getters.order
    filters.order_field = getters.orderField
    return filters
  },
  order: state => {
    return state.order
  },
  orderField: state => {
    return state.orderField
  },
  perPage: state => {
    return state.perPage
  },
  page: state => {
    return state.page
  },
  notificationsInfiniteId: state => {
    return state.notificationsInfiniteId
  },
  showDeleted: state => {
    return state.showDeleted
  },
  orderOptions: (state, getters, rootState, rootGetters) => {
    const options = []
    state.orderOptions.forEach(option => {
      options.push({
        value: option.value,
        text: rootGetters.translation(option.text)
      })
    })
    return options
  }
}
