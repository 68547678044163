<template>
  <b-card
    no-body
    border-variant="secondary"
    :class="{ 'shadow-lg': hovering }"
    class="mb-3"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <b-card-header>
      <div class="d-flex flex-column flex-sm-row">
        <div class="sale-date">
          <div class="sale-title d-none d-sm-block">{{ translation('order_date') }}</div>
          <div class="sale-value">{{ saleDate }}</div>
        </div>
        <div class="sale-value ml-0 ml-sm-4 d-flex flex-row flex-sm-column">
          <div class="sale-title">{{ translation('total') }}</div>
          <div class="sale-value ml-2 ml-sm-0">{{ sale.total_paid_tax_incl_friendly }}</div>
        </div>
        <div class="sale-value ml-0 ml-sm-4 d-flex flex-row flex-sm-column">
          <div class="sale-title">{{ translation('bought_by') }}</div>
          <div class="sale-value ml-2 ml-sm-0">{{ sale.user_info.full_name }}</div>
        </div>
        <div class="sale-value ml-0 ml-sm-auto">
          <div class="sale-title">{{ translation('order_number') }}: {{ sale.reference }}</div>
          <div class="sale-value">
            <!--<b-link href="#">{{ translation('view_details') }}</b-link>
            |-->
            <b-link href="#">{{ translation('invoice') }}</b-link>
          </div>
        </div>
      </div>
      <div v-if="client !== sale.id_client">
        <span class="sale-title">COMPRADO EN</span> {{ sale.bought_at.name }}
      </div>
    </b-card-header>
    <b-card-body class="pb-0">
      <div
        v-for="(ad, idx) of sale.ads"
        :key="idx"
      >
        <sale-item :item="ad"
          :class="{ 'border-bottom-1' : idx < sale.ads.length - 1 }"
        />
        <hr v-if="idx < sale.ads.length - 1"/>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import SaleItem from '@/components/sales/SaleItem'

export default {
  name: 'SalesCard',
  components: { SaleItem },
  props: ['sale'],
  data () {
    return {
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'language']),
    ...mapGetters('clients', ['client']),
    showSummary () {
      return this.ad.id_type === 'i'
    },
    saleDate () {
      moment.locale(this.language)
      return moment(this.sale.payment_time).format('LL')
    }
  }
}
</script>

<style lang="scss" scoped>
.distance-label {
  font-size: 0.9rem;
}

.view-btn {
  &:hover {
    opacity: 0.7;
  }
}

.sale-title {
  text-transform: uppercase;
}
</style>
