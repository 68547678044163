import mixins from '@/mixins'

export default {
  'SET_CART_ID' (state, cartId) {
    state.cartId = cartId
  },
  'SET_ADS' (state, ads) {
    state.ads = ads
  },
  'SET_ADS_INFO' (state, adsInfo) {
    state.adsInfo = adsInfo
  },
  'ADD_TO_CART' (state, newAd) {
    if (newAd.id) {
      if (newAd.includedGifts) {
        newAd.key = mixins.createID()
        state.ads = [...state.ads, newAd]
      } else {
        const index = state.ads.findIndex(function (ad) {
          return ad.id === newAd.id && !ad.gifts
        })
        if (index >= 0) {
          state.ads[index].count += newAd.count
        } else {
          newAd.key = mixins.createID()
          state.ads = [...state.ads, newAd]
        }
      }
    }
  },
  'UPDATE_COUNT' (state, payload) {
    if (payload.id) {
      const index = state.ads.findIndex(function (ad) {
        return ad.id === payload.id && !ad.gifts
      })
      if (index >= 0) {
        state.ads[index].count = payload.count
      }
    }
  },
  'REMOVE_FROM_CART' (state, key) {
    let index = state.ads.findIndex(function (ad) {
      return ad.key === key
    })
    if (index >= 0) {
      state.ads.splice(index, 1)
    }
    index = state.adsInfo.findIndex(function (ad) {
      return ad.key === key
    })
    if (index >= 0) {
      state.adsInfo.splice(index, 1)
    }
  },
  'EMPTY_CART' (state) {
    state.ads = []
    state.adsInfo = []
    state.total = 0
  }
}
