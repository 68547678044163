export default {
  'SET_PURCHASES' (state, purchases) {
    state.purchases = purchases
  },
  'ADD_PURCHASES' (state, purchases) {
    purchases.forEach(ad => {
      state.purchases.push(ad)
    })
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'SET_ORDER' (state, order) {
    state.order = order
  },
  'SET_ORDER_FIELD' (state, orderField) {
    state.orderField = orderField
  },
  'SET_CURRENT_AD' (state, ad) {
    state.currentAd = ad
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.purchasesInfiniteId += 1
  }
}
