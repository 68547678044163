export default {
  currentUser: state => {
    return state.currentUser
  },
  selectedUser: state => {
    return state.selectedUser
  },
  foundUser: state => {
    return state.foundUser
  },
  users: state => {
    return state.users
  },
  getUserById: (state) => (id) => {
    return state.users.find(user => user.user_id === id)
  },
  usersCount: state => {
    return state.usersCount
  },
  perPage: state => {
    return state.perPage
  },
  infiniteId: state => {
    return state.infiniteId
  },
  page: state => {
    return state.page
  },
  requestData: (state, getters, rootState, rootGetters) => {
    return {
      ...getters.filters,
      offset: getters.page * getters.perPage,
      perPage: getters.perPage,
      user_at_wall: rootGetters['clients/client']
    }
  },
  filters: state => {
    return state.filters
  },
  filterValues: state => {
    return state.filterValues
  }
}
