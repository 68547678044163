<template>
  <div class="date-filter">
    <strong>{{ label }}</strong>
    <b-form-row>
      <b-col cols="6">
        <b-form-group
          label="Fecha inicio"
          class="mb-1"
        >
          <b-form-datepicker
            v-model="startDate"
            class="filter-date"
            locale="es-ES"
            v-bind="labels[locale] || {}"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="min" :max="max"
            today-button
            reset-button
            close-button
            :label-today-button="labels[locale].labelToday"
            :label-reset-button="labels[locale].labelReset"
            :label-close-button="labels[locale].labelClose"
            dropleft
            hide-header
            placeholder=""
            @input="updateFilters"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Fecha fin"
          class="mb-1"
        >
          <b-form-datepicker
            v-model="endDate"
            class="filter-date"
            locale="es-ES"
            v-bind="labels[locale] || {}"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="min" :max="max"
            today-button
            reset-button
            close-button
            :label-today-button="labels[locale].labelToday"
            :label-reset-button="labels[locale].labelReset"
            :label-close-button="labels[locale].labelClose"
            dropleft
            hide-header
            placeholder=""
            @input="updateFilters"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DateFilter',
  props: ['label', 'fieldMin', 'fieldMax'],
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 12)
    minDate.setDate(15)
    // 15th in 12 months
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 12)
    maxDate.setDate(15)

    return {
      min: minDate,
      max: maxDate,
      locale: 'es',
      labels: {
        es: {
          labelPrevDecade: 'Década anterior',
          labelPrevYear: 'Año anterior',
          labelPrevMonth: 'Mes anterior',
          labelCurrentMonth: 'Mes actual',
          labelNextMonth: 'Mes siguiente',
          labelNextYear: 'Año siguiente',
          labelNextDecade: 'Década siguiente ',
          labelToday: 'Hoy',
          labelSelected: 'Fecha seleccionada',
          labelNoDateSelected: 'Sin fecha seleccionada',
          labelCalendar: 'Calendario',
          labelNav: 'Navigación del Calendario',
          labelHelp: 'Usa las teclas de flecha para navegar por el calendario',
          labelReset: 'Borrar',
          labelClose: 'Cerrar'
        }
      }
    }
  },
  computed: {
    ...mapGetters('vouchers', ['filters']),
    startDate: {
      get () {
        return this.filters[this.fieldMin]
      },
      set (value) {
        this.setFilterValue({
          field: this.fieldMin,
          value: value
        })
      }
    },
    endDate: {
      get () {
        return this.filters[this.fieldMax]
      },
      set (value) {
        this.setFilterValue({
          field: this.fieldMax,
          value: value
        })
      }
    }
  },
  methods: {
    ...mapActions('vouchers', ['updateFilters', 'setFilterValue'])
  }
}
</script>

<style scoped>

</style>
