<template>
  <div class="item-description my-3">
    <h2 v-if="title">{{ title }}</h2>
    <div class="description"
         v-if="showDescription"
         v-html="text"
    />
    <div class="summary"
         v-if="!showDescription"
    >
      {{ summary }}
      <b-link v-if="text.length > summaryLength" @click="showDescription = !showDescription">
        {{ translation('see_more') }}
      </b-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ItemDescription',
  props: ['text', 'summary', 'title'],
  data () {
    return {
      showDescription: true
    }
  },
  computed: {
    ...mapGetters(['translation', 'summaryLength'])
  }
}
</script>

<style scoped>

</style>
