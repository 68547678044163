import moment from 'moment'

export default {
  products: state => {
    return state.products
  },
  filters: state => {
    return state.filters
  },
  filtersLocked: state => {
    return state.filtersLocked
  },
  activeFilter: state => {
    return state.activeFilter
  },
  order: state => {
    return state.order
  },
  orderField: state => {
    return state.orderField
  },
  currentProduct: state => {
    return state.currentProduct
  },
  currentOrder: state => {
    return state.currentOrder
  },
  adHosts: state => {
    return state.adHosts
  },
  perPage: state => {
    return state.perPage
  },
  page: state => {
    return state.page
  },
  summary: state => {
    return state.summary
  },
  productsInfiniteId: state => {
    return state.productsInfiniteId
  },
  onlyForClient: state => {
    return state.onlyForClient
  },
  orderOptions: (state, getters, rootState, rootGetters) => {
    const options = []
    state.orderOptions.forEach(option => {
      options.push({
        value: option.value,
        text: rootGetters.translation(option.text)
      })
    })
    return options
  },
  activityOptions: (state, getters, rootState, rootGetters) => {
    const options = [
      { value: '', text: rootGetters.translation('select_activity') }
    ]
    state.activityOptions.forEach(activity => {
      options.push(activity)
    })
    return options
  },
  ownerOptions: (state, getters, rootState, rootGetters) => {
    const options = [
      { value: '', text: rootGetters.translation('select_company') }
    ]
    state.ownerOptions.forEach(activity => {
      options.push(activity)
    })
    return options
  },
  requestData: (state, getters, rootState, rootGetters) => {
    const data = {}
    let field, value
    for (field in getters.filters) {
      // eslint-disable-next-line no-prototype-builtins
      if (getters.filters.hasOwnProperty(field)) {
        value = getters.filters[field]
        if (value === true) {
          value = 1
        } else if (value === false) {
          value = 0
        } else if (String(field).search('/Date/') !== -1) {
          if (value) {
            value = moment(value).format('YYYY-MM-DD')
          } else {
            value = ''
          }
        }
        data[field] = value
        // data.set(field, value)
      }
    }
    data.id_client = rootGetters['clients/client']
    data.user_at_wall = rootGetters['clients/client']
    data.order = getters.order
    data.order_field = getters.orderField
    data.offset = getters.page * getters.perPage
    data.limit = getters.perPage
    data.only_for_client = getters.onlyForClient + 0
    return data
  }
}
