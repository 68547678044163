import Vue from 'vue'

export default {
  setPromos: ({ commit }, promos) => {
    commit('SET_PROMOS', promos)
  },
  sePromosCount: ({ commit }, promosCount) => {
    commit('SET_PROMOS_COUNT', promosCount)
  },
  setFilters: ({ commit }, filters) => {
    commit('SET_FILTERS', filters)
  },
  updateInfiniteId: ({ commit }) => {
    commit('UPDATE_INFINITE_ID')
  },
  initPromos: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      const requestData = getters.requestData
      Vue.http.post(rootGetters.siteUrl + '/admin/routes/getAdsList', requestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get promos!'))
        })
        .then(data => {
          const ads = data.ads || []
          commit('SET_PROMOS', ads)
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get promos!'))
        })
    })
  },
  loadPromos: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PAGE', getters.page + 1)
      Vue.http.post(rootGetters.siteUrl + '/admin/routes/getAdsList', getters.requestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get promos!'))
        })
        .then(data => {
          const ads = data.ads || []
          commit('ADD_PROMOS', ads)
          resolve(data.ads)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get promos!'))
        })
    })
  },
  setBusy: (context, busy) => {
    context.commit('SET_BUSY', busy)
  }
}
