export default {
  notifications: [],
  orderOptions: [
    { value: 'created', text: 'date' },
    { value: 'read', text: 'read' }
  ],
  order: 'DESC',
  orderField: 'created',
  perPage: 16,
  page: 0,
  notificationsInfiniteId: +new Date() + 250000,
  showDeleted: false
}
