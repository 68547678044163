<template>
  <b-modal
    id="product-reservation-modal"
    title="Reservar"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div>
      <div class="mb-3">
        Realizar la reserva. El usuario recibirá un nuevo mensaje con el código de la reserva.
      </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col>
            <b-form-group
              label="Número de pedido"
              label-for="order-input"
            >
              <b-input
                id="order-input"
                v-model="order"
                required
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :state="codeState"
              label="Código"
              label-for="code-input"
            >
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="code"
                  :state="codeState"
                  id="code-input"
                  required
                  @input="resetStatus"
                />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="validateCode">
                    <font-awesome-icon icon="search"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback v-if="!code">
                El código es obligatorio
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="wrongCode">
                El código es incorrecto
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <div v-if="fullName" class="mb-3">
          <div v-if="product">
            <product-found :product="product" :fullName="fullName"/>
            <hr>
          </div>
          <b-form-row>
            <b-col cols="8">
              <b-form-group>
                <b-form-datepicker
                  v-model="reservationDate"
                  placeholder="Seleccione la fecha"
                  :min="min"
                  :max="max"
                  locale="es"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <b-form-timepicker
                  v-model="reservationTime"
                  placeholder="Seleccione la hora"
                  label-close-button="Cerrar"
                  locale="es"/>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        v-if="!busy && codeState"
        size="sm"
        variant="success"
        @click="ok()"
      >
        <font-awesome-icon icon="check"/>
        Reservar
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        <font-awesome-icon icon="ban"/>
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import ProductFound from '@/components/products/ProductFound'

export default {
  name: 'ProductReservationModal',
  components: { ProductFound },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // one year
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 12)
    maxDate.setDate(15)

    return {
      code: '',
      codeState: null,
      wrongCode: false,
      order: '',
      min: today,
      max: maxDate,
      reservationDate: today,
      reservationTime: '9:00',
      context: null,
      fullName: ''
    }
  },
  computed: {
    ...mapGetters(['busy', 'language']),
    ...mapGetters('products', ['currentProduct']),
    product () {
      return this.currentProduct
    },
    reservation () {
      const date = moment(this.reservationDate).format('YYYY-MM-DD')
      return moment(`${date} ${this.reservationTime}`).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    ...mapActions('products', ['checkIfValid', 'initProducts', 'validateProduct', 'reserveProduct', 'setCurrentProduct']),
    resetModal () {
      this.fullName = ''
      this.code = ''
      this.order = ''
      this.codeState = null
      this.wrongCode = false
      this.setCurrentProduct(null)
      const now = new Date()
      this.reservationDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      this.reservationTime = '9:00'
    },
    validateCode () {
      this.checkIfValid({ code: this.code, order: this.order })
        .then(data => {
          if (data.success === 'true') {
            this.fullName = data.owner_name
            this.codeState = true
          } else {
            this.wrongCode = true
            this.codeState = false
          }
        })
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      return this.codeState && valid
    },
    resetStatus () {
      this.wrongCode = false
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$bvModal.hide('product-reservation-modal')
      const data = {
        order: this.order,
        code: this.code,
        reservation: this.reservation
      }
      // console.log('reservation: ', this.reservation)
      this.reserveProduct(data)
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.success === 'true') {
            this.initProducts()
            this.resetModal()
            this.makeToast(data.heading, data.message, 'success')
            this.$nextTick(() => {
              this.$bvModal.hide('product-reservation-modal')
            })
          } else {
            this.wrongCode = true
            this.codeState = false
            if (data.heading && data.message) {
              this.makeToast(data.heading, data.message, 'danger')
            }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
