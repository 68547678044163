<template>
  <b-row class="products-list">
    <product-card
      v-for="(product, idx) of products"
      :key="idx"
      :product="product"
    />
  </b-row>
</template>

<script>
import ProductCard from './ProductCard'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductsList',
  components: { ProductCard },
  computed: {
    ...mapGetters('products', ['products'])
  }
}
</script>

<style scoped>

</style>
