<template>
  <div class="vouchers-view">
    <page-title :title="translation('gifts')" :subtitle="translation('gifts_associated')" icon="gifts" :showFiltersIcon="true"/>
    <filters-bar :section="'gifts'"/>
    <google-map :markers="markers"/>
    <ads-list :section="'gifts'"/>
  </div>
</template>

<script>
import FiltersBar from '@/components/ads/FiltersBar'
import AdsList from '@/components/ads/AdsList'
import { mapActions, mapGetters } from 'vuex'
import GoogleMap from '@/components/maps/GoogleMap'
import PageTitle from '@/components/common/PageTitle'

export default {
  name: 'ProductsWithGiftsView',
  components: { PageTitle, GoogleMap, AdsList, FiltersBar },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('clients', ['clientInfo']),
    ...mapGetters('ads', ['markers'])
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('ads', ['setAdTypes', 'setOnlyAllowingGifts', 'setShowPromoPrice', 'setOnlyVisibleAtMyWalls', 'setDoNotSelectGifts', 'setAllowedAtMarketplace']),
    ...mapActions('clients', ['initClient'])
  },
  created () {
    this.setAdTypes('a,p')
    this.setOnlyAllowingGifts(true)
    this.setShowPromoPrice(false)
    this.setOnlyVisibleAtMyWalls(true)
    this.setDoNotSelectGifts(false)
    this.setAllowedAtMarketplace(false)
    if (!this.clientInfo) {
      this.initClient(this.$route.params.id)
    }
    this.setSidebarVisibility(false)
  }
}
</script>

<style scoped>

</style>
