import Vue from 'vue'

export default {
  initSales: ({ commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/admin/sales/get_sales`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get sales!'))
        })
        .then(data => {
          commit('SET_SALES', data.sales)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get sales!'))
        })
    })
  },
  loadSales: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/admin/sales/get_sales`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get sales!'))
        })
        .then(data => {
          commit('ADD_SALES', data.sales)
          resolve(data.sales)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get sales!'))
        })
    })
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initSales')
  },
  setOrder: ({ commit, dispatch }, order) => {
    commit('SET_ORDER', order)
    dispatch('initSales')
  },
  setOrderField: ({ commit, dispatch }, orderField) => {
    commit('SET_ORDER_FIELD', orderField)
    dispatch('initSales')
  }
}
