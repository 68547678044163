<template>
  <div id="approved-list">
    <b-row>
      <user-card
        v-for="user in approvedList"
        :key="user.user_id"
        :user="user"
        :list-type="'approved'"
      />
    </b-row>
    <infinite-loading @infinite="infiniteHandler" :identifier="approvedInfId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import UserCard from './UserCard'
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ApprovedList',
  components: { UserCard, InfiniteLoading },
  computed: {
    ...mapGetters('relations', ['approved', 'approvedCount', 'approvedInfiniteId']),
    approvedInfId () {
      return this.approvedInfiniteId
    },
    approvedList () {
      return this.approved
    }
  },
  methods: {
    ...mapActions('relations', ['loadApprovedPage']),
    infiniteHandler ($state) {
      this.loadApprovedPage()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
