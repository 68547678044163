// import Vue from 'vue'

export default {
  setActivities: ({ commit }, activities) => {
    commit('SET_ACTIVITIES', activities)
  },
  setSelectedActivity: ({ commit }, activity) => {
    commit('SET_SELECTED_ACTIVITY', activity)
  },
  initActivities: ({ commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      /* commit('SET_BUSY', true, { root: true })
      Vue.http.post(rootGetters.siteUrl + '/admin/activities/getCompatiblesForUser/1', rootGetters['users/requestData'])
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get activities!'))
        })
        .then(data => {
          commit('SET_ACTIVITIES', data.activities)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get activities!'))
        }) */
    })
  }
}
