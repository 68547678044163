<template>
  <div class="vouchers-view">
    <page-title :title="translation('vouchers')" :showFiltersIcon="true" icon="envelopes-bulk"/>
    <filters-bar :section="'vouchers'"/>
    <google-map :markers="markers"/>
    <ads-list :section="'vouchers'"/>
    <voucher-send-modal/>
  </div>
</template>

<script>
import FiltersBar from '@/components/ads/FiltersBar'
import AdsList from '@/components/ads/AdsList'
import { mapActions, mapGetters } from 'vuex'
import GoogleMap from '@/components/maps/GoogleMap'
import PageTitle from '@/components/common/PageTitle'
import VoucherSendModal from '@/components/vouchers/VoucherSendModal.vue'

export default {
  name: 'VouchersView',
  components: { VoucherSendModal, PageTitle, GoogleMap, AdsList, FiltersBar },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('ads', ['setAdTypes', 'setOnlyAllowingGifts', 'setOnlyVisibleAtMyWalls', 'setDoNotSelectGifts', 'setAllowedAtMarketplace'])
  },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('ads', ['markers'])
  },
  created () {
    this.setAdTypes('v')
    this.setOnlyAllowingGifts('null')
    this.setSidebarVisibility(false)
    this.setOnlyVisibleAtMyWalls(true)
    this.setDoNotSelectGifts(false)
    this.setAllowedAtMarketplace(false)
  }
}
</script>

<style scoped>

</style>
