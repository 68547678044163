<template>
  <li
    class="nav-item"
    :class="{ 'group-bottom': endGroup }"
  >
    <a
      @click.stop="setActivePage(section)"
      :class="{ 'active-link': activePage === section }"
      class="nav-link"
      :style="linkStyle"
    >
      <font-awesome-icon :icon="icon"/>
      <span class="ml-2">{{ translation(label) }}</span>
      <div
        class="triangle"
        :style="inverseBandStyles"
      />
    </a>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserSidebarLink',
  props: ['section', 'label', 'icon', 'endGroup'],
  computed: {
    ...mapGetters(['translation', 'activePage']),
    ...mapGetters('clients', ['customBandStyles', 'inverseBandStyles']),
    linkStyle () {
      return this.activePage === this.section ? this.customBandStyles : ''
    }
  },
  methods: {
    ...mapActions(['setActivePage'])
  }
}
</script>

<style lang="scss" scoped>
  .nav-link {
    padding: 0.5rem;
    position: relative;

    &.active-link {
      .triangle {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        right: -15px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        border-width: 11px 0 11px 19.1px;
        // border-color: transparent transparent transparent #88BD2F;
        border-style: solid;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .group-bottom {
    border-bottom: 1px solid $secondary;
    padding-bottom: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .light {
    a:not(.active-link) {
      color: black;
    }
  }

  .dark {
    a:not(.active-link) {
      color: white;
    }
  }
</style>
