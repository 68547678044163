<template>
  <div
    class="address"
    v-if="fullAddress"
  >
    {{ fullAddress }}
  </div>
</template>

<script>
export default {
  name: 'FullAddress',
  props: ['user'],
  computed: {
    fullAddress () {
      const address = []
      if (this.user.address) {
        address.push(this.user.address)
      }
      if (this.user.zip) {
        address.push(this.user.zip)
      }
      /* if (this.user.city) {
        address.push(this.user.city)
      } */
      if (this.user.province) {
        address.push(this.user.province)
      }
      return address.join(', ')
    }
  }
}
</script>

<style scoped>

</style>
