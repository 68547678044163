<template>
  <div class="d-inline text-danger">
    {{ days }}
  </div>
</template>

<script>
export default {
  name: 'RemainingDays',
  props: ['days']
}
</script>

<style scoped>

</style>
