<template>
  <div class="sales-view mb-3">
    <page-title title="Ventas" icon="sack-dollar"/>
    <sale-filters-bar/>
    <sales-list/>
  </div>
</template>

<script>
import SaleFiltersBar from '@/components/sales/SaleFiltersBar'
import SalesList from '@/components/sales/SalesList'
import PageTitle from '@/components/common/PageTitle'

export default {
  name: 'SalesPanel',
  components: { PageTitle, SalesList, SaleFiltersBar }
}
</script>

<style scoped>

</style>
