<template>
  <b-modal
    id="auth-modal"
    :title="translation('site_welcome')"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    :size="modalSize"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div v-if="showRegister">
        <b-form-group>
          {{ translation('do_you_have_an_account') }}
          <b-link @click="showForm = 'login'" class="primary-link">{{ translation('user_login') }}</b-link>
        </b-form-group>
        <b-form-group>
          <h4>{{ translation('form_join_us') }}</h4>
          <b-form-radio-group
            v-model="accountTypeValue"
          >
            <b-form-radio value="private">{{ translation('private_account') }}</b-form-radio>
            <b-form-radio value="professional">{{ translation('professional_account') }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group
          :state="nameState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="user"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="name"
              id="name-input"
              :placeholder="translation('name')"
              required
              :state="nameState"
              @input="nameState = null"
            />
            <b-form-invalid-feedback v-if="!name">
              El nombre es obligatorio
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :state="emailState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="envelope"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="email"
              id="email-input"
              :placeholder="translation('email')"
              required
              :state="emailState"
              @input="emailState = null"
            />
            <b-form-invalid-feedback v-if="!email">
              El email es obligatorio
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!validEmail">
              La dirección de correo no es válida
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :state="phoneState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="phone"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="phone"
              id="phone-input"
              :placeholder="translation('phone')"
              required
              :state="phoneState"
              @input="phoneState = null"
            />
            <b-form-invalid-feedback v-if="!phone">
              El teléfono es obligatorio
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-for="password-input"
          :state="passwordState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="lock"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="password"
              :state="passwordState"
              id="password-input"
              :placeholder="translation('password')"
              type="password"
              required
              @input="passwordState = null"
            />
            <b-form-invalid-feedback v-if="!validPassword">
              {{ translation('good_password') }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-for="password-confirmation-input"
          :state="passwordConfirmationState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="lock"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="passwordConfirmation"
              :state="passwordConfirmationState"
              id="password-confirmation-input"
              :placeholder="translation('password_confirm')"
              type="password"
              required
              @input="passwordConfirmationState = null"
            />
            <b-form-invalid-feedback v-if="!passwordConfirmation">
              Debe confirmar la contraseña
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="passwordConfirmationDoNotMatch">
              No coincide con la contraseña
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <div v-if="showProfessionalFields">
          <b-form-row>
            <b-col>
              <b-form-group
                label-for="commercial-name-input"
                :state="passwordState"
              >
                <b-form-input
                  v-model="commercialName"
                  :state="commercialNameState"
                  id="commercial-name-input"
                  :placeholder="translation('commercial_name')"
                  required
                  @input="commercialNameState = null"
                />
                <b-form-invalid-feedback v-if="!commercialName">
                  Este campo es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="nif-input"
                :state="nifState"
              >
                <b-form-input
                  v-model="nif"
                  :state="nifState"
                  id="commercial-name-input"
                  :placeholder="translation('nif')"
                  required
                  @input="nifState = null"
                />
                <b-form-invalid-feedback v-if="!nif">
                  Este campo es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-group
            label-for="address-input"
            :state="addressState"
          >
            <b-form-input
              v-model="address"
              :state="addressState"
              id="commercial-name-input"
              :placeholder="translation('address')"
              required
              @input="addressState = null"
            />
            <b-form-invalid-feedback v-if="!address">
              Este campo es obligatorio
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-row>
            <b-col>
              <b-form-group
                label-for="zip-input"
                :state="zipState"
              >
                <b-form-input
                  v-model="zip"
                  :state="zipState"
                  id="zip-input"
                  :placeholder="translation('zip')"
                  type="password"
                  required
                  @input="zipState = null"
                />
                <b-form-invalid-feedback v-if="!zip">
                  Este campo es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="city-input"
                :state="cityState"
              >
                <b-form-input
                  v-model="city"
                  :state="cityState"
                  id="city-input"
                  :placeholder="translation('city')"
                  required
                  @input="cityState = null"
                />
                <b-form-invalid-feedback v-if="!city">
                  Este campo es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label-for="province-input"
                :state="provinceState"
              >
                <b-form-input
                  v-model="province"
                  :state="provinceState"
                  id="commercial-name-input"
                  :placeholder="translation('province')"
                  type="password"
                  required
                  @input="provinceState = null"
                />
                <b-form-invalid-feedback v-if="!province">
                  Este campo es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="country-input"
                :state="countryState"
              >
                <b-form-input
                  v-model="country"
                  :state="countryState"
                  id="commercial-name-input"
                  :placeholder="translation('country')"
                  type="password"
                  required
                  @input="countryState = null"
                />
                <b-form-invalid-feedback v-if="!country">
                  Este campo es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
        <b-form-group
          v-if="terms || privacy"
          :state="acceptTermsState"
        >
          <b-form-checkbox
            v-model="acceptTerms"
            :value="1"
            :unchecked-value="0"
            @change="acceptTermsChange"
          >
            {{ translation('i_accept') }} <b-link @click.stop="showTerms">{{ translation('terms_and_privacy') }}</b-link>
          </b-form-checkbox>
          <b-form-invalid-feedback :state="acceptTermsState">
            {{ translation('must_accept_terms_and_privacy_policy') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div v-if="showLogin">
        <b-form-group
          label-for="login-email-input"
          :state="loginEmailState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="envelope"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="loginEmail"
              id="email-input"
              :placeholder="translation('email')"
              required
              :state="loginEmailState"
              @input="loginEmailState = null"
            />
            <b-form-invalid-feedback v-if="!loginEmail">
              El email es obligatorio
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!validLoginEmail">
              La dirección de correo no es válida
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group
          class="login-password-container mb-0"
          label-for="login-password-input"
          :state="loginPasswordState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="lock"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="loginPassword"
              :state="loginPasswordState"
              id="login-password-input"
              :placeholder="translation('password')"
              type="password"
              required
              @input="loginPasswordState = null"
            />
            <b-form-invalid-feedback v-if="!loginPassword">
              La contraseña es obligatoria
            </b-form-invalid-feedback>
          </b-input-group>
          <b-link
            class="forgot-password-link"
            @click.stop="showForm = 'forgot-password'"
          >
            {{ translation('forgot_password') }}
          </b-link>
        </b-form-group>
        <b-form-group class="mt-3 mb-0">
          <b-form-checkbox
            v-model="remember"
          >
            {{ translation('remember') }}
          </b-form-checkbox>
        </b-form-group>
        <b-alert variant="danger" v-model="wrongEmailOrPassword">{{ wrongEmailOrPasswordMessage }}</b-alert>
      </div>
      <div v-if="showForgotPassword">
        <b-form-group >
          <span>
            {{ translation('forgot_password_message') }}
          </span>
        </b-form-group>
        <b-form-group
          label-for="forgot-email-input"
          :state="forgotEmailState"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <font-awesome-icon icon="envelope"/>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="forgotEmail"
              id="email-input"
              :placeholder="translation('email')"
              required
              :state="forgotEmailState"
              @input="forgotEmailState = null"
            />
            <b-form-invalid-feedback v-if="!forgotEmail">
              El email es obligatorio
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!validForgotEmail">
              La dirección de correo no es válida
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </div>
    </form>
    <div v-if="showTermsAndPrivacy" class="terms-privacy-block">
      <div v-if="terms" class="mb-5">
        <h1>{{ translation('terms_of_use') }}</h1>
        <div v-html="terms"/>
      </div>
      <div v-if="privacy">
        <h1>{{ translation('privacy_policy') }}</h1>
        <div v-html="privacy"/>
      </div>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <div v-if="showForm === 'terms-and-privacy'">
        <b-button
          size="sm"
          @click="hideTerms"
        >
          {{ translation('close') }}
        </b-button>
      </div>
      <div v-if="showForm !== 'terms-and-privacy'">
        <div class="text-right">
          <b-button
            v-if="!busy"
            size="sm"
            variant="success"
            @click="ok()"
          >
            <font-awesome-icon icon="check"/>
            {{ submitButtonLabel }}
          </b-button>
          <b-button
            class="ml-2"
            size="sm"
            @click="cancel()"
          >
            <font-awesome-icon icon="ban"/>
            {{ translation('cancel') }}
          </b-button>
        </div>
        <div v-if="!showRegister" class="w-100 text-right mt-4">
          {{ translation('dont_you_have_an_account_at') }}
          <b-link @click="showForm = 'register'" class="primary-link">{{ translation('join_us') }}</b-link>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuthModal',
  data () {
    return {
      name: '',
      nameState: null,
      email: '',
      emailState: null,
      phone: '',
      phoneState: null,
      password: '',
      passwordState: null,
      passwordConfirmation: '',
      passwordConfirmationState: null,
      wrongEmailOrPassword: false,
      wrongEmailOrPasswordMessage: '',
      commercialName: '',
      commercialNameState: null,
      nif: '',
      nifState: null,
      address: '',
      addressState: null,
      zip: '',
      zipState: null,
      city: '',
      cityState: null,
      province: '',
      provinceState: null,
      country: '',
      countryState: null,
      loginEmail: '',
      loginEmailState: null,
      loginPassword: '',
      loginPasswordState: null,
      forgotEmail: '',
      forgotEmailState: null,
      showForm: 'register',
      remember: false,
      acceptTerms: 0,
      acceptTermsState: null,
      modalSize: 'md'
    }
  },
  computed: {
    ...mapGetters(['busy', 'translation', 'exclusiveSite']),
    ...mapGetters('clients', ['client', 'clientInfo', 'linkStyles', 'terms', 'privacy']),
    ...mapGetters('auth', ['accountType', 'showProfessionalFields', 'activated', 'isPasswordValid', 'isAuthenticated', 'isUser']),
    passwordConfirmationDoNotMatch () {
      if (!this.passwordConfirmation) {
        return false
      }
      return this.password !== this.passwordConfirmation
    },
    validEmail () {
      return this.isEmailValid(this.email)
    },
    validLoginEmail () {
      return this.isEmailValid(this.loginEmail)
    },
    validForgotEmail () {
      return this.isEmailValid(this.forgotEmail)
    },
    validPassword () {
      // Minimum eight characters, at least one letter, one number, one uppercase and can include symbols like #?!@$%^&*-
      return !this.password || this.isPasswordValid(this.password) ? null : false
    },
    submitButtonLabel () {
      if (this.showRegister) {
        return this.exclusiveSite ? this.translation('request') : this.translation('register')
      }
      if (this.showLogin) {
        return this.translation('login')
      }
      return this.translation('send')
    },
    showRegister () {
      return this.showForm === 'register'
    },
    showLogin () {
      return this.showForm === 'login'
    },
    showForgotPassword () {
      return this.showForm === 'forgot-password'
    },
    showTermsAndPrivacy () {
      return this.showForm === 'terms-and-privacy'
    },
    userAreaUrl () {
      return this.isUser ? `/user-home/${this.client}` : `/client-home/${this.client}`
    },
    accountTypeValue: {
      get () {
        return this.accountType
      },
      set (value) {
        this.setAccountType(value)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['requestNewPassword', 'loginUser', 'registerUser', 'setAccountType', 'sendAgain']),
    showTerms () {
      this.modalSize = 'xl'
      this.showForm = 'terms-and-privacy'
    },
    hideTerms () {
      this.modalSize = 'md'
      this.showForm = 'register'
    },
    isEmailValid (email) {
      if (!email) {
        return true
      }
      return email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/) ? null : false
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      if (this.showRegister) {
        this.nameState = this.name !== ''
        if (!this.email) {
          this.emailState = false
        } else {
          this.emailState = this.validEmail
        }
        if (!this.phone) {
          this.phoneState = false
        } else {
          this.phoneState = null
        }
        if (!this.password) {
          this.passwordState = false
        } else {
          this.passwordState = this.validPassword
        }
        if (this.passwordConfirmation && this.passwordConfirmation !== this.password) {
          this.passwordConfirmationState = false
        } else if (!this.passwordConfirmation) {
          this.passwordConfirmationState = false
        } else {
          this.passwordConfirmationState = null
        }
        this.acceptTermsState = parseInt(this.acceptTerms) === 1 ? null : false
        this.commercialNameState = this.commercialName !== ''
        this.nifState = this.nif !== ''
        this.addressState = this.address !== ''
        this.zipState = this.zip !== ''
        this.cityState = this.city !== ''
        this.provinceState = this.province !== ''
        this.countryState = this.country !== ''
        // const newVar = valid && this.nameState !== false && this.emailState !== false && this.passwordState !== false && this.passwordConfirmationState !== false
        // console.log('valid: ', valid, ' - nameState: ', this.nameState, ' - emailState: ', this.emailState, ' - passwordState: ', this.passwordState, ' - passwordConfirmationState: ', this.passwordConfirmationState)
        // return newVar
        const mainFieldsValid = valid && this.nameState !== false && this.emailState !== false && this.passwordState !== false && this.passwordConfirmationState !== false
        if (!this.showProfessionalFields) {
          return mainFieldsValid
        }
        return mainFieldsValid && this.commercialNameState !== false && this.nifState !== false && this.addressState !== false && this.zipState !== false &&
          this.cityState !== false && this.provinceState !== false && this.countryState !== false && this.acceptTerms
      }
      if (this.showLogin) {
        this.loginEmailState = this.loginEmail !== '' && this.validLoginEmail
        this.loginPasswordState = this.loginPassword !== ''
        return valid && this.loginEmailState !== false && this.loginPasswordState !== false
      }
      if (this.showForgotPassword) {
        this.forgotEmailState = this.forgotEmail !== '' && this.validForgotEmail
        return valid && this.forgotEmailState !== false
      }
      return false
    },
    acceptTermsChange () {
      this.acceptTermsState = parseInt(this.acceptTerms) === 1 ? null : false
    },
    forgotPassword () {
      this.showForm = 'forgot-password'
    },
    resetModal () {
      this.name = ''
      this.email = ''
      this.phone = ''
      this.password = ''
      this.passwordConfirmation = ''
      this.commercialName = ''
      this.nif = ''
      this.address = ''
      this.zip = ''
      this.city = ''
      this.province = ''
      this.country = ''
      this.acceptTerms = 0
      this.loginEmail = ''
      this.loginPassword = ''
      this.nameState = null
      this.emailState = null
      this.passwordState = null
      this.passwordConfirmationState = null
      this.nameState = null
      this.emailState = null
      this.passwordState = null
      this.commercialNameState = null
      this.nifState = null
      this.addressState = null
      this.zipState = null
      this.cityState = null
      this.provinceState = null
      this.countryState = null
      this.loginEmailState = null
      this.loginPasswordState = null
      this.forgotEmailState = null
      this.acceptTermsState = null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$bvModal.hide('auth-modal')
      if (this.showRegister) {
        const data = {
          full_name: this.name,
          email: this.email,
          phone: this.phone,
          password: this.password
        }
        if (this.showProfessionalFields) {
          data.commercial_name = this.commercialName
          data.nif = this.nif
          data.address = this.address
          data.zip = this.zip
          data.city = this.city
          data.province = this.province
          data.country = this.country
        }
        this.registerUser(data)
          .then(data => {
            if (data.success === 'true') {
              this.resetModal()
              this.makeToast(data.heading, data.message, 'success')
              this.$nextTick(() => {
                this.$bvModal.hide('auth-modal')
              })
            } else if (data.heading && data.message) {
              this.makeToast(data.heading, data.message, 'danger')
            } else if (data.errors) {
              const message = []
              for (const field in data.errors) {
                message.push(data.errors[field])
              }
              this.makeToast(data.heading, message.join('<br/>'), 'danger')
            }
          })
      } else if (this.showLogin) {
        const data = {
          email: this.loginEmail,
          password: this.loginPassword,
          remember: this.remember ? 1 : 0
        }
        this.loginUser(data)
          .then(data => {
            if (this.isAuthenticated) {
              this.resetModal()
              if (data.message) {
                this.makeToast(data.heading, data.message, 'success')
              }
              this.$nextTick(() => {
                this.$bvModal.hide('auth-modal')
              })
              this.$router.push(this.userAreaUrl)
            } else if (!this.activated) {
              this.sendAgain(this.email)
                .then(data => {
                  this.makeToast(data.heading, data.message, 'success')
                })
            } else {
              this.loginEmailState = false
              this.loginPasswordState = false
              this.wrongEmailOrPassword = true
              this.wrongEmailOrPasswordMessage = data.message
            }
          })
      } else {
        const data = {
          forgot_login: this.forgotEmail
        }
        this.requestNewPassword(data)
          .then(data => {
            if (data.success === 'true') {
              this.resetModal()
              if (data.message) {
                this.makeToast(data.heading, data.message, 'success')
              }
              this.$nextTick(() => {
                this.$bvModal.hide('auth-modal')
              })
            } else {
              this.makeToast(data.heading, data.message, 'danger')
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-password-container {
    position: relative;
  }

  .forgot-password-link {
    position: absolute;
    right: 10px;
    bottom: 8px;
    color: #989ca1;
    font-size: 0.8rem;
  }

  .terms-privacy-block {
    h1 {
      text-transform: uppercase;
    }
  }
</style>
