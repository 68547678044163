<template>
  <div class="mb-2">
    <div
      v-if="voucher.id_status === 'p'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-warning"/>
        <span class="status-name ml-2 text-truncate">{{ voucher.status_friendly }}</span>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'd'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-danger"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'c'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-primary"/>
        <span class="status-name ml-2 text-truncate">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.validated"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'v' && !voucher.reserved_friendly"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-success"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.validated"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'v' && voucher.reservation_friendly && voucher.reservation > now"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-success"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.reservation"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'v' && voucher.reservation_friendly && voucher.reservation <= now"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-dark"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.reservation"/>
      </div>
    </div>
  </div>
</template>

<script>
import TimeSlot from '@/components/common/TimeSlot'
import moment from 'moment'

export default {
  name: 'VoucherStatusLine',
  components: { TimeSlot },
  props: ['voucher'],
  computed: {
    now () {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
  .status-name {
    line-height: 1;
  }
</style>
