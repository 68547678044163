export default {
  'SET_PROMOS' (state, promos) {
    state.promos = promos
  },
  'SET_PROMOS_COUNT' (state, promosCount) {
    state.promosCount = promosCount
  },
  'ADD_PROMOS' (state, promos) {
    promos.forEach(promo => {
      state.promos.push(promo)
    })
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.infiniteId += 1
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = { ...filters }
  },
  'SET_BUSY' (state, busy) {
    state.busy = busy
  }
}
