<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <div>
      <purchase-card
        v-for="(purchase, idx) of purchases"
        :key="idx"
        :purchase="purchase"/>
    </div>
    <infinite-loading @infinite="infiniteHandler" :identifier="purchasesInfiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import PurchaseCard from '@/components/purchases/PurchasesCard'

export default {
  name: 'PurchasesList',
  components: { PurchaseCard, InfiniteLoading },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('purchases', ['purchases', 'purchasesInfiniteId']),
    ...mapGetters('clients', ['clientInfo'])
  },
  created () {
    this.initClient(this.$route.params.id)
      .then(() => {
        this.initPurchases()
      })
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('purchases', ['initPurchases', 'loadPurchases']),
    infiniteHandler ($state) {
      this.loadPurchases()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
