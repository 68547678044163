<template>
  <b-card
    v-if="user !== null"
    no-body
    id="user-profile"
  >
    <b-card-header class="profile-header">
      <b-media>
        <template #aside v-if="logo">
          <b-img :src="logo" width="64"></b-img>
        </template>
        <h5 class="mt-0">{{ user.commercial_name || user.full_name }}</h5>
      </b-media>
      <b-link
        class="close-btn"
        href="#"
        @click="closeProfile"
      >
        &times;
      </b-link>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4">
          <div>
            <b-card
              :img-src="image"
              :img-alt="user.full_name"
              img-top
            >
              <b-card-text v-if="user.address">
                <full-address :user="user" />
              </b-card-text>
              <b-card-text v-if="user.phone">
                <div>Teléfono: {{ user.phone }}</div>
              </b-card-text>
              <relation-buttons :user="user" :listType="'profile'"/>
            </b-card>
          </div>
        </b-col>
        <b-col cols="12" md="8">
          <p>{{ user.plain_description }}</p>
          <b-form-group v-if="user.activities.length > 0">
            <label>Actividades:</label>
            <div>
              <b-badge
                v-for="activity of user.activities"
                :key="activity.id_activity"
                class="mr-2"
              >
                {{ activity.name }}
              </b-badge>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <promos-list />
      <div class="text-center my-3">
        <b-button
          variant="outline-secondary"
          @click.stop="closeProfile"
        >
          {{ translation('go_back') }}
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RelationButtons from './RelationButtons'
import FullAddress from '../common/FullAddress'
import PromosList from './PromosList'

export default {
  name: 'UserProfile',
  components: { FullAddress, RelationButtons, PromosList },
  computed: {
    ...mapGetters(['siteUrl', 'translation']),
    ...mapGetters('users', ['selectedUser']),
    user () {
      return this.selectedUser
    },
    image () {
      return this.user.image ? `${this.siteUrl}/${this.user.image}` : ''
    },
    logo () {
      return this.user.logo ? `${this.siteUrl}/${this.user.logo}` : ''
    }
  },
  methods: {
    ...mapActions('users', ['clearSelectedUser']),
    ...mapActions('relations', ['refreshSuggested']),
    closeProfile () {
      this.clearSelectedUser()
      this.refreshSuggested()
    }
  }
}
</script>

<style lang="scss">
  .profile-header {
    position: relative;

    .close-btn {
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      color: #1b1e21;
      text-decoration: none;
      font-size: 2rem;
      font-weight: bold;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .ad-info {
    font-size: 1.1rem;

    .promo-price {
      color: #BD2F40;
    }
  }
</style>
