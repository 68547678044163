<template>
  <b-navbar
    v-if="clientInfo"
    toggleable="lg"
    :type="theme"
    :variant="theme"
    fixed="top"
    ref="topNavbar"
    id="top-navbar"
  >
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-navbar-nav class="d-md-none">
      <cart-status v-if="clientInfo"/>
    </b-navbar-nav>

    <b-navbar-brand :href="currentHomeUrl">
      <b-img
        class="logo"
        :src="clientInfo.logo"
        :alt="clientInfo.name" />
    </b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :href="currentHomeUrl">{{ translation('home') }}</b-nav-item>
        <b-nav-item-dropdown
          v-if="showMenu && categories.length > 0"
          :text="translation('categories')"
          class="my-shadow"
          left
        >
          <category-link
            v-for="(category, idx) of categories"
            :key="idx"
            :category="category"
          />
<!--          <b-dropdown-item
            v-for="(category, idx) of categories"
            :key="idx"
            :to="toURL(category)"
          >
            {{ category.name }}
          </b-dropdown-item>-->
        </b-nav-item-dropdown>
        <b-nav-item v-if="adsCount.marketplace > 0" :to="`/ads-list/${client}`">{{ translation('marketplace') }}</b-nav-item>
        <b-nav-item v-if="adsCount.gifts > 0" :to="`/gifts-list/${client}`">{{ translation('gifts') }}</b-nav-item>
        <b-nav-item v-if="adsCount.vouchers > 0" :to="`/vouchers/${client}`">{{ translation('vouchers') }}</b-nav-item>
        <b-nav-item v-if="adsCount.info > 0" :to="`/info/${client}`">{{ translation('information') }}</b-nav-item>
        <b-nav-item-dropdown
          v-if="languages.length > 1"
          :text="language.toUpperCase()"
          class="my-shadow"
          right
        >
          <b-dropdown-item
            v-for="(language, idx) of languages"
            :key="idx"
            href="#"
            @click.stop="changeLanguage(language.value)"
          >
            {{ language.label }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav v-if="showAuthMenu" class="ml-auto">
        <b-nav-item-dropdown
          :text="translation('user_area')"
          class="my-shadow"
          right
        >
          <b-dropdown-item
            @click.stop="register('private')"
          >
            {{ translation('private_area') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click.stop="register('professional')"
          >
            {{ translation('company_area') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav v-if="showMenu && isAuthenticated" class="ml-auto">
        <b-nav-item
          v-if="isReceptionManager || isReception"
          :to="`/client-home/${client}`"
        >
          {{ translation('company_area') }}
        </b-nav-item>
        <b-nav-item
          v-if="isUser"
          :to="`/user-home/${client}`"
        >
          {{ translation('user_area') }}
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="!showMenu && invited && !registered" class="ml-auto">
        <b-nav-item
          @click.stop="register('private_area')"
        >
          {{ translation('join_us') }}
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="!exclusiveSite">
        <form
          v-if="false"
          :action="submitUrl"
          id="general-search-form"
          class="form-inline my-2 my-lg-0"
        >
          <b-input-group>
            <b-form-input name="filter"></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-success"><font-awesome-icon class="mt-1" icon="search"/></b-button>
            </b-input-group-append>
          </b-input-group>
          <input name="id_client" type="hidden" class="form-control" :value="client">
          <input name="in_search" type="hidden" value="1">
        </form>
        <cart-status v-if="clientInfo"/>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CartStatus from '@/components/cart/CartStatus'
import CategoryLink from '@/components/common/CategoryLink.vue'

export default {
  name: 'TopNavbar',
  components: { CategoryLink, CartStatus },
  computed: {
    ...mapGetters('auth', ['isUser', 'isReceptionManager', 'isReception', 'invited', 'registered', 'isAuthenticated']),
    ...mapGetters(['language', 'translation', 'fullUrl', 'homeUrl', 'theme', 'exclusiveSite']),
    ...mapGetters('clients', ['client', 'clientInfo', 'categories', 'languages']),
    ...mapGetters('ads', ['adsCount']),
    ...mapGetters(['exclusiveSite']),
    submitUrl () {
      return `sponsor/view/${this.client}`
    },
    showMenu () {
      return !this.invited
    },
    showAuthMenu () {
      return !this.isAuthenticated && (!this.exclusiveSite || (!this.invited && !this.registered))
    },
    currentHomeUrl () {
      if (!this.invited) {
        return this.homeUrl
      }
      return `/vouchers/${this.client}`
    }
  },
  methods: {
    ...mapActions(['setNavbarHeight', 'routeReload', 'setLanguage']),
    ...mapActions('clients', ['initClient', 'setHomeActive', 'resetClientInfo']),
    ...mapActions('auth', ['setAccountType']),
    openClientHome (page) {
      this.setHomeActive(page)
      const path = `/client-home/${this.client}`
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path)
      }
    },
    changeLanguage (language) {
      this.setLanguage(language)
      this.resetClientInfo()
      setTimeout(() => {
        this.initClient(this.client)
          .then(() => {
            this.routeReload()
          })
      }, 500)
    },
    register (accountType) {
      this.setAccountType(accountType)
      this.$bvModal.show('auth-modal')
    },
    categoryUrl (category) {
      return category.url ? this.fullUrl(category.url) : null
    },
    toURL (category) {
      /* if (!category.external_url && !category.external_url) {
        return `/category/${this.client}/${category.id_category}`
      } */
      return category.external_url ? `/external/${this.client}/${category.id_category}` : null
    },
    showCategory (category) {
      const toUrl = this.toURL(category)
      const externalUrl = this.categoryUrl(category)
      if (toUrl) {
        this.$router.push(toUrl)
      } else if (externalUrl) {
        window.open(externalUrl)
      }
    }
  },
  mounted () {
    // const obj = this
    /* setTimeout(() => {
      obj.setNavbarHeight(obj.$refs.topNavbar.clientHeight)
    }, 500) */
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    max-height: 40px;
  }

  .navbar-toggler {
    padding: 0;
  }

  .navbar-toggler-icon {
    width: 1.2em;
    height: 1.2em;
  }

  @media (max-width: 500px) {
    #top-navbar {
      padding-top: 0;
      padding-bottom: 0;
      // border-bottom: 1px solid #dee2e6;
    }

    .navbar-brand {
      margin-right: 0;
    }
    .navbar-toggler {
      border: none;
      font-size: 1rem;
    }
  }
</style>
