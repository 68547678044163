<template>
  <b-modal
    id="product-adm-reservation-modal"
    :title="translation('reserve')"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div v-if="product && product.ad_info">
      <div class="mb-3">

        {{ product.ad_info.name }}
      </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text>
                <font-awesome-icon icon="user-alt"/>
              </b-input-group-text>
            </template>
            <div class="form-control static-text text-truncate">
              {{ product.owner_info.full_name }}
            </div>
          </b-input-group>
        </b-form-group>
        <b-form-row>
          <b-col cols="8">
            <b-form-group>
              <b-form-datepicker
                v-model="reservationDate"
                placeholder="Seleccione la fecha"
                :min="min"
                :max="max"
                locale="es"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group>
              <b-form-timepicker
                v-model="reservationTime"
                placeholder="Seleccione la hora"
                label-close-button="Cerrar"
                locale="es"/>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group
          :state="codeState"
          label="Código"
          label-for="code-input"
        >
          <b-form-input
            v-model="code"
            :state="codeState"
            id="code-input"
            required
            @input="resetStatus"
          />
          <b-form-invalid-feedback v-if="!code">
            El código es obligatorio
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="wrongCode">
            El código es incorrecto
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        v-if="!busy"
        size="sm"
        variant="success"
        @click="ok()"
      >
        <font-awesome-icon icon="check"/>
        Reservar
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        <font-awesome-icon icon="ban"/>
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'ProductAdmReservationModal',
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // one year
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 12)
    maxDate.setDate(15)

    return {
      code: '',
      codeState: null,
      wrongCode: false,
      min: today,
      max: maxDate,
      reservationDate: today,
      reservationTime: '9:00'
    }
  },
  computed: {
    ...mapGetters(['busy', 'translation']),
    ...mapGetters('products', ['currentProduct']),
    product () {
      return this.currentProduct
    },
    reservation () {
      const date = moment(this.reservationDate).format('YYYY-MM-DD')
      return moment(`${date} ${this.reservationTime}`).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    ...mapActions('products', ['initProducts', 'reserveProduct']),
    resetModal () {
      this.codeState = null
      this.wrongCode = false
      if (this.product && this.product.reservation && this.product.reservation !== '0000-00-00 00:00:00') {
        const reservationArray = this.product.reservation.split(' ')
        this.reservationDate = reservationArray[0]
        this.reservationTime = reservationArray[1]
      } else {
        this.reservationDate = moment().format('YYYY-MM-DD')
        this.reservationTime = '9:00'
      }
    },
    resetStatus () {
      this.wrongCode = false
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      this.$bvModal.hide('product-adm-reservation-modal')
      const data = {
        order: this.product.id_order,
        code: this.code,
        reservation: this.reservation
      }
      this.reserveProduct(data)
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.success === 'true') {
            this.initProducts()
            this.resetModal()
            this.makeToast(data.heading, data.message, 'success')
            this.$nextTick(() => {
              this.$bvModal.hide('product-adm-reservation-modal')
            })
          } else {
            this.wrongCode = true
            this.codeState = false
            if (data.heading && data.message) {
              this.makeToast(data.heading, data.message, 'danger')
            }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
