export default {
  'SET_APPROVED' (state, approved) {
    state.approved = approved
  },
  'ADD_APPROVED' (state, approved) {
    approved.forEach(request => {
      state.approved.push(request)
    })
  },
  'SET_APPROVED_PAGE' (state, page) {
    state.approvedPage = page
  },
  'SET_APPROVED_COUNT' (state, approvedCount) {
    state.approvedCount = approvedCount
  },
  'CANCEL_APPROVED' (state, userId) {
    const idx = state.approved.findIndex(approved => approved.user_id === userId)
    state.approved.splice(idx, 1)
  },
  'SET_TO_APPROVE' (state, toApprove) {
    state.toApprove = toApprove
  },
  'ADD_TO_APPROVE' (state, toApprove) {
    toApprove.forEach(request => {
      state.toApprove.push(request)
    })
  },
  'SET_TO_APPROVE_PAGE' (state, page) {
    state.toApprovePage = page
  },
  'SET_TO_APPROVE_COUNT' (state, toApproveCount) {
    state.toApproveCount = toApproveCount
  },
  'REMOVE_TO_APPROVE' (state, userId) {
    const idx = state.toApprove.findIndex(toApprove => toApprove.user_id === userId)
    state.toApprove.splice(idx, 1)
  },
  'SET_PENDING' (state, pending) {
    state.pending = pending
  },
  'ADD_PENDING' (state, pending) {
    pending.forEach(request => {
      state.pending.push(request)
    })
  },
  'SET_PENDING_PAGE' (state, page) {
    state.pendingPage = page
  },
  'SET_PENDING_COUNT' (state, pendingCount) {
    state.pendingCount = pendingCount
  },
  'CANCEL_PENDING' (state, userId) {
    const idx = state.pending.findIndex(pending => pending.user_id === userId)
    state.pending.splice(idx, 1)
  },
  'SET_SUGGESTED' (state, suggested) {
    state.suggested = suggested
  },
  'UPDATE_APPROVED_INFINITE_ID' (state) {
    state.approvedInfiniteId += 1
  },
  'UPDATE_TO_APPROVE_INFINITE_ID' (state) {
    state.toApproveInfiniteId += 1
  },
  'UPDATE_PENDING_INFINITE_ID' (state) {
    state.pendingInfiniteId += 1
  },
  'REMOVE_SUGGESTED' (state, userId) {
    const idx = state.suggested.findIndex(suggested => suggested.user_id === userId)
    state.suggested.splice(idx, 1)
  },
  'SET_BUSY' (state, busy) {
    state.busy = busy
  }
}
