import moment from 'moment'

export default {
  vouchers: state => {
    return state.vouchers
  },
  order: state => {
    return state.order
  },
  orderField: state => {
    return state.orderField
  },
  getVoucherById: (state) => ($id) => {
    const result = state.vouchers.find(voucher => voucher.id_voucher === $id)
    if (result) {
      return result
    }
    return null
  },
  currentVoucher: state => {
    return state.currentVoucher
  },
  onlyMyVouchers: state => {
    return state.onlyMyVouchers
  },
  voucherHosts: state => {
    return state.voucherHosts
  },
  influencers: state => {
    return state.influencers
  },
  filters: state => {
    return state.filters
  },
  filtersLocked: state => {
    return state.filtersLocked
  },
  activeFilter: state => {
    return state.activeFilter
  },
  summary: state => {
    return state.summary
  },
  page: state => {
    return state.page
  },
  perPage: state => {
    return state.perPage
  },
  infiniteId: state => {
    return state.infiniteId
  },
  getClientById: (state, geters, rootState, rootGetters) => (id) => {
    const result = state.voucherHosts.find(client => client.id === id)
    if (result) {
      return result
    }
    return rootGetters['clients/getClientById'](id)
  },
  selectedClient: (state, getters) => {
    if (state.filters.voucherHost) {
      return getters.getClientById(state.filters.voucherHost)
    }
    if (state.filters.idClient) {
      return getters.getClientById(state.filters.idClient)
    }
    return null
  },
  resendSuccess: state => {
    return state.resendSuccess
  },
  rejectSuccess: state => {
    return state.rejectSuccess
  },
  reservationSuccess: state => {
    return state.reservationSuccess
  },
  validationSuccess: state => {
    return state.validationSuccess
  },
  orderOptions: (state, getters, rootState, rootGetters) => {
    const options = []
    state.orderOptions.forEach(option => {
      options.push({
        value: option.value,
        text: rootGetters.translation(option.text)
      })
    })
    return options
  },
  activityOptions: (state, getters, rootState, rootGetters) => {
    const options = [
      { value: '', text: rootGetters.translation('select_activity') }
    ]
    state.activityOptions.forEach(activity => {
      options.push(activity)
    })
    return options
  },
  ownerOptions: (state, getters, rootState, rootGetters) => {
    const options = [
      { value: '', text: rootGetters.translation('select_company') }
    ]
    state.ownerOptions.forEach(activity => {
      options.push(activity)
    })
    return options
  },
  vouchersRequestData: (state, getters, rootState, rootGetters) => {
    const data = {}
    // const data = new FormData()
    let field, value
    for (field in getters.filters) {
      // eslint-disable-next-line no-prototype-builtins
      if (getters.filters.hasOwnProperty(field)) {
        value = getters.filters[field]
        if (value === true) {
          value = 1
        } else if (value === false) {
          value = 0
        } else if (String(field).search('/Date/') !== -1) {
          value = value ? moment(value).format('YYYY-MM-DD') : ''
        }
        data[field] = value
        // data.set(field, value)
      }
    }
    data.user_at_wall = rootGetters['clients/client']
    data.onlyMine = getters.onlyMyVouchers ? 1 : 0
    data.offset = getters.page * getters.perPage
    data.perPage = getters.perPage
    return data
  }
}
