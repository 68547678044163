<template>
  <div class="gift-item" :title="gift.name">
    <b-img :src="gift.preview" class="thumbnail" fluid/>
    <b-badge
      v-if="gift.count > 1"
      class="count-badge"
      variant="secondary" pill
    >
      {{ gift.count }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'CardAdGiftItem',
  props: ['gift']
}
</script>

<style lang="scss" scoped>
  .gift-item {
    position: relative;

    .count-badge {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
</style>
