<template>
  <div class="purchases-view mb-3">
    <page-title :title="translation('my_products_administration')" icon="gifts"/>
    <product-filters-bar/>
    <products-list/>
    <product-transfer-modal/>
  </div>
</template>

<script>
import ProductFiltersBar from '@/components/products/ProductFiltersBar'
import ProductsList from '@/components/products/ProductsList'
import PageTitle from '@/components/common/PageTitle'
import ProductTransferModal from '@/components/products/ProductTransferModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductsPanel',
  components: { ProductTransferModal, PageTitle, ProductsList, ProductFiltersBar },
  computed: {
    ...mapGetters(['translation'])
  }
}
</script>

<style scoped>

</style>
