export default {
  'SET_NOTIFICATIONS' (state, notifications) {
    state.notifications = notifications
  },
  'ADD_NOTIFICATIONS' (state, notifications) {
    notifications.forEach(notification => {
      state.notifications.push(notifications)
    })
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'SET_ORDER' (state, order) {
    state.order = order
  },
  'SET_ORDER_FIELD' (state, orderField) {
    state.orderField = orderField
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.notificationsInfiniteId += 1
  },
  'SET_SHOW_DELETED' (state, showDeleted) {
    state.showDeleted = showDeleted
  },
  'UPDATE_NOTIFICATION' (state, fields) {
    const notification = state.notifications.find(notification => notification.id_notification === fields.id_notification)
    if (notification) {
      for (const field in fields) {
        notification[field] = fields[field]
      }
    }
  }
}
