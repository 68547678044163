<template>
  <div class="ad-details" v-if="ad">
    <div class="slider-block">
      <div class="promo-image-container container">
          <!--$slider-->
      </div>
    </div>
    <b-container>
      <div class="d-md-none d-lg-none d-xl-none">
        <h1 class="my-3">{{ ad.name }}</h1>
        <div
          v-if="ad.video"
          class="my-3 text-center"
        >
          <a
            v-if="!showVideo"
            @click.stop="showVideo = true"
            href="#"
            class="px-0"
          >
            {{ translation('view_video') }} <font-awesome-icon icon="play"/>
          </a>
          <a
            v-if="showVideo"
            @click.stop="showVideo = false"
            href="#"
            class="px-0"
          >
            {{ translation('view_slide') }} <font-awesome-icon icon="image"/>
          </a>
        </div>
        <price-block
          class="m-3"
          :ad="ad"
          :showOnlyPrice="!showPromoPrice"
        />
        <ad-link :ad="ad"/>
      </div>
      <b-form-row
        v-if="ad.gallery && ad.gallery.length > 0"
        id="gallery-selector"
        class="mb-3"
      >
        <b-col cols="2" sm="1">
          <b-img
            :src="ad.preview"
            @mouseenter="updateImage(ad.preview, ad.image)"
            @click.stop="updateImage(ad.preview, ad.image)"
            fluid-grow alt=""
          />
        </b-col>
        <b-col
          v-for="(image, idx) of ad.gallery"
          :key="idx"
          cols="2"
          sm="1"
        >
          <b-img
            :src="image.small"
            @mouseenter="updateImage(image.large, image.large)"
            @click.stop="updateImage(image.large, image.large)"
            fluid-grow
            class="gallery-image"
            alt=""
          />
        </b-col>
      </b-form-row>
      <b-row>
        <b-col md="4">
          <inner-image-zoom
            :src="image"
            :zoomSrc="largeImage"
            zoomType="hover"
            :fullscreenOnMobile="true"
            :zoomScale="0.9"
            :zoomPreload="true"
          />
          <div
            v-if="ad.video"
            class="my-3 text-center"
          >
            <a
              v-if="!showVideo"
              @click.stop="showVideo = true"
              href="#"
              class="px-0"
            >
              {{ translation('view_video') }} <font-awesome-icon icon="play"/>
            </a>
            <a
              v-if="showVideo"
              @click.stop="showVideo = false"
              href="#"
              class="px-0"
            >
              {{ translation('view_slide') }} <font-awesome-icon icon="image"/>
            </a>
          </div>
          <div v-if="ad.address" class="contact-block mt-3 d-none d-md-block d-lg-block d-xl-block">
            <ad-address :ad="ad"/>

            <div class="promo-share item">
              <div class="share-buttons"></div>
            </div>
          </div>
        </b-col>
        <b-col md="8">
          <div class="promo-info mb-3">
            <div class="d-none d-md-block d-lg-block d-xl-block">
              <h1>{{ ad.name }}</h1>
            </div>
            <h2 v-if="ad.promotion_name">{{ ad.promotion_name }}</h2>
            <div class="d-none d-md-block d-lg-block d-xl-block">
              <price-block
                v-if="ad.price > 0"
                :section="section"
                :ad="ad"
                :alignment="'left'"
              />
              <div class="d-flex my-3">
                <ad-link :ad="ad"/>
              </div>
            </div>
            <item-description :text="ad.description" :summary="ad.large_summary"/>
            <item-description :text="ad.promotion_terms" :summary="ad.promotion_terms_summary" :title="translation('promotion_terms')"/>

            <div
              v-if="!ad.description && !ad.promotion_terms && ad.owner_info.description"
              class="advertiser-info"
            >
              <item-description :text="ad.owner_info.description" :summary="ad.owner_info.summary"/>
              <div class="">
                <h3 class="section-header">{{ ad.owner_info.commercial_name }}</h3>
                <p>{{ ad.owner_info.address }}</p>
                <div
                  v-if="ad.owner_info.gps_latitude || ad.owner_info.gps_longitude"
                  id="map-panel"
                >
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PriceBlock from '@/components/ads/PriceBlock.vue'
import AdLink from '@/components/ads/AdLink.vue'
import AdAddress from '@/components/ads/AdAddress.vue'
import ItemDescription from '@/components/common/ItemDescription.vue'
import InnerImageZoom from '@/components/common/InnerImageZoom.vue'

export default {
  name: 'AdDetails',
  components: { ItemDescription, AdAddress, AdLink, PriceBlock, InnerImageZoom },
  data () {
    return {
      showVideo: false,
      image: '',
      largeImage: '',
      section: ''
    }
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('ads', ['initAds', 'resetFilters', 'setOnlyVisibleAtMyWalls']),
    ...mapActions('clients', ['initClient']),
    updateImage (image, largeImage) {
      this.image = image
      this.largeImage = largeImage
    }
  },
  computed: {
    ...mapGetters('ads', ['currentAd', 'showPromoPrice']),
    ...mapGetters(['translation']),
    ad () {
      return this.currentAd
    },
    showPrice () {
      return this.currentAd.id_type === 'a'
    }
  },
  created () {
    if (!this.clientInfo) {
      this.initClient(this.$route.params.client)
    }
    this.section = this.$route.params.section
    this.resetFilters({ section: this.section, init: false })
    this.setOnlyVisibleAtMyWalls(false)
    this.initAds({
      id_ad: this.$route.params.ad
    })
      .then(() => {
        this.image = this.ad.preview
        this.largeImage = this.ad.image
      })
    this.setSidebarVisibility(false)
  }
}
</script>

<style lang="scss" scoped>
.gallery-image {
  width: 80px;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: left;
  margin-bottom: 20px;
}

h2 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.contact-block {
  text-align: left;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

address {
  text-align: left;
  padding: 0 0 20px 0;
  margin-bottom: 0;
}
</style>
