<template>
  <div id="client-home" class="mb-3">
    <home-menu-bar/>
    <products-panel v-if="activePage === 'products'"/>
    <vouchers-panel v-if="activePage === 'vouchers'"/>
    <purchases-panel v-if="activePage === 'purchases'"/>
    <notifications-panel v-if="activePage === 'notifications'"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PurchasesPanel from '@/components/purchases/PurchasesPanel'
import ProductsPanel from '@/components/products/ProductsPanel'
import HomeMenuBar from '@/components/common/HomeMenuBar'
import VouchersPanel from '@/components/vouchers/VouchersPanel'
import NotificationsPanel from '@/components/notifications/NotificationsPanel.vue'

export default {
  name: 'UserHomeView',
  components: { NotificationsPanel, VouchersPanel, HomeMenuBar, ProductsPanel, PurchasesPanel },
  computed: {
    ...mapGetters(['translation', 'activePage']),
    validPage () {
      return ['products', 'vouchers', 'purchases', 'notifications'].indexOf(this.$route.params.page) >= 0
    }
  },
  methods: {
    ...mapActions(['setActivePage', 'setSidebarVisibility', 'setActivePage']),
    ...mapActions('clients', ['initClient'])
  },
  created () {
    this.initClient(this.$route.params.id)
  },
  mounted () {
    const obj = this
    obj.setSidebarVisibility(false)
    if (this.validPage) {
      this.setActivePage(this.$route.params.page)
    } else {
      this.setActivePage('vouchers')
    }

    window.addEventListener('resize', () => {
      setTimeout(() => {
        obj.setSidebarVisibility(true)
      }, 250)
    })
    setTimeout(() => {
      obj.setSidebarVisibility(true)
    }, 250)
  }
}
</script>

<style lang="scss" scoped>

</style>
