export default {
  'SET_PRODUCTS' (state, products) {
    state.products = products
  },
  'ADD_PRODUCTS' (state, products) {
    products.forEach(product => {
      state.products.push(product)
    })
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'SET_FILTERS_LOCKED' (state, value) {
    state.filtersLocked = value
  },
  'SET_ORDER' (state, order) {
    state.order = order
  },
  'SET_ORDER_FIELD' (state, orderField) {
    state.orderField = orderField
  },
  'SET_CURRENT_PRODUCT' (state, product) {
    state.currentProduct = product
  },
  'SET_CURRENT_ORDER' (state, orderId) {
    state.currentOrder = orderId
  },
  'SET_AD_HOSTS' (state, adHosts) {
    state.adHosts = adHosts
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'SET_SUMMARY' (state, summary) {
    state.summary = summary
  },
  'SET_ONLY_FOR_CLIENT' (state, onlyForClient) {
    state.onlyForClient = onlyForClient
  },
  'TOGGLE_FILTER' (state, filterName) {
    const value = state.filters[filterName]
    if (filterName !== 'pending') {
      state.filters.pending = false
    }
    if (filterName !== 'reserved') {
      state.filters.reserved = false
    }
    if (filterName !== 'didNotAssist') {
      state.filters.didNotAssist = false
    }
    if (filterName !== 'completed') {
      state.filters.completed = false
    }
    state.filters[filterName] = !value
    state.activeFilter = state.filters[filterName] ? filterName : ''
  },
  'SET_FILTER_VALUE' (state, payload) {
    state.filters[payload.field] = payload.value
  },
  'SET_ACTIVE_FILTER' (state, filterName) {
    state.activeFilter = filterName
  },
  'RESET_FILTERS' (state) {
    state.filters = {
      pagFilter: '',
      creator: 0,
      idClient: 0,
      adHost: 0,
      idOwner: 0,
      idAd: '',
      createdMinDate: '',
      createdMaxDate: '',
      validatedMinDate: '',
      validatedMaxDate: '',
      reservedMinDate: '',
      reservedMaxDate: '',
      status: '',
      pending: false,
      reserved: false,
      didNotAssist: false,
      completed: false
    }
  },
  'RESET_TOGGLE_FILTERS' (state) {
    state.filters.pending = false
    state.filters.reserved = false
    state.filters.didNotAssist = false
    state.filters.completed = false
  },
  'UPDATE_INFINITE_ID' (state) {
    state.productsInfiniteId += 1
  }
}
