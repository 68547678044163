<template>
  <div id="app" ref="app" :class="theme">
    <top-navbar v-if="isRouterAlive"/>
    <div class="container-fluid" :style="{ minHeight: containerHeight + 'px', paddingLeft: contentPadding }">
      <div id="top-div" ref="topDiv"/>
      <router-view v-if="isRouterAlive"/>
    </div>
    <portal-target name="body-target"/>
    <footer-block v-if="isRouterAlive" :style="{ paddingLeft: contentPadding }"/>
    <user-sidebar/>
    <auth-modal/>
    <terms-modal/>
    <privacy-modal/>
    <refund-policy-modal/>
    <cookies-policy-modal/>
    <mobile-bottom-bar/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TopNavbar from '@/components/common/TopNavbar'
import FooterBlock from '@/components/common/FooterBlock'
import UserSidebar from '@/components/common/UserSidebar'
import AuthModal from '@/components/auth/AuthModal.vue'
import MobileBottomBar from '@/components/common/MobileBottomBar.vue'
import TermsModal from '@/components/common/TermsModal.vue'
import RefundPolicyModal from '@/components/common/RefundPolicyModal.vue'
import PrivacyModal from '@/components/common/PrivacyModal.vue'
import CookiesPolicyModal from '@/components/common/CookiesPolicyModal.vue'

export default {
  components: { CookiesPolicyModal, PrivacyModal, RefundPolicyModal, TermsModal, MobileBottomBar, AuthModal, UserSidebar, FooterBlock, TopNavbar },
  computed: {
    ...mapGetters(['busy', 'containerHeight', 'isRouterAlive', 'translation', 'contentPadding', 'theme', 'exclusiveSite', 'isMobileView', 'siteName']),
    ...mapGetters('auth', ['role']),
    ...mapGetters('clients', ['client', 'showCookiesPolicy', 'clientName', 'clientInfo']),
    showCookiesModal () {
      return this.showCookiesPolicy
    }
  },
  watch: {
    showCookiesModal (newValue, oldValue) {
      if (newValue) {
        this.$bvModal.show('cookies-policy-modal')
      }
    },
    clientName (newValue, oldValue) {
      document.title = newValue
    },
    $route: {
      immediate: true,
      handler (to, from) {
        if (this.clientName) {
          document.title = this.clientName
        } else {
          document.title = to.meta.title || this.siteName
        }
      }
    }
  },
  methods: {
    ...mapActions(['setWindowSize']),
    ...mapActions('auth', ['initInvited'])
  },
  mounted () {
    const obj = this
    document.title = this.siteName
    obj.setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
    window.addEventListener('resize', () => {
      setTimeout(() => {
        obj.setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }, 100)
    })
    if (this.exclusiveSite) {
      setInterval(() => {
        if (this.client) {
          this.initInvited()
        }
      }, 60000)
    }
    /* setTimeout(() => {
      this.$bvModal.show('cookies-policy-modal')
    }, 2000) */
  }
}
</script>

<style lang="scss">
@import 'assets/scss/vendors/bootstrap-vue/index';
@import 'assets/scss/themes';

$primary_color: #88BD2F;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.light {
  a:not(.active-button) {
    color: black;
  }
}

.dark {
  a:not(.active-button) {
    color: white;
  }
}

#sidebar-footer {
  top: 80px;
}

.nav-link {
  &.active {
    color: #ffffff;
    background-color: $primary_color;
  }
}

.filter-date {
  right: 0;
}

.static-text {
  background-color: #e9ecef
}

.vdp-datepicker__clear-button {
  position: absolute;
  right: 0.3rem;
  top: 0.5rem;
}

.text-green {
  color: $primary_color;
}

.btn-success {
  background-color: $primary_color;

  &:focus {
    background-color: $primary_color;
  }

  &:hover {
    opacity: 0.8;
    background-color: $primary_color;
  }
}

#top-div {
  height: 84px;
}

.icon-link {
  color: $primary_color;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.primary-link {
  color: $primary_color;
}

.dropdown.my-shadow .dropdown-menu {
  box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.5);
}

h1 {
  font-size: 20pt;
}

h2 {
  font-size: 18px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal-header {
  h4, h5 {
    text-transform: uppercase;
  }
}

@media screen and (max-width: 500px) {
  h5 {
    font-size: 0.9rem;
  }

  h6 {
    font-size: 0.7rem;
  }

  h1 {
    font-size: 14pt;
  }

  h2 {
    font-size: 12pt;
  }

  .btn {
    font-size: 0.8rem;
  }

  #top-div {
    height: 54px;
  }
}
</style>
