<template>
  <b-card no-body class="shadow-lg">
    <template v-slot:header>
      <div class="d-flex justify-content-between">
        <h5 class="mb-0">Buscador avanzado</h5>
        <span
          class="hide-filters-link pl-4"
          @click="toggleFiltersPanel"
        >
          <font-awesome-icon icon="chevron-right"/>
        </span>
      </div>
    </template>
    <b-card-body>
      <advanced-filters/>
    </b-card-body>
    <b-card-footer class="text-center">
      <b-button
        variant="success"
        @click="clearFilters"
      >
        Inicializar filtro
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import AdvancedFilters from './AdvancedFilters'
import { mapActions } from 'vuex'

export default {
  name: 'FiltersPanel',
  components: { AdvancedFilters },
  methods: {
    ...mapActions(['toggleFiltersPanel']),
    ...mapActions('vouchers', ['clearFilters'])
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    background-color: #88BD2F;
    color: white;

    .vdp-datepicker__calendar {
      right: 0;
    }
  }

  .hide-filters-link {
    margin-top: 4px;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

</style>
