<template>
  <b-col cols="12" sm="6" md="4" lg="4" xl="3" class="mb-3">
    <b-card
      no-body
      border-variant="secondary"
      class="user-card"
      :img-src="getImageUrl(user.image)"
      @click="showUser(user)"
    >
      <template #header>
        <div>
          <b-media v-if="user.logo">
            <template #aside>
              <b-img :src="getImageUrl(user.logo)" width="64"></b-img>
            </template>
            <h5 class="mt-0">{{ user.commercial_name || user.full_name }}</h5>
          </b-media>
          <h5 class="mb-0" v-if="!user.logo">{{ user.commercial_name || user.full_name }}</h5>
        </div>
      </template>

      <b-card-body class="info-panel">
        <b-card-text v-if="user.short_summary">
          {{ user.short_summary }}
        </b-card-text>
        <div v-if="fullAddress">
          <b-card-sub-title class="mb-2">{{ translation('address') }}</b-card-sub-title>
          <b-card-text>
            {{ fullAddress }}
          </b-card-text>
          <b-card-text v-if="distance ">
            {{ translation('distance') }}: {{ distance }}
          </b-card-text>
        </div>
        <div
          v-for="(client, idx) of user.clients"
          :key="idx"
          class="mb-3"
          @click.stop
        >
          <b-link :href="client.url" target="_blank" :title="client.name" @click.stop>
            <font-awesome-icon icon="eye"/> {{ translation('view_site') }}
            <span v-if="client.users_count > 1">({{ client.users_count }} {{ translation('users') }})</span>
          </b-link>
        </div>
      </b-card-body>
      <b-card-footer class="text-center">
        <div
          v-if="user.is_rejected"
          class="mb-3"
        >
          {{ translation('sorry_rejected_request') }}
        </div>
        <relation-buttons :user="user" :listType="listType"/>
      </b-card-footer>
    </b-card>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RelationButtons from './RelationButtons'

export default {
  name: 'UserCard',
  components: { RelationButtons },
  props: ['user', 'listType'],
  created () {
    // console.log('User ', this.user.full_name, ' - ', this.user)
  },
  computed: {
    ...mapGetters(['siteUrl', 'translation', 'windowWidth']),
    fullAddress () {
      const address = []
      if (this.user.address) {
        address.push(this.user.address)
      }
      if (this.user.zip) {
        address.push(this.user.zip)
      }
      /* if (this.user.city) {
        address.push(this.user.city)
      } */
      if (this.user.province) {
        address.push(this.user.province)
      }
      return address.join(', ')
    },
    distance () {
      if (this.user.distance && this.user.distance !== '999999999') {
        return this.user.distance_friendly
      }
      return ''
    }
  },
  methods: {
    ...mapActions('users', ['setSelectedUser']),
    showUser (user) {
      this.setSelectedUser(user)
    },
    getImageUrl (image) {
      return image ? `${this.siteUrl}/${image}` : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-panel {
    // min-height: 245px;
  }

  .user-card {
    &:hover {
      cursor: pointer;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
    }
  }
</style>
