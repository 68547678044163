<template>
  <div class="categories-selector container-fluid" ref="component">
    <categories-structure @change="$emit('change')"/>
    <b-input v-model="selectedCategoryIds" name="ad_categories" hidden/>
  </div>
</template>

<script>
import store from '../../store'
import CategoriesStructure from '@/components/categories/CategoriesStructure'
import { mapGetters } from 'vuex'

export default {
  name: 'CategoriesSelector',
  components: { CategoriesStructure },
  store,
  props: ['selected'],
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('categories', ['selectedCategories']),
    selectedCategoryIds: {
      get () {
        return this.selectedCategories ? this.selectedCategories.join(',') : ''
      },
      set (value) {

      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
