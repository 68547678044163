<template>
  <b-card
    no-body
    border-variant="secondary"
    :class="{ 'shadow-lg': hovering }"
    class="mb-3"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <b-card-header>
      <div class="d-flex flex-column flex-sm-row">
        <div class="purchase-date">
          <div class="purchase-title d-none d-sm-block">{{ translation('order_date') }}</div>
          <div class="purchase-value">{{ saleDate }}</div>
        </div>
        <div class="purchase-value ml-0 ml-sm-4 d-flex flex-row flex-sm-column">
          <div class="purchase-title">{{ translation('total') }}</div>
          <div class="purchase-value">{{ purchase.total_paid_tax_incl_friendly }}</div>
        </div>
        <div class="purchase-value ml-0 ml-sm-auto">
          <div class="purchase-title">{{ translation('order_number') }}: {{ purchase.reference }}</div>
          <div class="purchase-value">
            <!--<b-link href="#">{{ translation('view_details') }}</b-link>
            |-->
            <b-link :href="`/order/invoicePreview/${purchase.id_order}`" target="_blank">{{ translation('invoice') }}</b-link>
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-body class="pb-0">
      <div
        v-for="(ad, idx) of purchase.ads"
        :key="idx"
      >
        <purchase-item
          :item="ad"
          :class="{ 'border-bottom-1' : idx < purchase.ads.length - 1 }"
        />
        <hr v-if="idx < purchase.ads.length - 1"/>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import PurchaseItem from '@/components/purchases/PurchaseItem'

export default {
  name: 'PurchaseCard',
  components: { PurchaseItem },
  props: ['purchase'],
  data () {
    return {
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'language']),
    ...mapGetters('clients', ['customBandStyles']),
    showSummary () {
      return this.ad.id_type === 'i'
    },
    saleDate () {
      moment.locale(this.language)
      return moment(this.purchase.payment_time).format('LL')
    }
  },
  created () {
    // console.log('purchase: ', this.purchase)
  }
}
</script>

<style lang="scss" scoped>
.distance-label {
  font-size: 0.9rem;
}

.view-btn {
  &:hover {
    opacity: 0.7;
  }
}

.purchase-title {
  text-transform: uppercase;
}
</style>
