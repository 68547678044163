<template>
  <div
    class="page-title mb-3"
    :class="{ 'bg-light': theme === 'light', 'bg-dark': theme === 'dark', 'text-light': theme === 'dark', 'text-dark': theme === 'light' }"
  >
    <div class="d-flex justify-content-between">
      <h1 class="mb-0">
        {{ title }}
        <font-awesome-icon v-if="icon" :icon="icon"/>
        <small v-if="subtitle" class="subtitle">
          - {{ subtitle }}
        </small>
      </h1>
      <b-link
        v-if="showIcon"
        class="toggle-filter-link"
        @click.stop="toggleListFiltersPanel"
      >
        <font-awesome-icon icon="sliders"/>
      </b-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageTitle',
  props: ['title', 'subtitle', 'icon', 'showFiltersIcon'],
  computed: {
    ...mapGetters(['theme', 'showFilters', 'isMobileView']),
    showIcon () {
      return this.showFiltersIcon && this.isMobileView
    }
  },
  methods: {
    ...mapActions(['toggleListFiltersPanel'])
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin-left: -15px;
  margin-right: -15px;
  padding: 1rem;
}

.subtitle {
  text-transform: none;
}

h1 {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.2rem;
  }
}
</style>
