<template>
  <li
    class="nav-item"
    :style="buttonStyles(section)"
  >
    <a
      class="nav-link"
      :class="{ 'active-button': isActiveButton(section) }"
      :style="buttonStyles(section)"
      @click.stop="action"
    >
      <span>{{ translation(label) }}</span>
      <b-badge
        v-if="count !== undefined"
        pill
        class="ml-2"
      >
        {{ count }}
      </b-badge>
    </a>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CollabNavButton',
  props: ['label', 'section', 'count', 'action', 'active'],
  computed: {
    ...mapGetters('clients', ['customBandStyles']),
    ...mapGetters(['translation'])
  },
  methods: {
    isActiveButton (section) {
      return section === this.active
    },
    buttonStyles (section) {
      return this.active && this.isActiveButton(section) ? this.customBandStyles : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .light {
    a:not(.active-button) {
      color: black;
    }
  }

  .dark {
    a:not(.active-button) {
      color: white;
    }
  }

  .nav-item {
    &:hover {
      cursor: pointer;
    }
  }
</style>
