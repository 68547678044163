export default {
  sales: [],
  filters: {
    name: '',
    id: '',
    code: ''
  },
  orderOptions: [
    { value: 'created', text: 'sell_date' },
    { value: 'total_paid', text: 'price' }
  ],
  order: 'DESC',
  orderField: 'created',
  perPage: 16,
  page: 0,
  salesInfiniteId: +new Date() + 250000
}
