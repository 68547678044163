<template>
  <div class="filter-links my-3 pb-3">
    <div
      class="mb-2 mb-md-0 mr-3 mr-md-2 filter-link d-inline-block"
      :class="{ 'active': activeFilter === 'pending' }"
      @click="toggleFilterValue('pending')"
    >
      <font-awesome-icon icon="circle" class="text-warning"/>
      Pendiente
      <span class="status-name ml-1">{{ summaryData.pending }}</span>
    </div>
    <div
      class="mb-2 mb-md-0 mr-3 mr-md-2 filter-link d-inline-block"
      :class="{ 'active': activeFilter === 'reserved' }"
      @click="toggleFilterValue('reserved')"
    >
      <font-awesome-icon icon="circle" class="text-success"/>
      Reservado
      <span class="status-name ml-1">{{ summaryData.reserved }}</span>
    </div>
    <div
      class="mb-3 mb-md-0 mr-3 mr-md-2 filter-link d-inline-block"
      :class="{ 'active': activeFilter === 'didNotAssist' }"
      @click="toggleFilterValue('didNotAssist')"
    >
      <font-awesome-icon icon="circle" class="text-dark"/>
      No asiste
      <span class="status-name ml-1">{{ summaryData.didNotAssist }}</span>
    </div>
    <div
      class="mb-2 mb-md-0 mr-3 mr-md-2 filter-link d-inline-block"
      :class="{ 'active': activeFilter === 'completed' }"
      @click="toggleFilterValue('completed')"
    >
      <font-awesome-icon icon="circle" class="text-primary"/>
      Completado
      <span class="status-name ml-1">{{ summaryData.completed }}</span>
    </div>
    <div
      class="mb-2 mb-md-0 icon-link d-inline-block"
      @click="clearFilterValues()"
      title="Inicializar el filtro"
    >
      <font-awesome-icon icon="sync-alt"/>
      <span class="d-inline d-md-none ml-2">Inicializar filtro</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'VouchersStatusFilter',
  data () {
    return {
      activeFilter: ''
    }
  },
  computed: {
    ...mapGetters('vouchers', ['summary']),
    summaryData () {
      return this.summary
    }
  },
  methods: {
    ...mapActions('vouchers', ['toggleFilter', 'clearFilters']),
    toggleFilterValue (filterName) {
      this.activeFilter = filterName !== this.activeFilter ? filterName : ''
      this.toggleFilter(filterName)
    },
    clearFilterValues () {
      this.activeFilter = ''
      this.clearFilters()
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-link {
  padding: 0.1rem;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  &.active {
    background-color: #eee;
  }
}
</style>
