<template>
  <div class="d-inline time text-secondary text-truncate">
    {{ createdDate }}
    <font-awesome-icon icon="clock"/>
    {{ createdTime }}
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TimeSlot',
  props: ['value'],
  computed: {
    createdDate () {
      return moment(this.value).format('DD-MM-YYYY')
    },
    createdTime () {
      return moment(this.value).format('HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
  .time {
    font-size: 15px;
    .fa {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
</style>
