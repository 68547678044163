export default {
  'SET_AGGREGATABLE_ADS' (state, aggregatableAds) {
    state.aggregatableAds = aggregatableAds
  },
  'ADD_AGGREGATABLE_ADS' (state, ads) {
    ads.forEach(ad => {
      state.aggregatableAds.push(ad)
    })
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'RESET_FILTERS' (state, filters) {
    state.filters = {
      name: '',
      distance: null,
      maxPrice: 0,
      categories: []
    }
  },
  'SET_ORDER' (state, order) {
    state.order = order
  },
  'SET_ORDER_FIELD' (state, orderField) {
    state.orderField = orderField
  },
  'SET_AD_TYPES' (state, adTypes) {
    state.adTypes = adTypes
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.adsInfiniteId += 1
  },
  'SET_CURRENT_COUNT' (state, count) {
    state.currentCount = count
  },
  'SET_SELECTED_ADS' (state, selectedAds) {
    state.selectedAds = selectedAds
  },
  'SET_SELECTED_CHANGED' (state, value) {
    state.selectedChanged = value
  },
  'SET_SELECTED_BY_ID' (state, selectedByAd) {
    state.selectedByAd = selectedByAd
  },
  'SET_SELECTED_COUNT' (state, selectedCount) {
    state.selectedCount = selectedCount
  },
  'ADD_SELECTED_BY_AD' (state, payload) {
    const key = 'ad_' + payload.ad.id_ad
    if (!Object.keys(state.selectedByAd).includes(key)) {
      state.selectedByAd[key] = {
        ad: payload.ad,
        count: payload.count,
        gifts: [...state.selectedAds]
      }
    }
    state.selectedByAd[key] = payload.ads
  },
  'ADD_SELECTED' (state, ad) {
    if (ad) {
      ad.selected = true
      const id = ad.id_ad
      let idx = state.selectedAds.findIndex(ad => parseInt(ad.id_ad) === parseInt(id))
      const key = 'ad_' + id
      if (idx === -1) {
        state.selectedAds.push({
          ...ad
        })
        idx = 0
        state.selectedCount[key] = 1
      } else {
        state.selectedCount[key]++
      }
    }
  },
  'REMOVE_SELECTED' (state, ad) {
    if (ad) {
      ad.selected = false
      const id = ad.id_ad
      const idx = state.selectedAds.findIndex(ad => parseInt(ad.id_ad) === parseInt(id))
      const key = 'ad_' + id
      if (idx >= 0) {
        if (state.selectedCount[key] > 1) {
          state.selectedCount[key]--
        } else {
          state.selectedAds.splice(idx, 1)
          delete state.selectedCount[key]
        }
      }
    }
  }
}
