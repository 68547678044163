export default {
  aggregatableAds: [],
  currentCount: 1,
  filters: {
    name: '',
    distance: null,
    categories: [],
    maxPrice: 0
  },
  orderOptions: [
    { value: 'name', text: 'name' },
    { value: 'distance', text: 'distance' }
  ],
  distanceOptions: [
    { value: null, text: 'distance' },
    { value: '0.5', text: '500m' },
    { value: '1', text: '1Km' },
    { value: '2', text: '2Km' },
    { value: '5', text: '5Km' },
    { value: '10', text: '10Km' },
    { value: '50', text: '50Km' },
    { value: '100', text: '100Km' },
    { value: '500', text: '500Km' }
  ],
  order: 'ASC',
  orderField: 'name',
  adTypes: 'a',
  perPage: 16,
  page: 0,
  adsInfiniteId: +new Date() + 450000,
  onlyVisibleAtMyWalls: true,
  selectedAds: [],
  selectedCount: {},
  selectedChanged: false,
  selectedByAd: {}
}
