import { render, staticRenderFns } from "./DateFilter.vue?vue&type=template&id=daa2ddf0&scoped=true&"
import script from "./DateFilter.vue?vue&type=script&lang=js&"
export * from "./DateFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daa2ddf0",
  null
  
)

export default component.exports