import Vue from 'vue'

export default {
  initAds: ({ getters, rootGetters, commit, dispatch }) => {
    // console.log('cart id: ', getters.cartId)
    const storedAds = localStorage.getItem(getters.cartId)
    const adIds = []
    const adsDict = {}
    if (storedAds) {
      const stored = JSON.parse(storedAds)
      if (stored) {
        stored.ads.forEach(function (ad) {
          adIds.push(parseInt(ad.id))
          adsDict[ad.key] = ad
          if (ad.includedGifts) {
            ad.includedGifts.forEach(gift => {
              adIds.push(parseInt(gift.id))
            })
          }
        })
      }
    }

    if (rootGetters['clients/client']) {
      // console.log('adIds: ', adIds)
      Vue.http.post(`${rootGetters.siteUrl}/cart`, { id_client: rootGetters['clients/client'], ads: adIds })
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          const ads = []
          const adsInfo = []
          if (!data.empty_cart) {
            const adsInfoDict = {}
            data.ads.forEach(function (ad, idx) {
              adsInfoDict['ad_' + ad.id_ad] = ad
            })
            if (data.ads) {
              let adInfo, k, gifts
              for (const key in adsDict) {
                ads.push({ ...adsDict[key] })
                k = 'ad_' + adsDict[key].id
                adInfo = { ...adsInfoDict[k], count: adsDict[key].count, key }
                if (adsDict[key].includedGifts) {
                  gifts = []
                  adsDict[key].includedGifts.forEach(gift => {
                    gifts.push({ ...adsInfoDict['ad_' + gift.id], count: gift.count })
                  })
                  adInfo.includedGifts = gifts
                }
                adsInfo.push(adInfo)
              }
            }
          } else {
            dispatch('updateCart')
          }
          commit('SET_ADS_INFO', adsInfo)
          commit('SET_ADS', ads)
        })
    }
  },
  updateCount: ({ commit, dispatch }, payload) => {
    if (payload.id) { // TODO change this
      commit('UPDATE_COUNT', { id: payload.id, count: payload.count })
      dispatch('updateCart')
    }
  },
  addToCart: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('ADD_TO_CART', payload)
      dispatch('updateCart')
        .then(cart => {
          resolve(cart)
        })
    })
  },
  updateCart: ({ getters }) => {
    return new Promise((resolve) => {
      const cart = {
        total: getters.totalAmount,
        ads: getters.ads
      }
      localStorage.setItem(getters.cartId, JSON.stringify(cart))
      resolve(cart)
    })
  },
  removeFromCart: ({ getters, commit }, key) => {
    commit('REMOVE_FROM_CART', key)
    const cart = {
      total: getters.totalAmount,
      ads: getters.ads
    }
    localStorage.setItem(getters.cartId, JSON.stringify(cart))
    /* if (getters.adsCount === 0) {
      window.location.replace(`/sponsor/view/${rootGetters['clients/client']}`)
    } */
  },
  emptyCart: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('EMPTY_CART')
      dispatch('updateCart')
        .then(data => {
          resolve(data)
        })
    })
  }
}
