<template>
  <div class="info-ads-view">
    <page-title :title="'Información'" :showFiltersIcon="true" icon="circle-info"/>
    <filters-bar :section="'info'"/>
    <google-map :markers="markers"/>
    <ads-list :section="'info'"/>
  </div>
</template>

<script>
import FiltersBar from '@/components/ads/FiltersBar'
import AdsList from '@/components/ads/AdsList'
import { mapActions, mapGetters } from 'vuex'
import GoogleMap from '@/components/maps/GoogleMap'
import PageTitle from '@/components/common/PageTitle'

export default {
  name: 'InfoAdsView',
  components: { PageTitle, GoogleMap, AdsList, FiltersBar },
  computed: {
    ...mapGetters('clients', ['clientInfo']),
    ...mapGetters('ads', ['markers'])
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('ads', ['setAdTypes', 'setOnlyAllowingGifts', 'setOnlyVisibleAtMyWalls', 'setDoNotSelectGifts', 'setAllowedAtMarketplace']),
    ...mapActions('clients', ['initClient'])
  },
  created () {
    this.setAdTypes('i')
    this.setOnlyAllowingGifts(null)
    this.setOnlyVisibleAtMyWalls(true)
    this.setDoNotSelectGifts(false)
    this.setAllowedAtMarketplace(false)
    if (!this.clientInfo) {
      this.initClient(this.$route.params.id)
    }
    this.setSidebarVisibility(false)
  }
}
</script>

<style scoped>

</style>
