<template>
  <div id="home-page">
    <iframe
      v-if="iframeUrl"
      :src="iframeUrl"
      style="width: 100%; max-width: 100%; height: 100vh"
      allow="fullscreen"
      title=""
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'IframeView',
  data () {
    return {
      iframeUrl: ''
    }
  },
  computed: {
    ...mapGetters(['translation', 'activePage']),
    ...mapGetters('clients', ['categoryById'])
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('clients', ['initClient', 'initClientBySlug'])
  },
  created () {
    this.setSidebarVisibility(false)
    if (this.$route.params.client && this.$route.params.category) {
      this.initClient(this.$route.params.client)
        .then(() => {
          const category = this.categoryById(this.$route.params.category)
          this.iframeUrl = category.external_url
        })
    }
  }
}
</script>

<style lang="scss" scoped>
iframe {
  border: none;
}
</style>
