<template>
  <div class="content-body">
    <page-title :title="translation('sold_products_administration')" icon="gift"/>
    <b-nav class="action-links">
      <b-nav-item @click="validate">
        <font-awesome-icon icon="calendar-check"/>
        <span class="ml-2">{{ translation('validate') }}</span>
      </b-nav-item>
      <b-nav-item @click="reserve">
        <font-awesome-icon icon="calendar-alt"/>
        <span class="ml-2">{{ translation('reserve') }}</span>
      </b-nav-item>
    </b-nav>
    <product-filters-bar />
    <products-list />
    <infinite-loading @infinite="infiniteHandler" :identifier="productsInfiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <transition name="fade">
      <filters-panel v-show="showFilters" class="filters-panel"/>
    </transition>
    <div
      v-show="showButton"
      class="filter-toggler"
      @click="toggleFilters"
    >
      <font-awesome-icon icon="chevron-left"/>
      Filtrar
    </div>
    <product-adm-reservation-modal />
    <product-adm-validation-modal />
    <filters-modal />
  </div>
</template>

<script>
import ProductsList from '@/components/productsAdm/ProductsList'
import FiltersModal from '@/components/productsAdm/FiltersModal'
import FiltersPanel from '@/components/productsAdm/FiltersPanel'
import PageTitle from '@/components/common/PageTitle'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'
import ProductAdmReservationModal from '@/components/productsAdm/ProductAdmReservationModal.vue'
import ProductAdmValidationModal from '@/components/productsAdm/ProductAdmValidationModal.vue'
import ProductFiltersBar from '@/components/products/ProductFiltersBar.vue'

export default {
  name: 'ProductsAdm',
  components: { ProductFiltersBar, ProductAdmValidationModal, ProductAdmReservationModal, PageTitle, FiltersPanel, FiltersModal, ProductsList, InfiniteLoading },
  methods: {
    ...mapActions(['toggleFiltersPanel', 'hideFiltersPanel']),
    ...mapActions('clients', ['initClient']),
    ...mapActions('products', ['loadProducts', 'initProducts', 'setOnlyForClient', 'clearFilters']),
    toggleFilters () {
      if (this.showModal) {
        this.$bvModal.show('filters-modal')
      } else {
        this.toggleFiltersPanel()
      }
    },
    infiniteHandler ($state) {
      this.loadProducts()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    },
    validate () {
      this.$bvModal.show('product-validation-modal')
    },
    reserve () {
      this.$bvModal.show('product-reservation-modal')
    }
  },
  mounted () {
    this.initClient(this.$route.params.id)
    // console.log('Client - created: ', this.client)

    // this.setSiteUrl(window.location.protocol + '//' + window.location.hostname)
    this.hideFiltersPanel()
    this.setOnlyForClient(true)
    this.clearFilters()
  },
  computed: {
    ...mapGetters(['translation', 'busy', 'showFilters', 'windowWidth', 'windowHeight']),
    ...mapGetters('clients', ['client']),
    ...mapGetters('products', ['productsInfiniteId']),
    showButton () {
      return !this.showFilters
    },
    showModal () {
      return this.windowWidth <= 500 || this.windowHeight <= 724
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-body {
    position: relative;
  }

  .filters-panel {
    top: 80px;
    right: 0;
    width: 500px;
    position: fixed;
    z-index: 1000;
  }

  @media screen and (max-width: 500px) {
    .filters-panel {
      width: 100%;
    }
  }

  .filter-toggler {
    position: fixed;
    top: 76px;
    right: -4px;
    padding: 12px;
    opacity: 0.6;
    color: #ffffff;
    background-color: #88BD2F;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 1s;
  }

  .fade-leave {

  }

  .fade-leave-active {
    transition: opacity 1s;
    opacity: 0;
  }

  .slide-enter {

  }

  .slide-enter-active {
    animation: slide-in 1s ease-out forwards;
  }

  .slide-leave {

  }

  .slide-leave-active {
    animation: slide-out 1s ease-out forwards;
  }

  .action-links {
    margin-left: -1rem;
  }

  @keyframes slide-out {
    from {
      transform: translateX(-500px);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-in {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-500px);
    }
  }
</style>
