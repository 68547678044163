import Vue from 'vue'

export default {
  checkIfValid: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (payload.order && payload.code) {
        commit('SET_BUSY', true, { root: true })
        const productRequestData = {
          id_client: rootGetters['clients/client'],
          code: payload.code,
          id_order: payload.order,
          user_at_wall: rootGetters['clients/client']
        }
        Vue.http.post(`${rootGetters.siteUrl}/products/is_valid`, productRequestData)
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not find any valid product!'))
          })
          .then(data => {
            commit('SET_CURRENT_PRODUCT', data.product || null)
            resolve(data)
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not find any valid product!'))
          })
      } else {
        reject(new Error('No code provided!'))
      }
    })
  },
  initProducts: ({ commit, getters, rootGetters }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      const productsRequestData = getters.requestData
      if (id) {
        productsRequestData.id = id
      }
      Vue.http.post(`${rootGetters.siteUrl}/products/get`, productsRequestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get products!'))
        })
        .then(data => {
          if (id) {
            commit('SET_CURRENT_PRODUCT', data.products ? data.products[0] : null)
          } else {
            commit('clients/SET_CLIENTS', data.clients, { root: true })
            commit('SET_AD_HOSTS', data.adHosts)
            commit('SET_PRODUCTS', data.products)
            commit('SET_SUMMARY', data.summary)
          }
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get products!'))
        })
    })
  },
  loadProducts: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/products/get`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get products!'))
        })
        .then(data => {
          commit('ADD_PRODUCTS', data.products)
          resolve(data.products)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get products!'))
        })
    })
  },
  setCurrentProduct: ({ commit }, product) => {
    commit('SET_CURRENT_PRODUCT', product)
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initProducts')
  },
  setOrder: ({ commit, dispatch }, order) => {
    commit('SET_ORDER', order)
    dispatch('initProducts')
  },
  setOrderField: ({ commit, dispatch }, orderField) => {
    commit('SET_ORDER_FIELD', orderField)
    dispatch('initProducts')
  },
  transfer: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        ...payload
      }
      Vue.http.post(`${rootGetters.siteUrl}/admin/sales/transfer`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not transfer ad!'))
        })
    })
  },
  validateProduct: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        id_order: payload.order,
        code: payload.code
      }
      Vue.http.post(`${rootGetters.siteUrl}/products/validate`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not validate ad!'))
        })
    })
  },
  reserveProduct: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        id_order: payload.order,
        code: payload.code,
        reservation: payload.reservation
      }
      Vue.http.post(`${rootGetters.siteUrl}/products/reserve`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not reserve ad!'))
        })
    })
  },
  toggleFilter: ({ commit, getters, dispatch }, filterName) => {
    if (!getters.filtersLocked) {
      commit('SET_FILTERS_LOCKED', true)
      commit('TOGGLE_FILTER', filterName)
      dispatch('initProducts')
        .then(() => {
          commit('SET_FILTERS_LOCKED', false)
        }, () => {
          commit('SET_FILTERS_LOCKED', false)
        })
    }
  },
  updateFilters: ({ commit, dispatch }) => {
    return dispatch('initProducts')
  },
  clearFilters: ({ commit, dispatch }) => {
    commit('RESET_FILTERS')
    commit('SET_ACTIVE_FILTER', '')
    return dispatch('updateFilters')
  },
  setFilterValue: ({ commit, dispatch }, payload) => {
    commit('SET_FILTER_VALUE', payload)
    return dispatch('initProducts')
  },
  setOnlyForClient: ({ commit }, onlyForClient) => {
    commit('SET_ONLY_FOR_CLIENT', onlyForClient)
  }
}
