export default {
  'SET_CURRENT_USER' (state, currentUserId) {
    state.currentUser = currentUserId
  },
  'SET_SELECTED_USER' (state, user) {
    if (user !== null) {
      state.selectedUser = { ...user }
    } else {
      state.selectedUser = null
    }
  },
  'SET_USERS' (state, users) {
    state.users = users
  },
  'SET_USERS_COUNT' (state, usersCount) {
    state.usersCount = usersCount
  },
  'ADD_USERS' (state, users) {
    users.forEach(user => {
      state.users.push(user)
    })
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.infiniteId += 1
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = { ...filters }
  },
  'SET_FILTER_VALUES' (state, filterValues) {
    state.filterValues = { ...filterValues }
  },
  'UPDATE_USER' (state, fields) {
    const user = state.users.find(user => user.user_id === fields.id)
    if (user) {
      for (const field in fields) {
        user[field] = fields[field]
      }
    }
  }
}
