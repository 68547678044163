<template>
  <div class="mb-3">
    <purchase-ad v-if="!isPack" :item="item"/>
    <purchase-pack v-if="isPack" :item="item"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PurchaseAd from '@/components/purchases/PurchaseAd'
import PurchasePack from '@/components/purchases/PurchasePack'

export default {
  name: 'PurchaseItem',
  components: { PurchasePack, PurchaseAd },
  props: ['item'],
  computed: {
    ...mapGetters(['translation']),
    isPack () {
      return this.item.ad_type === 'p' || this.item.validations.ads
    },
    pending () {
      return this.item.validations.p ? this.item.validations.p.length : 0
    },
    validated () {
      return this.item.validations.v ? this.item.validations.v.length : 0
    },
    reserved () {
      return this.item.validations.r ? this.item.validations.r.length : 0
    },
    transferable () {
      return this.item.validations.t ? this.item.validations.t.length : 0
    }
  },
  methods: {
    ...mapActions('purchases', ['setCurrentAd']),
    transfer () {
      this.setCurrentAd(this.item)
      // console.log(this.item)
      this.$bvModal.show('purchase-transfer-modal')
    }
  }
}
</script>

<style scoped>
.action-button {
  width: 180px;
}
</style>
