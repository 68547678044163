<template>
  <div class="activity-item">
    <div class="activity-name">
      <div
        :class="{ 'base-activity': parseInt(level) === 0 }"
        class="p-1"
      >
        <span
          v-if="!expanded && canBeExpanded(activity)"
          class="activity-link mr-1"
          title="Expandir"
          @click.stop="expanded = true"
        >
          <font-awesome-icon icon="circle-plus"/>
        </span>
        <span
          class="activity-link"
          @click.stop="selectActivity(activity)"
        >
          <span
            class="p-1"
            :class="{ 'selected': activity.id_activity === selected }"
          >
            {{ activity.name }}
            <b-badge v-if="activity.count > 0 && activity.show_count" pill variant="light">{{ activity.count }}</b-badge>
          </span>
        </span>
      </div>
    </div>
    <div v-if="activity.children" class="pl-4">
      <div v-if="expanded">
        <activity-item
          v-for="subActivity of activity.children"
          :key="subActivity.id_activity"
          :activity="subActivity"
          :level="level + 1"
          :expand="expanded"
          @change="$emit('change', subActivity)"
        />
      </div>
      <div v-if="!expanded">
        <div
          v-for="(subActivity, index) of activity.children"
          :key="subActivity.id_activity"
          class="activity-link d-inline-block p-1 mr-2"
        >
          <span
            v-if="canBeExpanded(subActivity)"
            class="activity-link mr-1"
            title="Expandir"
            @click.stop="expanded = true"
          >
            <font-awesome-icon icon="circle-plus"/>
          </span>
          <span
            class="activity-link"
            @click.stop="selectActivity(subActivity)"
          >
            <span
              class="p-1"
              :class="{ 'selected': subActivity.id_activity === selected }"
            >
              {{ subActivity.name }}
              <b-badge v-if="subActivity.count > 0" pill variant="light">{{ subActivity.count }}</b-badge>
            </span>
            <span v-if="index < activity.children.length - 1">, </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ActivityItem',
  props: ['activity', 'level', 'expand'],
  data () {
    return {
      expanded: this.expand
    }
  },
  computed: {
    ...mapGetters('activities', ['selectedActivity']),
    selected: {
      get () {
        return this.selectedActivity
      },
      set (activity) {
        this.setSelectedActivity(activity)
      }
    }
  },
  methods: {
    ...mapActions('activities', ['setSelectedActivity']),
    ...mapActions('users', ['setUsers']),
    selectActivity (activity) {
      if (this.selected === activity.id_activity) {
        this.selected = ''
      } else {
        this.selected = activity.id_activity
      }
      this.$emit('change', this.selected)
    },
    canBeExpanded (activity) {
      return activity.children && activity.children.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .selected {
    background-color: #88BD2F;
    color: #FFFFFF;
  }

  .base-activity {
    font-weight: bold;
  }

  .activity-link {
    display: inline-block;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
</style>
