export default {
  'SET_CATEGORIES' (state, categories) {
    state.categories = categories
  },
  'SET_SELECTED_CATEGORIES' (state, selectedCategories) {
    if (typeof selectedCategories === 'string') {
      const values = []
      selectedCategories.split(',').forEach(category => {
        values.push(parseInt(category))
      })
      state.selectedCategories = values
    } else if (typeof selectedCategories === 'number') {
      state.selectedCategories = [parseInt(selectedCategories)]
    } else {
      state.selectedCategories = selectedCategories
    }
  },
  'RESET_SELECTED' (state) {
    state.selectedCategories = []
  },
  'ADD_SELECTED' (state, id) {
    const idx = state.selectedCategories.findIndex(category => category === id)
    if (idx < 0) {
      const selected = [...state.selectedCategories]
      selected.push(id)
      state.selectedCategories = selected
    }
  },
  'REMOVE_SELECTED' (state, id) {
    const idx = state.selectedCategories.findIndex(category => category === id)
    if (idx >= 0) {
      const selected = [...state.selectedCategories]
      selected.splice(idx, 1)
      state.selectedCategories = selected
    }
  }
}
