<template>
  <b-media class="mb-2">
    <template #aside>
      <b-img
        v-if="product.thumbnail"
        :src="product.thumbnail"
        width="64"
        :alt="product.name"
      />
    </template>
    <div>
      <h6 class="my-0">{{ product.name }}</h6>
      <small>{{ product.activity_name }}</small>
    </div>
  </b-media>
</template>

<script>
export default {
  name: 'ProductSmallInfo',
  props: ['product']
}
</script>

<style scoped>

</style>
