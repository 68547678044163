<template>
  <div class="d-flex justify-content-center">
    <b-card class="reset-password-form shadow-lg">
      <b-card-body class="p-0">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <h4 class="mb-3">{{ translation('create_new_password') }}</h4>
          <b-form-group>
            {{ translation('create_new_password_message') }}
          </b-form-group>
          <b-form-group
            label-for="password-input"
            :state="passwordState"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <font-awesome-icon icon="lock"/>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="password"
                :state="passwordState"
                id="password-input"
                :placeholder="translation('new_password')"
                type="password"
                required
                @input="passwordState = null"
              />
              <b-form-invalid-feedback v-if="!validPassword">
                {{ translation('good_password') }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group
            label-for="password-confirmation-input"
            :state="passwordConfirmationState"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <font-awesome-icon icon="lock"/>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="passwordConfirmation"
                :state="passwordConfirmationState"
                id="password-confirmation-input"
                :placeholder="translation('password_confirm')"
                type="password"
                required
                @input="passwordConfirmationState = null"
              />
              <b-form-invalid-feedback v-if="!passwordConfirmation">
                Debe confirmar la contraseña
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="passwordConfirmationDoNotMatch">
                No coincide con la contraseña
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <div class="text-right">
            <b-button
              v-if="!busy"
              size="sm"
              variant="success"
              @click.stop="handleSubmit()"
            >
              <font-awesome-icon icon="check"/>
              {{ translation('change_password') }}
            </b-button>
          </div>
        </form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ResetPassword',
  data () {
    return {
      password: '',
      passwordState: null,
      passwordConfirmation: '',
      passwordConfirmationState: null
    }
  },
  created () {
    if (!this.clientInfo) {
      this.initClient(this.$route.params.client)
    }
    this.setSidebarVisibility(false)
  },
  computed: {
    ...mapGetters(['busy', 'translation']),
    ...mapGetters('clients', ['client', 'clientInfo']),
    ...mapGetters('auth', ['isUser', 'isPasswordValid']),
    validPassword () {
      // Minimum eight characters, at least one letter, one number, one uppercase and can include symbols like #?!@$%^&*-
      return !this.password || this.isPasswordValid(this.password) ? null : false
    },
    passwordConfirmationDoNotMatch () {
      if (!this.passwordConfirmation) {
        return false
      }
      return this.password !== this.passwordConfirmation
    }
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('auth', ['createNewPassword']),
    ...mapActions('clients', ['initClient']),
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      if (!this.password) {
        this.passwordState = false
      } else {
        this.passwordState = this.validPassword
      }
      if (this.passwordConfirmation && this.passwordConfirmation !== this.password) {
        this.passwordConfirmationState = false
      } else if (!this.passwordConfirmation) {
        this.passwordConfirmationState = false
      } else {
        this.passwordConfirmationState = null
      }
      return valid && this.forgotEmailState !== false
    },
    handleSubmit () {
      if (!this.checkFormValidity()) {
        return
      }
      const data = {
        userId: this.$route.params.userId,
        key: this.$route.params.key,
        newPassword: this.password,
        confirmNewPassword: this.passwordConfirmation
      }
      this.createNewPassword(data)
        .then(data => {
          if (data.success === 'true') {
            this.makeToast(data.heading, data.message, 'success')
            const url = this.isUser ? `/user-home/${this.client}` : `/client-home/${this.client}`
            this.$router.push(url)
          } else if (data.heading && data.message) {
            // TODO redirect
            this.makeToast(data.heading, data.message, 'danger')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .reset-password-form {
    max-width: 420px;
  }
</style>
