<template>
  <div v-if="user">
    <b-btn
      v-if="requestVisible"
      variant="success"
      class="text-white text-truncate"
      @click.stop="requestCollab"
    >
      <font-awesome-icon
        v-if="listType !== 'suggested'"
        icon="link"
      />
      <span class="ml-2 text-truncate">
        {{ translation('collaborate') }}
      </span>
    </b-btn>
    <b-btn
      v-if="user.is_approved"
      variant="success"
      class="text-white text-truncate"
      @click.stop="cancelCollab"
    >
      <font-awesome-icon icon="unlink"/>
      <span class="ml-2 text-truncate">
        {{ translation('cancel_collaboration') }}
      </span>
    </b-btn>
    <b-btn
      v-if="cancelVisible"
      variant="success"
      class="text-white text-truncate"
      @click.stop="cancelReq"
    >
      <font-awesome-icon
        v-if="listType !== 'suggested'"
        icon="unlink"
      />
      <span
        v-if="listType !== 'suggested'"
        class="ml-2 text-truncate"
      >
        {{ translation('cancel_request') }}
      </span>
      <span
        v-if="listType === 'suggested'"
        class="ml-2 text-truncate"
      >
        {{ translation('cancel') }}
      </span>
    </b-btn>
    <b-btn
      v-if="rejectVisible"
      variant="success"
      class="text-white text-truncate"
      @click.stop="rejectReq"
    >
      <font-awesome-icon icon="ban"/>
      <span class="ml-2 text-truncate">
        {{ translation('reject') }}
      </span>
    </b-btn>
    <b-btn
      v-if="approveVisible"
      variant="success"
      class="text-white text-truncate ml-2"
      @click.stop="approveReq"
    >
      <font-awesome-icon icon="check"/>
      <span class="ml-2 text-truncate">
        {{ translation('approve') }}
      </span>
    </b-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RelationButtons',
  props: ['user', 'listType'],
  computed: {
    ...mapGetters(['siteUrl', 'windowWidth', 'translation']),
    ...mapGetters('users', ['getUserById']),
    requestVisible () {
      return this.user && (this.user.no_relation || (!this.user.is_pending && !this.user.is_approved && !this.user.is_rejected && !this.user.is_to_be_approved))
    },
    cancelVisible () {
      return this.user && ((this.listType === 'pending' || this.listType === 'search' || this.listType === 'suggested') && this.user.is_pending)
    },
    approveVisible () {
      return this.user && ((this.listType === 'to_approve' && this.user.is_to_be_approved) || (this.listType === 'search' && this.user.is_to_be_approved))
    },
    rejectVisible () {
      return this.user && ((this.listType === 'to_approve' && this.user.is_to_be_approved) || (this.listType === 'search' && this.user.is_to_be_approved))
    },
    smallButtons () {
      return this.windowWidth < 950
    }
  },
  methods: {
    ...mapActions('users', ['updateUser']),
    ...mapActions('relations', ['createRequest', 'cancelRequest', 'approveRequest', 'rejectRequest', 'initPending', 'removeRelation']),
    requestCollab () {
      this.createRequest(this.user)
        .then(data => {
          let variant = 'danger'
          if (data.success === 'true') {
            variant = 'success'
            this.updateUser({
              id: this.user.user_id,
              is_pending: true,
              no_relation: false
            })
            if (this.listType === 'suggested') {
              this.initPending()
            }
          }
          this.makeToast(data.heading, data.message, variant)
        })
    },
    cancelReq () {
      this.cancelRequest(this.user)
        .then(data => {
          let variant = 'danger'
          if (data.success === 'true') {
            variant = 'success'
            this.updateUser({
              id: this.user.user_id,
              is_pending: false,
              no_relation: true
            })
          }
          this.makeToast(data.heading, data.message, variant)
        })
    },
    cancelCollab () {
      this.removeRelation(this.user)
        .then(data => {
          let variant = 'danger'
          if (data.success === 'true') {
            variant = 'success'
            this.updateUser({
              id: this.user.user_id,
              is_approved: false,
              no_relation: true
            })
          }
          this.makeToast(data.heading, data.message, variant)
        })
    },
    rejectReq () {
      this.rejectRequest(this.user)
        .then(data => {
          let variant = 'danger'
          if (data.success === 'true') {
            variant = 'success'
            this.updateUser({
              id: this.user.user_id,
              is_pending: false,
              is_rejected: true,
              no_relation: false
            })
          }
          this.makeToast(data.heading, data.message, variant)
        })
    },
    approveReq () {
      this.approveRequest(this.user)
        .then(data => {
          let variant = 'danger'
          if (data.success === 'true') {
            variant = 'success'
            this.updateUser({
              id: this.user.user_id,
              is_pending: false,
              is_approved: true,
              no_relation: false
            })
          }
          this.makeToast(data.heading, data.message, variant)
        })
    },
    makeToast (title, message, variant = null) {
      if (message) {
        this.$bvToast.toast(message, {
          title: title,
          variant: variant || 'default',
          solid: true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
