<template>
  <b-card
    class="user-card mx-3 mb-5"
    no-body
    @click="showUser(user)"
    :img-src="getImageUrl(user.image)"
    :img-alt="user.full_name"
    :ref="`userCard${user.user_id}`"
  >
    <b-card-body class="p-3">
      <span>{{ user.full_name }}</span>
      <div
        v-for="(client, idx) of user.clients"
        :key="idx"
        class="mb-3"
      >
        <b-link :href="client.url" target="_blank" :title="client.name" @click.stop>
          <font-awesome-icon icon="eye"/> <span>{{ translation('view_site') }}</span>
        </b-link>
      </div>
    </b-card-body>
    <b-card-footer class="text-center">
      <relation-buttons :user="user" :list-type="'suggested'"/>
    </b-card-footer>
  </b-card>
</template>

<script>
import RelationButtons from '@/components/clients/RelationButtons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SuggestedUserCard',
  components: { RelationButtons },
  props: ['user'],
  data () {
    return {
      cardWidth: 60
    }
  },
  computed: {
    ...mapGetters(['siteUrl', 'translation'])
  },
  methods: {
    ...mapActions('users', ['setSelectedUser']),
    getImageUrl (image) {
      return image ? this.siteUrl + '/' + image : ''
    },
    showUser (user) {
      this.setSelectedUser(user)
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-card {
    &:hover {
      cursor: pointer;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
    }
  }
</style>
