import Vue from 'vue'

export default {
  setCurrentVoucher: ({ commit }, voucher) => {
    commit('SET_CURRENT_VOUCHER', voucher)
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initVouchers')
  },
  setOrder: ({ commit, dispatch }, order) => {
    commit('SET_ORDER', order)
    dispatch('initVouchers')
  },
  setOrderField: ({ commit, dispatch }, orderField) => {
    commit('SET_ORDER_FIELD', orderField)
    dispatch('initVouchers')
  },
  addVouchers: ({ commit }, vouchers) => {
    commit('ADD_VOUCHERS', vouchers)
  },
  checkIfValid: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (payload.order && payload.code) {
        commit('SET_BUSY', true, { root: true })
        const productRequestData = {
          id_client: rootGetters['clients/client'],
          code: payload.code,
          id_order: payload.order
        }
        Vue.http.post(`${rootGetters.siteUrl}/vouchers/is_valid`, productRequestData)
          .then(response => {
            commit('SET_BUSY', false, { root: true })
            return response.json()
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not find any valid voucher!'))
          })
          .then(data => {
            commit('SET_CURRENT_VOUCHER', data.product || null)
            resolve(data)
          }, () => {
            commit('SET_BUSY', false, { root: true })
            reject(new Error('Could not find any valid voucher!'))
          })
      } else {
        reject(new Error('No code provided!'))
      }
    })
  },
  initVouchers: ({ commit, getters, rootGetters, dispatch }, voucherId) => {
    return new Promise((resolve, reject) => {
      commit('UPDATE_INFINITE_ID')
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      const vouchersRequestData = getters.vouchersRequestData
      if (voucherId) {
        vouchersRequestData.idVoucher = voucherId
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/get/${rootGetters['clients/client']}`, vouchersRequestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get vouchers!'))
        })
        .then(data => {
          if (!voucherId) {
            commit('clients/SET_CLIENTS', data.clients, { root: true })
            commit('SET_VOUCHERS', data.vouchers)
            commit('SET_VOUCHER_HOSTS', data.voucherHosts)
          } else if (data.vouchers.length > 0) {
            commit('UPDATE_VOUCHER', data.vouchers[0])
          }
          commit('SET_SUMMARY', data.summary)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get vouchers!'))
        })
    })
  },
  initSummary: ({ commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      const vouchersRequestData = getters.vouchersRequestData
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/getSummary/${rootGetters['clients/client']}`, vouchersRequestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get summary!'))
        })
        .then(data => {
          commit('SET_SUMMARY', data.summary)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get summary!'))
        })
    })
  },
  loadPage: ({ commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/get/${rootGetters['clients/client']}`, getters.vouchersRequestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get vouchers!'))
        })
        .then(data => {
          commit('ADD_VOUCHERS', data.vouchers)
          resolve(data.vouchers)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get vouchers!'))
        })
    })
  },
  toggleFilter: ({ commit, getters, dispatch }, filterName) => {
    if (!getters.filtersLocked) {
      commit('SET_FILTERS_LOCKED', true)
      commit('TOGGLE_FILTER', filterName)
      dispatch('initVouchers')
        .then(() => {
          commit('SET_FILTERS_LOCKED', false)
        }, () => {
          commit('SET_FILTERS_LOCKED', false)
        })
    }
  },
  updateFilters: ({ commit, dispatch }) => {
    commit('UPDATE_INFINITE_ID')
    return dispatch('initVouchers')
  },
  clearFilters: ({ commit, dispatch }) => {
    commit('RESET_FILTERS')
    commit('SET_ACTIVE_FILTER', '')
    return dispatch('updateFilters')
  },
  setFilterValue: ({ commit, dispatch }, payload) => {
    commit('SET_FILTER_VALUE', payload)
    return dispatch('initVouchers')
  },
  setOnlyMyVouchers: ({ commit, dispatch, getters }, value) => {
    commit('SET_ONLY_MY_VOCHERS', value)
    return dispatch('initVouchers')
  },
  validateVoucher: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        code: payload.code
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/validate`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not validate voucher!'))
        })
    })
  },
  reserveVoucher: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        id_voucher: payload.id,
        code: payload.code,
        reservation_date: payload.reservationDate,
        reservation_time: payload.reservationTime
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/reserve`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not reserve voucher!'))
        })
    })
  },
  sendVoucher: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const params = {
        id_client: rootGetters['clients/client'],
        id_ad: payload.id
      }
      if (payload.email) {
        params.email = payload.email
      }
      if (payload.name) {
        params.full_name = payload.name
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/send`, params)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not send voucher!'))
        })
    })
  },
  resendVoucher: ({ commit, getters, rootGetters }, id) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/resend/${rootGetters['clients/client']}/${id}`)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not resend voucher!'))
        })
    })
  },
  contractVoucher: ({ commit, getters, rootGetters, dispatch }, voucher) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        id_voucher: voucher.id_voucher
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/set_contracted`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set contracted voucher!'))
        })
        .then(responseData => {
          voucher.contracted_at = responseData.date
          commit('UPDATE_VOUCHER', voucher)
          dispatch('initSummary')
          commit('SET_BUSY', false, { root: true })
          resolve(responseData)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set contracted voucher!'))
        })
    })
  },
  rejectVoucher: ({ commit, getters, rootGetters, dispatch }, voucher) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        id_voucher: voucher.id_voucher
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/reject`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set rejected voucher!'))
        })
        .then(responseData => {
          voucher.rejected_at = responseData.date
          commit('DELETE_VOUCHER', voucher)
          dispatch('initSummary')
          commit('SET_BUSY', false, { root: true })
          resolve(responseData)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not set rejected voucher!'))
        })
    })
  },
  undoVoucherReject: ({ commit, getters, rootGetters, dispatch }, voucher) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        id_voucher: voucher.id_voucher
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/undo_reject`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not undo voucher rejection!'))
        })
        .then(responseData => {
          voucher.rejected_at = responseData.date
          commit('DELETE_VOUCHER', voucher)
          dispatch('initSummary')
          commit('SET_BUSY', false, { root: true })
          resolve(responseData)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not undo voucher rejection!'))
        })
    })
  },
  transfer: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        ...payload
      }
      Vue.http.post(`${rootGetters.siteUrl}/vouchers/transfer`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not transfer voucher!'))
        })
        .then(data => {
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not download voucher!'))
        })
    })
  },
  download: ({ commit, getters, rootGetters }, downloadUrl) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/${downloadUrl}`)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not download voucher!'))
        })
        .then(data => {
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not download voucher!'))
        })
    })
  }
}
