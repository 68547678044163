import { scroller } from 'vue-scrollto/src/scrollTo'

const hasKey = (obj, key) => {
  return Object.keys(obj).includes(key)
}

const createID = () => {
  let dt = Date.now()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

const getTableField = (tableFieldId) => {
  const result = {
    table: '',
    field: '',
    populationField: false
  }
  if (tableFieldId) {
    const fieldArr = String(tableFieldId).split('.')
    if (fieldArr.length >= 2) {
      result.table = fieldArr[0]
      result.field = fieldArr[1]
    }
    if (fieldArr.length === 3 && fieldArr[2] === 'population') {
      result.populationField = true
    }
  }
  return result
}

const goTop = () => {
  const scroll = scroller()
  scroll('#top-div')
}

export default {
  createID,
  hasKey,
  getTableField,
  goTop
}
