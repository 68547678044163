<template>
  <div class="purchase-card">
    <div class="d-block d-sm-none mb-3">
      <b-img :src="item.image" class="mb-3 w-100" :alt="item.name"></b-img>
      <div class="d-flex justify-content-between">
        <div class="">
          <div class="mb-2">
            <b-link href="#">{{ item.name }}</b-link>
          </div>
          <div class="mb-2">
            <span>{{ translation('owner') }}: {{ item.commercial_name }}</span>
          </div>
          <div class="mb-2">
            <div class="mb-2">{{ translation('count') }}: {{ totalCount }}</div>
            <div class="mb-2">{{ translation('pending') }}: {{ pending }}</div>
            <div class="mb-2">{{ translation('validated_p') }}: {{ validated }}</div>
            <div class="mb-2">{{ translation('reserved_p') }}: {{ reserved }}</div>
            <div class="mb-2">{{ translation('transferable_p') }}: {{ transferable }}</div>
          </div>
        </div>
      </div>
      <b-btn-group class="w-100" v-if="transferable > 0">
        <b-btn
          size="sm w-100"
          @click="transfer"
        >
          <font-awesome-icon icon="arrow-up-from-bracket"/>
          {{ translation('transfer') }}
        </b-btn>
      </b-btn-group>
    </div>
    <b-media class="d-none d-sm-block mb-3">
      <template #aside>
        <b-img :src="item.image" width="120" :alt="item.name"></b-img>
      </template>
      <div class="d-flex justify-content-between">
        <div class="">
          <div class="mb-2">
            <b-link href="#">{{ item.name }}</b-link>
          </div>
          <div class="mb-2">
            <span>{{ translation('owner') }}: {{ item.commercial_name }}</span>
          </div>
          <div class="mb-2">
            <span>{{ translation('count') }}: {{ totalCount }}</span>
            <span class="ml-3">{{ translation('pending') }}: {{ pending }}</span>
            <span class="ml-3">{{ translation('validated_p') }}: {{ validated }}</span>
            <span class="ml-3">{{ translation('reserved_p') }}: {{ reserved }}</span>
            <span class="ml-3">{{ translation('transferable_p') }}: {{ transferable }}</span>
          </div>
        </div>
        <div class="actions flex-shrink-1">
          <div v-if="transferable > 0" class="mb-2 action-button">
            <b-btn
              size="sm w-100"
              @click="transfer"
            >
              <font-awesome-icon icon="arrow-up-from-bracket"/>
              {{ translation('transfer') }}
            </b-btn>
          </div>
        </div>
      </div>
    </b-media>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PurchaseAd',
  props: ['item'],
  computed: {
    ...mapGetters(['translation']),
    totalCount () {
      return this.pending + this.validated + this.reserved
    },
    pending () {
      return this.item.validations.p ? this.item.validations.p.length : 0
    },
    validated () {
      return this.item.validations.v ? this.item.validations.v.length : 0
    },
    reserved () {
      return this.item.validations.r ? this.item.validations.r.length : 0
    },
    transferable () {
      return this.item.validations.t ? this.item.validations.t.length : 0
    }
  },
  methods: {
    ...mapActions('purchases', ['setCurrentAd']),
    transfer () {
      this.setCurrentAd(this.item)
      // console.log(this.item)
      this.$bvModal.show('purchase-transfer-modal')
    }
  }
}
</script>

<style scoped>
.action-button {
  width: 180px;
}
</style>
