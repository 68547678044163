<template>
  <div id="search-users-panel">
    <b-form-row>
      <b-col cols="12" md="4" lg="4" xl="3">
        <b-form-group
          label="Buscar por nombre"
        >
          <b-input-group>
            <b-form-input
              v-model="name"
              @input="nameSearch"
            />
            <b-input-group-append>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4" xl="3">
        <b-form-group
          label="Código postal"
        >
          <b-input-group>
            <b-form-input
              v-model="zip"
              @input="zipSearch"
            />
            <b-input-group-append>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4" xl="3">
        <b-form-group
          label="Ciudad"
        >
          <b-form-select
            v-model="city"
            @change="search"
          >
            <b-select-option
              v-for="option of cityOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4" xl="3">
        <b-form-group
          label="Distancia"
        >
          <b-form-select
            v-model="distance"
            @change="search"
          >
            <b-select-option
              v-for="option of distanceOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <div class="mb-3">
      <span
        class="filter-link"
        :class="{ 'selected': hasVouchers }"
        @click="toggleFilter('hasVouchers')"
      >
        Campañas de solicitudes
      </span>
      <span
        class="filter-link ml-3"
        :class="{ 'selected': hasPromos }"
        @click="toggleFilter('hasPromos')"
      >
        Campañas de promociones
      </span>
      <span
        class="filter-link ml-3"
        :class="{ 'selected': hasContractCategories }"
        @click="toggleFilter('hasContractCategories')"
      >
        Contratación de categorías
      </span>
    </div>
    <activities-filter @change="setActivity"/>
    <search-list v-if="listVisible" id="results-list"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchList from './SearchList'
import ActivitiesFilter from '../common/ActivitiesFilter'
import { scroller } from 'vue-scrollto/src/scrollTo'

export default {
  name: 'SearchPanel',
  components: { ActivitiesFilter, SearchList },
  data () {
    return {
      name: '',
      activity: '',
      city: '',
      zip: '',
      distance: '',
      hasPromos: false,
      hasVouchers: false,
      hasContractCategories: false,
      searchActive: true
    }
  },
  created () {
    this.setUsers([])
    this.resetFilters()
    this.resetAllFilters()
    this.initFilterValues()
  },
  computed: {
    ...mapGetters('activities', ['activities']),
    ...mapGetters('users', ['users', 'filterValues']),
    distanceOptions () {
      return [
        {
          text: '',
          value: ''
        },
        {
          text: '500 m',
          value: 0.5
        },
        {
          text: '1 Km',
          value: 1
        },
        {
          text: '2 Km',
          value: 2
        },
        {
          text: '5 Km',
          value: 5
        },
        {
          text: '10 Km',
          value: 10
        },
        {
          text: '20 Km',
          value: 20
        },
        {
          text: '50 Km',
          value: 50
        }
      ]
    },
    cityOptions () {
      const result = [{
        text: '',
        value: ''
      }]
      if (this.filterValues.cities) {
        this.filterValues.cities.forEach(city => {
          result.push({
            text: `${city.value} (${city.count})`,
            value: city.value
          })
        })
      }
      return result
    },
    listVisible () {
      return this.users.length > 0
    }
  },
  methods: {
    ...mapActions('users', ['setFilters', 'resetFilters', 'initUsers', 'updateInfiniteId', 'setUsers', 'initFilterValues']),
    ...mapActions('activities', ['setSelectedActivity']),
    updateFilters () {
      this.setFilters({
        name: this.name,
        activity: this.activity,
        distance: this.distance,
        city: this.city,
        zip: this.zip,
        has_promos: this.hasPromos ? 1 : '',
        has_vouchers: this.hasVouchers ? 1 : '',
        has_contract_categories: this.hasContractCategories ? 1 : ''
      })
    },
    resetAllFilters () {
      this.name = ''
      this.activity = ''
      this.city = ''
      this.zip = ''
      this.distance = ''
      this.hasPromos = false
      this.hasVouchers = false
      this.hasContractCategories = false
      this.searchActive = true
      this.updateFilters()
    },
    nameSearch () {
      this.updateFilters()
      if (this.name.length >= 3 && this.searchActive) {
        this.searchActive = false
        this.updateInfiniteId()
        this.initUsers()
        setTimeout(() => {
          this.searchActive = true
        }, 250)
      } else if (this.name.length < 3) {
        this.setFilters({
          name: '',
          activity: this.activity,
          distance: this.distance,
          city: this.city,
          zip: this.zip,
          has_promos: this.hasPromos ? 1 : '',
          has_vouchers: this.hasVouchers ? 1 : '',
          has_contract_categories: this.hasContractCategories ? 1 : ''
        })
        this.setUsers([])
      }
    },
    zipSearch () {
      this.updateFilters()
      if (this.zip.length === 5 && this.searchActive) {
        this.searchActive = false
        this.updateInfiniteId()
        this.initUsers()
        setTimeout(() => {
          this.searchActive = true
        }, 250)
      } else if (this.zip.length < 5) {
        this.setFilters({
          name: this.name,
          activity: this.activity,
          distance: this.distance,
          city: this.city,
          zip: '',
          has_promos: this.hasPromos ? 1 : '',
          has_vouchers: this.hasVouchers ? 1 : '',
          has_contract_categories: this.hasContractCategories ? 1 : ''
        })
        this.initUsers()
      }
    },
    setActivity (activity) {
      this.activity = activity
      this.updateFilters()
      this.updateInfiniteId()
      this.initUsers()
        .then(() => {
          this.gotoResult()
        })
    },
    search () {
      this.setSelectedActivity(null)
      this.activity = ''
      this.updateFilters()
      if (this.name.length >= 3 || this.distance || this.city || this.hasPromos || this.hasVouchers || this.hasContractCategories) {
        this.updateInfiniteId()
        this.initUsers()
          .then(() => {
            this.gotoResult()
          })
      } else {
        this.initFilterValues()
        this.setUsers([])
      }
    },
    toggleFilter (filter) {
      this[filter] = !this[filter]
      this.search()
    },
    gotoResult () {
      const scroll = scroller()
      scroll('#results-list')
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-link {
    padding: 0.5rem;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .selected {
    background-color: #88BD2F;
    color: #FFFFFF;
  }
</style>
