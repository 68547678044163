<template>
  <li
    :style="buttonStyles(section)"
    class="nav-item"
    :class="{ 'active-button': isActiveButton(section) }"
    @click.stop="doAction"
    :title="translation(title)"
  >
    <a
      class="nav-link"
      :style="buttonStyles(section)"
      @click.stop="setActivePage(section)"
    >
      <font-awesome-icon :icon="icon"/>
    </a>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeMenuLink',
  props: ['section', 'icon', 'action', 'title'],
  computed: {
    ...mapGetters(['translation', 'activePage']),
    ...mapGetters('clients', ['customBandStyles'])
  },
  methods: {
    ...mapActions(['setActivePage']),
    buttonStyles (section) {
      return section && this.isActiveButton(section) ? this.customBandStyles : ''
    },
    isActiveButton (section) {
      return section === this.activePage
    },
    doAction () {
      if (this.section) {
        this.setActivePage(this.section)
      } else if (this.action) {
        this.action()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .light {
    a:not(.active-button) {
      color: black;
    }
  }

  .dark {
    a:not(.active-button) {
      color: white;
    }
  }
</style>
