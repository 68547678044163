<template>
  <b-navbar
    v-if="isTabletView"
    fixed="bottom"
    :type="theme"
    :variant="theme"
    class="py-0"
  >
    <b-nav>
      <b-nav-item
        :title="translation('home')"
        :href="homeUrl"
      >
        <font-awesome-icon icon="house"/>
      </b-nav-item>
      <b-nav-item
        v-if="adsCount.marketplace > 0"
        :title="translation('marketplace')"
        :to="`/ads-list/${client}`"
      >
        <font-awesome-icon icon="gifts"/>
      </b-nav-item>
      <b-nav-item
        v-if="adsCount.gifts > 0"
        :title="translation('gifts')"
        :to="`/gifts-list/${client}`"
      >
        <font-awesome-icon icon="gift"/>
      </b-nav-item>
      <b-nav-item
        v-if="adsCount.vouchers > 0"
        :title="translation('vouchers')"
        :to="`/vouchers/${client}`"
      >
        <font-awesome-icon icon="envelopes-bulk"/>
      </b-nav-item>
      <b-nav-item
        v-if="adsCount.info > 0"
        :to="`/info/${client}`"
        :title="translation('information')"
      >
        <font-awesome-icon icon="circle-info"/>
      </b-nav-item>
    </b-nav>
    <b-nav
      v-if="showMenu && isAuthenticated"
      class="ml-auto"
    >
      <b-nav-item
        v-if="isReceptionManager || isReception"
        :to="`/client-home/${client}`"
        :title="translation('company_area')"
      >
        <font-awesome-icon icon="user"/>
      </b-nav-item>
      <b-nav-item
        v-if="isUser"
        :to="`/user-home/${client}`"
        :title="translation('user_area')"
      >
        <font-awesome-icon icon="user"/>
      </b-nav-item>
    </b-nav>
    <b-nav
      v-if="showAuthMenu"
      class="ml-auto"
    >
      <b-nav-item
        :title="translation('user_area')"
        @click.stop="register('private_area')"
      >
        <font-awesome-icon icon="user"/>
      </b-nav-item>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MobileBottomBar',
  computed: {
    ...mapGetters(['translation', 'theme', 'isTabletView', 'homeUrl']),
    ...mapGetters('clients', ['client']),
    ...mapGetters('ads', ['adsCount']),
    ...mapGetters('auth', ['isAuthenticated', 'isReceptionManager', 'isReception', 'isUser']),
    showMenu () {
      return !this.invited
    },
    showAuthMenu () {
      return !this.isAuthenticated && (!this.exclusiveSite || (!this.invited && !this.registered))
    }
  },
  methods: {
    ...mapActions('auth', ['setAccountType']),
    register () {
      this.setAccountType('private')
      this.$bvModal.show('auth-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .light {
    a:not(.active-button) {
      color: black;
    }
  }

  .dark {
    a:not(.active-button) {
      color: white;
    }
  }

  @media screen and (min-width: 700px) and (max-width: 1200px) {
    .nav-link {
      font-size: 2rem;
    }
  }
</style>
