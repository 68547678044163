<template>
  <div>
    <b-link
      v-if="ad.voucher_send_url"
      class="btn view-btn pt-2"
      @click="send"
    >
      <font-awesome-icon icon="envelope"/>
      {{ translation('send') }}
    </b-link>
    <b-link
      v-if="ad.voucher_download_url"
      class="btn view-btn pt-2"
      @click="downloadVoucher"
    >
      <font-awesome-icon icon="download"/>
      {{ translation('download') }}
    </b-link>
    <div class="mb-3">
      <add-cart-btn :ad="ad" :section="section"/>
    </div>
  </div>
</template>

<script>
import AddCartBtn from '@/components/cart/AddCartBtn.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdLink',
  components: { AddCartBtn },
  props: ['ad', 'section'],
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('auth', ['isAuthenticated', 'isUser'])
  },
  methods: {
    ...mapActions('ads', ['setCurrentAd']),
    ...mapActions('vouchers', ['download']),
    ...mapActions('auth', ['setAccountType']),
    authenticate () {
      this.setAccountType('private')
      this.$bvModal.show('auth-modal')
    },
    send () {
      if (this.isAuthenticated && this.isUser) {
        this.setCurrentAd(this.ad)
        this.$bvModal.show('voucher-send-modal')
      } else if (!this.isAuthenticated) {
        this.authenticate()
      }
    },
    downloadVoucher () {
      if (this.isAuthenticated && this.isUser) {
        this.download(this.ad.voucher_download_url)
          .then(data => {
            if (data.success === 'true') {
              this.makeToast(data.heading, data.message, 'success')
            } else {
              this.makeToast(data.heading, data.message, 'danger')
            }
          })
      } else if (!this.isAuthenticated) {
        this.authenticate()
      }
    }
  }
}
</script>

<style scoped>

</style>
