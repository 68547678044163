export default {
  cartId: (rootGetters) => {
    return `cart-${rootGetters['clients/client']}`
  },
  ads: (state) => {
    return state.ads
  },
  adsInfo: (state) => {
    return state.adsInfo
  },
  totalAmount: (state) => {
    let result = 0
    state.ads.forEach(ad => {
      result += (ad.price * ad.count)
    })
    return result
  },
  adsCount: (state) => {
    return state.ads.length
  }
}
