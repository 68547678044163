<template>
  <b-col
    cols="6"
    sm="6"
    md="6"
    lg="4"
    xl="3"
    class="category-item package-item ribbon-item"
    :class="{ 'mb-2': isMobileView, 'mb-3': !isMobileView }"
  >
    <b-card
      no-body
      :class="{ 'shadow-lg': hovering, 'text-center pt-0': isMobileView }"
      border-variant="secondary"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <b-card-body
        class="px-0 pb-0"
        :class="{ 'text-center pt-0': isMobileView}"
      >
        <ad-card-header :ad="ad"/>
        <div>
          <b-img :src="ad.preview" alt="" fluid/>
        </div>
        <div class="p-3">
          <div class="pack-name">
            <h5 class="text-left">{{ ad.name }}</h5>
          </div>
          <price-block :ad="ad" :inCard="true"/>
          <b-form-row>
            <b-col class="price-label text-right pr-0">
              {{ translation('count') }}:
            </b-col>
            <b-col v-if="!ad.includedGifts" class="text-left">
              <div class="d-inline-block form-inline">
                <count-select :count="ad.count" :max="20" @change="updateAdsCount"/>
              </div>
            </b-col>
            <b-col v-if="ad.includedGifts" class="text-left">
              {{ ad.count }}
            </b-col>
          </b-form-row>
        </div>
        <b-form-row>
          <b-col
            v-for="gift of ad.includedGifts"
            :key="gift.id_ad"
            cols="4"
          >
            <card-ad-gift-item :gift="gift"/>
          </b-col>
        </b-form-row>
        <b-button-group class="w-100 text-right">
          <b-button squared :style="buttonStyles(hovering)" @click="removeFromCart(ad.key)">
            <font-awesome-icon icon="trash-can"/>
            {{ translation('remove') }}
          </b-button>
        </b-button-group>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CountSelect from '@/components/cart/CountSelect'
import PriceBlock from '@/components/ads/PriceBlock'
import AdCardHeader from '@/components/ads/AdCardHeader'
import CardAdGiftItem from '@/components/cart/CardAdGiftItem'

export default {
  name: 'CartAdCard',
  components: { CardAdGiftItem, AdCardHeader, PriceBlock, CountSelect },
  data () {
    return {
      hovering: false
    }
  },
  props: ['ad'],
  computed: {
    ...mapGetters(['translation', 'isMobileView']),
    ...mapGetters('clients', ['buttonStyles'])
  },
  methods: {
    ...mapActions('cart', ['removeFromCart', 'updateCount']),
    updateAdsCount: function (count) {
      this.updateCount({
        id: this.ad.id_ad,
        count: count
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .price-label {
    font-size: 0.9rem;
  }
</style>
