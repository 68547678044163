// https://vuex.vuejs.org/en/mutations.html

export default {
  'SET_SITE_URL' (state, url) {
    state.siteUrl = url
  },
  'SET_SITE_NAME' (state, name) {
    state.siteName = name
  },
  'SET_HOME_URL' (state, url) {
    state.homeUrl = url
  },
  'SET_LANGUAGE' (state, language) {
    state.language = language
  },
  'TOGGLE_FILTERS_PANEL' (state) {
    state.showFilters = !state.showFilters
  },
  'HIDE_FILTERS_PANEL' (state) {
    state.showFilters = false
  },
  'TOGGLE_LIST_FILTERS_PANEL' (state) {
    state.showListFilters = !state.showListFilters
  },
  'SHOW_LIST_FILTERS_PANEL' (state) {
    state.showListFilters = true
  },
  'HIDE_LIST_FILTERS_PANEL' (state) {
    state.showListFilters = false
  },
  'SET_WINDOW_HEIGHT' (state, height) {
    state.windowHeight = height
  },
  'SET_WINDOW_WIDTH' (state, width) {
    state.windowWidth = width
  },
  'SET_NAVBAR_HEIGHT' (state, height) {
    state.navbarHeight = height
  },
  'SET_FOOTER_HEIGHT' (state, height) {
    state.footerHeight = height
  },
  'SET_TRANSLATIONS' (state, translations) {
    state.translations = translations
  },
  'SET_LOCATION' (state, location) {
    state.location = location
  },
  'SET_ACTIVE_PAGE' (state, activePage) {
    state.activePage = activePage
  },
  'SET_SIDEBAR_ACTIVE' (state, value) {
    state.sideBarActive = value
  },
  'SET_SIDEBAR_VISIBILITY' (state, value) {
    state.sideBarVisible = value
  },
  'SET_BUSY' (state, busy) {
    state.busy = busy
  },
  'SET_INFO_WINDOW_OPEN' (state, infoWindowOpen) {
    state.infoWindowOpen = infoWindowOpen
  },
  'SET_SHOW_MAP' (state, showMap) {
    state.showMap = showMap
  },
  'SET_SHOW_CATEGORY' (state, showCategories) {
    state.showCategories = showCategories
  },
  'SET_ROUTER_ALIVE' (state, isRouterAlive) {
    state.isRouterAlive = isRouterAlive
  },
  'SET_SLUG' (state, slug) {
    state.slug = slug
  },
  'SET_EXCLUSIVE' (state, exclusiveSite) {
    state.exclusiveSite = exclusiveSite
  }
}
