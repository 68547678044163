<template>
  <select class="form-control py-0" size="lg" v-model="itemsCount" @change="countChanged">
    <option
      v-for="(option, idx) of countOptions"
      :key="idx"
      :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'CountSelect',
  props: ['count', 'max'],
  data: function () {
    return {
      itemsCount: this.count ?? 1
    }
  },
  computed: {
    countOptions: function () {
      const result = []
      for (let i = 1; i <= this.maxOptionsCount; i++) {
        result.push({
          label: i,
          value: i
        })
      }
      return result
    },
    maxOptionsCount () {
      if (this.max <= 30) {
        return this.max
      }
      return 30
    }
  },
  methods: {
    countChanged: function () {
      this.$emit('change', this.itemsCount)
    }
  }
}
</script>

<style scoped>

</style>
