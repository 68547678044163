<template>
  <b-sidebar
    id="sidebar-footer"
    aria-label="Sidebar with custom footer"
    no-header shadow no-slide
    z-index="999"
    :visible="sideBarVisible"
    :width="sidebarWidth + 'px'"
    :bg-variant="theme"
    :text-variant="theme === 'dark' ? 'light' : 'dark'"
  >
    <div class="px-3 py-2">
      <b-nav
        v-if="isReceptionManager"
        class="mb-3"
        vertical
      >
        <user-sidebar-link
          section="collaboration"
          label="collaborators_network"
          icon="handshake-simple"
          :endGroup="true"
        />
        <user-sidebar-link
          section="sales"
          label="my_sales"
          icon="sack-dollar"
        />
        <user-sidebar-link
          section="productsAdm"
          label="sold_products_administration"
          icon="gifts"
          :endGroup="true"
        />
        <user-sidebar-link
          section="purchases"
          label="my_purchases"
          icon="cart-plus"
        />
        <user-sidebar-link
          section="products"
          label="my_products_administration"
          icon="gifts"
          :endGroup="true"
        />
        <user-sidebar-link
          section="vouchers"
          label="my_vouchers"
          icon="gift"
        />
        <user-sidebar-link
          section="vouchersAdm"
          label="vouchers_administration"
          icon="gift"
          :endGroup="true"
        />
        <user-sidebar-link
          section="notifications"
          label="notifications"
          icon="envelope"
        />
        <b-nav-item :href="`${siteUrl}/admin`" target="_blank">
          <font-awesome-icon icon="cogs"/>
          <span class="ml-2">{{ translation('client_panel') }}</span>
        </b-nav-item>
        <b-nav-item @click="logout()">
          <font-awesome-icon icon="sign-out"/>
          {{ translation('logout') }}
        </b-nav-item>
      </b-nav>
      <b-nav
        v-if="isReception"
        class="mb-3"
        vertical
      >
        <user-sidebar-link
          section="products"
          label="products_services"
          icon="gifts"
        />
        <user-sidebar-link
          section="vouchers"
          label="vouchers"
          icon="gift"
        />
        <b-nav-item @click="validate">
          <font-awesome-icon icon="calendar-check"/>
          <span class="ml-2">{{ translation('validate') }}</span>
        </b-nav-item>
        <b-nav-item class="mb-3" @click="reserve">
          <font-awesome-icon icon="calendar-alt"/>
          <span class="ml-2">{{ translation('reserve') }}</span>
        </b-nav-item>
        <user-sidebar-link
          section="notifications"
          label="notifications"
          icon="envelope"
        />
        <b-nav-item @click="logout()">
          <font-awesome-icon icon="sign-out"/>
          {{ translation('logout') }}
        </b-nav-item>
      </b-nav>
      <b-nav
        v-if="isUser"
        vertical
      >
        <user-sidebar-link
          section="products"
          label="products_services"
          icon="gifts"
        />
        <user-sidebar-link
          section="vouchers"
          label="vouchers"
          icon="gift"
        />
        <user-sidebar-link
          section="purchases"
          label="purchases"
          icon="cart-plus"
        />
        <user-sidebar-link
          section="notifications"
          label="notifications"
          icon="envelope"
        />
        <b-nav-item @click="logout()">
          <font-awesome-icon icon="sign-out"/>
          {{ translation('logout') }}
        </b-nav-item>
      </b-nav>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserSidebarLink from '@/components/common/UserSidebarLink.vue'

export default {
  name: 'UserSidebar',
  components: { UserSidebarLink },
  computed: {
    ...mapGetters(['translation', 'activePage', 'sideBarVisible', 'sidebarWidth', 'siteUrl', 'theme', 'homeUrl']),
    ...mapGetters('auth', ['isUser', 'isReceptionManager', 'isReception']),
    ...mapGetters('clients', ['client'])
  },
  methods: {
    ...mapActions(['setActivePage']),
    ...mapActions('auth', ['logoutUser']),
    validate () {
      this.$bvModal.show('product-validation-modal')
    },
    reserve () {
      this.$bvModal.show('product-reservation-modal')
    },
    logout () {
      this.logoutUser()
        .then(() => {
          this.$router.push(`/home/${this.client}`)
        })
    }
  }
}
</script>

<style lang="scss">
  .group-bottom {
    border-bottom: 1px solid $secondary;
    padding-bottom: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .b-sidebar {
    &.text-light {
      a {
        color: $white;
      }
    }

    &.text-dark {
      a {
        color: $black;
      }
    }
  }
</style>
