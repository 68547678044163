<template>
  <b-col
    class="selected-item mb-4"
    cols="6" sm="4" md="3"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
    @click.stop="setSelected"
  >
    <b-img
      :src="ad.preview"
      :class="{ 'shadow-sm': hovering }"
      fluid thumbnail
    />
    <font-awesome-icon
      class="delete-icon alert-danger"
      icon="trash-can"
      :title="translation('remove')"
      @click.stop="removeSelectedAd(ad)"
    />
    <b-badge
      v-if="count > 1"
      class="count-badge"
      variant="secondary" pill>
      {{ count }}
    </b-badge>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdSelectedItem',
  props: ['ad'],
  data () {
    return {
      hovering: false
    }
  },
  computed: {
    ...mapGetters('aggregatableAds', ['selectedCount', 'selectedChanged']),
    ...mapGetters(['translation']),
    count () {
      return !this.selectedChanged ? this.selectedCount(this.ad.id_ad) : 0
    }
  },
  methods: {
    ...mapActions('aggregatableAds', ['removeSelected'], 'initAggregatableAds'),
    removeSelectedAd (ad) {
      this.removeSelected(ad)
      // this.initAggregatableAds()
      this.$emit('change')
    },
    setSelected () {
      this.$emit('select', this.ad)
    }
  }
}
</script>

<style lang="scss" scoped>
  .selected-item {
    position: relative;

    &:hover {
      cursor: pointer;
    }

    .delete-icon {
      position: absolute;
      right: 30px;
      top: 20px;
      text-shadow: 1px 1px 5px black, 1px 1px 1px #000, -1px -1px 1px #000;
    }

    .count-badge {
      position: absolute;
      right: 30px;
      bottom: 20px;
    }
  }
</style>
