export default {
  promos: [],
  promosCount: 0,
  perPage: 16,
  page: 0,
  infiniteId: +new Date() + 100000,
  filters: {
    id_owner: ''
  },
  busy: false
}
