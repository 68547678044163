<template>
  <div id="client-home" class="mb-3">
    <home-menu-bar/>
    <collaborators-panel v-if="activePage === 'collaboration'"/>
    <vouchers-panel v-if="activePage === 'vouchers'"/>
    <vouchers-adm v-if="activePage === 'vouchersAdm'"/>
    <sales-panel v-if="activePage === 'sales'"/>
    <purchases-panel v-if="activePage === 'purchases'"/>
    <products-panel v-if="activePage === 'products'"/>
    <products-adm v-if="activePage === 'productsAdm'"/>
    <notifications-panel v-if="activePage === 'notifications'"/>
    <product-validation-modal/>
    <product-reservation-modal/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CollaboratorsPanel from '@/components/clients/CollaboratorsPanel'
import VouchersAdm from '@/components/vouchersAdm/VouchersAdm'
import SalesPanel from '@/components/sales/SalesPanel'
import PurchasesPanel from '@/components/purchases/PurchasesPanel'
import ProductValidationModal from '@/components/products/ProductValidationModal'
import ProductReservationModal from '@/components/products/ProductReservationModal'
import HomeMenuBar from '@/components/common/HomeMenuBar'
import ProductsAdm from '@/components/productsAdm/ProductsAdm.vue'
import ProductsPanel from '@/components/products/ProductsPanel.vue'
import VouchersPanel from '@/components/vouchers/VouchersPanel.vue'
import NotificationsPanel from '@/components/notifications/NotificationsPanel.vue'

export default {
  name: 'ClientHomeView',
  components: { NotificationsPanel, VouchersPanel, ProductsPanel, ProductsAdm, HomeMenuBar, ProductReservationModal, ProductValidationModal, PurchasesPanel, SalesPanel, VouchersAdm, CollaboratorsPanel },
  computed: {
    ...mapGetters(['translation', 'activePage', 'sideBarVisible']),
    validPage () {
      return ['collaboration', 'vouchers', 'vouchersAdm', 'sales', 'purchases', 'products', 'productsAdm', 'notifications'].indexOf(this.$route.params.page) >= 0
    }
  },
  methods: {
    ...mapActions(['setActivePage', 'setSidebarVisibility']),
    ...mapActions('clients', ['initClient'])
  },
  created () {
    // console.log('page: ', this.$route.params.page)
    if (this.validPage) {
      this.setActivePage(this.$route.params.page)
    } else {
      this.setActivePage('collaboration')
    }
    if (!this.clientInfo) {
      this.initClient(this.$route.params.id)
    }
  },
  mounted () {
    const obj = this
    this.setSidebarVisibility(false)
    window.addEventListener('resize', () => {
      setTimeout(() => {
        obj.setSidebarVisibility(true)
      }, 250)
    })
    setTimeout(() => {
      obj.setSidebarVisibility(true)
    }, 250)
  }
}
</script>

<style lang="scss" scoped>
#client-home {
  /*.nav-link {
    &.active {
      background-color: #88BD2F;
    }
  }*/
}
</style>
