<template>
  <b-modal
    id="filters-modal"
    title="Buscador avanzado"
    scrollable
    @ok="clearFilter"
  >
    <advanced-filters/>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        size="sm"
        variant="success"
        @click="ok()"
      >
        Inicializar filtro
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import AdvancedFilters from './AdvancedFilters'
import { mapActions } from 'vuex'

export default {
  name: 'FiltersModal',
  components: { AdvancedFilters },
  methods: {
    ...mapActions('products', ['clearFilters']),
    clearFilter (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.clearFilters()
    }
  }
}
</script>

<style scoped>

</style>
