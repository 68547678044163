<template>
  <div>
    <div class="form-row">
      <cart-ad-card
        v-for="(ad, idx) in adsInfo"
        :key="idx"
        :ad="ad"
      />
    </div>
    <div
      class="py-3 text-center"
      v-if="ads.length > 0"
    >
      <form :action="`${siteUrl}/order/buyAds`" method="post" @submit="submitCart">
        <input type="hidden" name="cartContent" v-model="cartContent"/>
        <b-btn
          type="submit"
          class="client-btn"
          @click="submitCart"
          @mouseover="hovering = true"
          @mouseleave="hovering = false"
          :style="buttonStyles(hovering)"
        >
          <font-awesome-icon icon="money-bills"/>
          {{ translation('buy') }}
        </b-btn>
        <b-btn
          variant="secondary"
          class="ml-2"
          @click="emptyCart"
        >
          <font-awesome-icon icon="trash-can"/>
          {{ translation('remove_all') }}
        </b-btn>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CartAdCard from '@/components/cart/CartAdCard'

export default {
  name: 'AdsCart',
  components: { CartAdCard },
  data: function () {
    return {
      cartContent: '',
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'siteUrl']),
    ...mapGetters('cart', ['ads', 'adsInfo', 'adsCount', 'totalAmount']),
    ...mapGetters('clients', ['client', 'buttonStyles']),
    ...mapGetters('auth', ['isAuthenticated', 'isUser'])
  },
  methods: {
    ...mapActions('cart', ['initAds', 'emptyCart']),
    ...mapActions('clients', ['initClient']),
    ...mapActions('auth', ['setAccountType']),
    submitCart: function () {
      if (this.isAuthenticated && this.isUser) {
        this.cartContent = JSON.stringify({
          client: this.client,
          ads: this.ads
        })
        return false
      } else if (!this.isAuthenticated) {
        this.setAccountType('private')
        this.$bvModal.show('auth-modal')
      }
    },
    makeToast (title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant || 'default',
        solid: true
      })
    },
    setCart () {
      if (this.$route.params.message) {
        const data = JSON.parse(atob(this.$route.params.message))
        const variant = data.success === 'true' ? 'success' : 'danger'
        this.makeToast(data.title, data.message, variant)
      }
      this.initAds()
    },
    initCart () {
      if (this.$route.params.action === 'empty') {
        this.emptyCart()
          .then(() => {
            // console.log('Cart is empty now: ', this.ads.length)
            this.setCart()
          })
      } else {
        this.setCart()
      }
    }
  },
  created () {
    // console.log('AdsCart - client: ', this.$route.params.id)
    if (!this.clientInfo) {
      this.initClient(this.$route.params.id)
        .then(() => {
          this.initCart()
        })
    } else {
      this.initCart()
    }
  }
}
</script>

<style scoped>

</style>
