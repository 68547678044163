import activities from './activities'
import ads from './ads'
import aggregatableAds from './aggregatableAds'
import auth from './auth'
import cart from './cart'
import categories from './categories'
import clients from './clients'
import notifications from './notifications'
import products from './products'
import promos from './promos'
import purchases from './purchases'
import relations from './relations'
import sales from './sales'
import sysUsers from './sysUsers'
import users from './users'
import vouchers from './vouchers'

export default {
  activities,
  ads,
  aggregatableAds,
  auth,
  cart,
  categories,
  clients,
  notifications,
  products,
  promos,
  purchases,
  relations,
  sales,
  sysUsers,
  users,
  vouchers
}
