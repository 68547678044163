<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <b-form-row>
      <ad-card
        v-for="(ad, idx) of ads"
        :key="idx"
        :ad="ad"
        :section="section"
        :observer="observer"
      />
    </b-form-row>
    <infinite-loading @infinite="infiniteHandler" :identifier="adsInfiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-overlay>
</template>

<script>
import AdCard from '@/components/ads/AdCard'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdsList',
  props: ['section'],
  components: { AdCard, InfiniteLoading },
  data () {
    return {
      observer: null
    }
  },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('ads', ['ads', 'adsInfiniteId', 'getById'])
  },
  created () {
    this.observer = new IntersectionObserver(
      this.onElementObserved,
      {
        root: this.$el,
        threshold: 1.0
      }
    )
    this.resetFilters({ section: this.section })
    this.initClient(this.$route.params.id)
      .then(() => {
        this.initAds({ section: this.section })
      })
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('ads', ['initAds', 'loadAds', 'resetFilters', 'updateViewingAd']),
    onElementObserved (entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return
        }

        // this.observer.unobserve(target)

        const obj = this

        setTimeout(() => {
          obj.updateViewingAd(target.attributes['data-id'].value)
        }, 1000)
      })
    },
    infiniteHandler ($state) {
      this.loadAds({ section: this.section })
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
