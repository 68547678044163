<template>
  <div
    class="footer-inner px-0 pt-0"
    ref="footer"
    :style="{ marginLeft: footerLeftMargin + 'px' }"
  >
    <div class="border-bottom container w-100"></div>
    <div v-if="client" class="footer-text container pt-0 pt-lg-4">
      <div class="d-none d-sm-block">
        <div class="row text-center text-sm-left">
          <div class="col-12 col-sm-4">
            <h4>{{ translation('about_us') }}</h4>
            <div v-if="client.phone_number || client.email">
              <div v-if="client.logo">
                <b-img
                  class="footer-logo"
                  :src="client.logo"
                  thumbnail
                />
              </div>
              <div v-if="client.owner_info.commercial_name" class="my-2">
                {{ client.owner_info.commercial_name }}
              </div>
              <div v-if="client.owner_info.nif" class="my-2">
                Cif: {{ client.owner_info.nif }}
              </div>
              <div v-if="client.phone_number || client.email">{{ translation('contact_us_for_info') }}</div>
            </div>
            <div
              v-if="!client.phone_number && !client.email"
              class="d-flex justify-content-left"
            >
              <div class="media logo-container">
                <div v-if="client.logo">
                  <b-img
                    class="footer-logo"
                    :src="client.logo"
                    thumbnail
                  />
                </div>
                <div class="media-body text-left ml-2">
                  <div v-if="client.owner_info.commercial_name">
                    {{ client.owner_info.commercial_name }}
                  </div>
                  <div v-if="client.owner_info.nif">
                    Cif: {{ client.owner_info.nif }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="client.phone_number || client.email"
            class="col-12 col-sm-4"
          >
            <h4>{{ translation('contact_us') }}</h4>
            <div v-if="client.phone_number" class="my-2">
              {{ translation('call_us') }}: {{ client.phone_number }}
            </div>
            <div v-if="client.email" class="my-2">
              {{ translation('email') }}: {{ client.email }}
            </div>
          </div>
          <div v-if="client.address" class="col-12 col-sm-4">
            <h4>{{ translation('address') }}</h4>
            <div class="my-2">
              {{ translation('where_to_find_us') }}: {{ client.address }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-sm-none">
        <div class="d-sm-none">
          <b-form-row>
            <b-col v-if="client.logo" class="text-center">
              <b-img
                class="footer-logo mb-2"
                :src="client.logo"
                thumbnail
              />
            </b-col>
          </b-form-row>
          <b-form-row v-if="client.owner_info.commercial_name">
            <b-col class="text-center">
              {{ client.owner_info.commercial_name }}
            </b-col>
          </b-form-row>
          <b-form-row v-if="client.owner_info.nif">
            <b-col class="text-center">
              Cif: {{ client.owner_info.nif }}
            </b-col>
        </b-form-row>
          <div>
            <div v-if="client.phone_number" class="my-2">
              {{ translation('call_us') }}: {{ client.phone_number }}
            </div>
            <div v-if="client.email" class="my-2">
              {{ translation('email') }}: {{ client.email }}
            </div>
            <div v-if="client.address" class="col-12 col-sm-4">
              <div class="my-2">
                {{ translation('where_to_find_us') }}: {{ client.address }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="links" class="text-center pt-sm-3">
        <span
          v-for="(link, idx) of links"
          :key="idx">
          <b-link @click.stop="link.action">
            {{ link.label }}
          </b-link>
          <span v-if="idx < links.length - 1">|</span>
        </span>
      </div>
      <div v-if="copyright" class="text-center pt-2 pt-sm-3">
        &copy;{{ copyright }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'FooterBlock',
  computed: {
    ...mapGetters(['siteName', 'translation', 'sideBarVisible', 'sidebarWidth']),
    ...mapGetters('clients', ['clientInfo', 'terms', 'privacy', 'refundPolicy']),
    client () {
      return this.clientInfo
    },
    copyright () {
      const currentYear = moment().format('Y')
      return `${currentYear} by ${this.siteName}`
    },
    oneColumnOnly () {
      return !this.client.phone_number && !this.client.email && this.client.address
    },
    footerLeftMargin () {
      return this.sideBarVisible ? this.sidebarWidth : 0
    },
    links () {
      const result = []
      if (this.terms) {
        result.push({
          action: this.showTerms,
          label: this.translation('terms_of_use')
        })
      }
      if (this.privacy) {
        result.push({
          action: this.showPrivacy,
          label: this.translation('privacy_policy')
        })
      }
      if (this.refundPolicy) {
        result.push({
          action: this.showRefundPolicy,
          label: this.translation('refund_policy')
        })
      }
      return result
    }
  },
  methods: {
    ...mapActions(['setFooterHeight']),
    showTerms () {
      this.$bvModal.show('terms-modal')
    },
    showPrivacy () {
      this.$bvModal.show('privacy-modal')
    },
    showRefundPolicy () {
      this.$bvModal.show('refund-policy-modal')
    }
  },
  mounted () {
    const obj = this
    setTimeout(() => {
      obj.setFooterHeight(obj.$refs.footer.clientHeight)
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.footer-inner {
  float: none;
  padding: 30px 15px;
  text-align: center;
  background-color: rgba(220, 220, 220, 1);
  color: #999;
  font-size: 15px;
  padding-left: 0 !important;

  a {
    color: #999;

    &:hover {
      opacity: 0.6;
    }
  }

  .border-bottom {
    margin-bottom: 30px;
    background: none repeat scroll 0 0 #FFFFFF;
    height: 0;
    position: relative;

    &:before {
      border-left: 40px solid rgba(0, 0, 0, 0);
      border-right: 40px solid rgba(0, 0, 0, 0);
      border-top: 20px solid #FFFFFF;
      bottom: -20px;
      content: "";
      left: 50%;
      margin-left: -40px;
      position: absolute;
    }
  }

  .footer-logo {
    max-height: 40px;
    max-width: 140px;
    width: auto;
    height: auto;;
  }

  .logo-container {
    line-height: 1;
    position: relative;
  }

  @media (max-width: 767px) {
    & {
      padding: 0 0 10px;
    }
  }

  @media (max-width: 500px) {
    & {
      padding-bottom: 70px;
    }
  }
}
</style>
