<template>
  <b-modal
    id="cookies-policy-modal"
    size="lg"
    :title="translation('cookies_policy')"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    @ok="handleOk"
  >
    <div
      v-if="showPolicy"
      v-html="cookiesPolicy"
    />
    <div v-if="!showPolicy">
      {{ translation('must_accept_terms_and_privacy_policy') }}
      <b-link
        v-if="cookiesPolicy"
        @click.stop="showPolicy = true"
      >
        {{ translation('third_party_cookies_declaration') }}
      </b-link>
    </div>
    <template v-slot:modal-footer="{ ok, cancel }">
      <div v-if="!showPolicy">
        <b-button
          size="sm"
          variant="primary"
          @click="ok()"
        >
          {{ translation('accept') }}
        </b-button>
        <b-button
          size="sm"
          @click="cancel()"
          class="ml-2"
        >
          {{ translation('reject') }}
        </b-button>
      </div>
      <div v-if="showPolicy">
        <b-button
          size="sm"
          @click="showPolicy = false"
        >
          {{ translation('close') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CookiesPolicyModal',
  data () {
    return {
      showPolicy: false
    }
  },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('clients', ['cookiesPolicy'])
  },
  methods: {
    ...mapActions('clients', ['hideCookiesPolicy']),
    handleOk () {
      this.hideCookiesPolicy()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
