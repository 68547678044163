<template>
  <div v-if="client">
    <b-img
      thumbnail
      class="logo"
      :src="logo"
      :alt="client.name" />
    <span
      v-if="filters.voucherHost"
      class="ml-2"
    >
      Muro de destino:
    </span>
    <span
      v-if="filters.idClient"
      :title="'Id: ' + filters.idClient"
      class="ml-2"
    >
      Muro de origen:
    </span>
    <span
      :title="'Id: ' + filters.voucherHost"
      class="text-green"
    >
      {{ client.name }}
    </span>
    <a
      v-if="client && client.phone_number"
      :href="'tel:' + client.phone_number"
      class="name-link ml-3 text-green"
    >
      <font-awesome-icon icon="phone" class="icon-link"/>
      {{ client.phone_number }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ClientHeader',
  computed: {
    ...mapGetters(['siteUrl']),
    ...mapGetters('vouchers', ['filters', 'selectedClient']),
    logo () {
      return this.siteUrl + '/' + this.client.logo
    },
    filters: function () {
      return this.filters
    },
    client: function () {
      return this.selectedClient
    }
  }
}
</script>

<style scoped>
  .logo {
    max-width: 120px;
    max-height: 40px;
  }
</style>
