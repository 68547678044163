// https://vuex.vuejs.org/en/actions.html
import mixins from '@/mixins'

export default {
  setSiteUrl: (context, url) => {
    context.commit('SET_SITE_URL', url)
  },
  updateUrls: ({ commit, getters, rootGetters }) => {
    const clientInfo = rootGetters['clients/clientInfo']
    /* if (rootGetters['clients/homeIframeURL']) {
      commit('SET_HOME_URL', `/home/${clientInfo.id_client}`)
    /* } else if (clientInfo.domain && clientInfo.subdomain) {
      commit('SET_SITE_URL', `https://${clientInfo.subdomain}.${clientInfo.domain}`)
      commit('SET_HOME_URL', `https://${clientInfo.subdomain}.${clientInfo.domain}`)
    } else */
    if (clientInfo.slug) {
      commit('SET_HOME_URL', `${getters.siteUrl}/cl-${clientInfo.slug}`)
    } else {
      commit('SET_HOME_URL', `${getters.siteUrl}/sponsor/view/${clientInfo.id_client}`)
    }
  },
  toggleFiltersPanel: ({ commit }) => {
    commit('TOGGLE_FILTERS_PANEL')
  },
  hideFiltersPanel: ({ commit }) => {
    commit('HIDE_FILTERS_PANEL')
  },
  toggleListFiltersPanel: ({ commit }) => {
    commit('TOGGLE_LIST_FILTERS_PANEL')
  },
  hideListFiltersPanel: ({ commit }) => {
    commit('HIDE_LIST_FILTERS_PANEL')
  },
  setWindowSize: ({ commit, dispatch, getters }, payload) => {
    commit('SET_WINDOW_HEIGHT', payload.height)
    commit('SET_WINDOW_WIDTH', payload.width)
    if (!getters.isMobileView) {
      commit('SHOW_LIST_FILTERS_PANEL', true)
    }
  },
  setNavbarHeight: ({ commit }, height) => {
    commit('SET_NAVBAR_HEIGHT', height)
  },
  setFooterHeight: ({ commit }, height) => {
    commit('SET_FOOTER_HEIGHT', height)
  },
  setLocation: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) {
        reject(new Error('Geolocation is not available.'))
      }
      navigator.geolocation.getCurrentPosition(pos => {
        // console.log('pos: ', pos)
        commit('SET_LOCATION', { latitude: pos.coords.latitude, longitude: pos.coords.longitude })
        resolve(pos)
      }, err => {
        reject(err)
      })
    })
  },
  setInfoWindowOpen: ({ commit }, value) => {
    commit('SET_INFO_WINDOW_OPEN', value)
  },
  setShowMap: ({ commit }, showMap) => {
    commit('SET_SHOW_MAP', showMap)
  },
  setShowCategories: ({ commit }, showCategories) => {
    commit('SET_SHOW_CATEGORY', showCategories)
  },
  setActivePage: ({ commit }, activePage) => {
    mixins.goTop()
    commit('SET_ACTIVE_PAGE', activePage)
  },
  setSidebarVisibility: ({ getters, commit }, value) => {
    commit('SET_SIDEBAR_ACTIVE', value)
    if (getters.windowWidth > 900) {
      commit('SET_SIDEBAR_VISIBILITY', value)
    } else {
      commit('SET_SIDEBAR_VISIBILITY', false)
    }
  },
  setBusy: ({ commit }, busy) => {
    commit('SET_BUSY', busy)
  },
  setLanguage: ({ commit }, language) => {
    commit('SET_LANGUAGE', language)
  },
  routeReload: ({ commit }) => {
    commit('SET_ROUTER_ALIVE', false)
    setTimeout(() => {
      commit('SET_ROUTER_ALIVE', true)
    }, 0)
  }
}
