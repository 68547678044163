<template>
  <b-col
    cols="12"
    md="6"
  >
    <b-card
      no-body
      border-variant="secondary"
      :class="{ 'shadow-lg': hovering, 'read': notification.read }"
      class="notification-card mb-3"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
      @click="markAsRead()"
    >
      <b-card-header>
        <div class="d-flex flex-column flex-sm-row">
          <font-awesome-icon v-if="notification.icon" :icon="notification.icon"/>
          <div
            :class="{ 'ml-3':notification.icon }"
            class="notification-value flex-grow-1"
          >
            {{ notificationDate }}
          </div>
          <div v-if="notification.full_name" class="notification-value flex-shrink-1">
            {{ notification.full_name }}
          </div>
          <font-awesome-icon
            v-if="!notification.deleted"
            class="delete-link"
            @click="deleteNotification()"
            icon="trash-can"
          />
        </div>
      </b-card-header>
      <b-card-body class="notification-body py-1">
        {{ notification.message }}
      </b-card-body>
      <b-card-footer
        v-if="notification.uri && !notification.action_done"
        class="text-right"
      >
        <b-btn
          size="sm"
          variant="outline-primary"
          @click.stop="executeAction()"
        >
          {{ notification.link_label }}
        </b-btn>
      </b-card-footer>
    </b-card>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'NotificationCard',
  props: ['notification'],
  data () {
    return {
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'language']),
    ...mapGetters('clients', ['client']),
    notificationDate () {
      moment.locale(this.language)
      return moment(this.notification.created).format('LL HH:mm')
    }
  },
  methods: {
    ...mapActions('notifications', ['doAction', 'setRead', 'deleteById']),
    markAsRead () {
      if (!this.notification.read) {
        this.setRead(this.notification.id_notification)
      }
    },
    deleteNotification () {
      this.deleteById(this.notification.id_notification)
    },
    executeAction () {
      this.markAsRead()
      this.doAction(this.notification)
    }
  }
}
</script>

<style lang="scss" scoped>
  .delete-link {
    &:hover {
      color: $danger;
      cursor: pointer;
    }
  }
</style>
