export default {
  sales: state => {
    return state.sales
  },
  filters: state => {
    return state.filters
  },
  requestData: (state, getters, rootState, rootGetters) => {
    const filters = {
      ...state.filters,
      offset: getters.page * getters.perPage,
      limit: getters.perPage
    }
    filters.id_client = rootGetters['clients/client']
    filters.user_at_wall = rootGetters['clients/client']
    filters.order = getters.order
    filters.order_field = getters.orderField
    return filters
  },
  order: state => {
    return state.order
  },
  orderField: state => {
    return state.orderField
  },
  perPage: state => {
    return state.perPage
  },
  page: state => {
    return state.page
  },
  salesInfiniteId: state => {
    return state.salesInfiniteId
  },
  orderOptions: (state, getters, rootState, rootGetters) => {
    const options = []
    state.orderOptions.forEach(option => {
      options.push({
        value: option.value,
        text: rootGetters.translation(option.text)
      })
    })
    return options
  }
}
