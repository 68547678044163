<template>
  <div class="notifications-view mb-3">
    <page-title :title="translation('notifications')" icon="envelope"/>
    <div class="pb-3 text-right">
      <b-btn
        v-if="!showDeleted"
        @click="setShowDeleted(true)"
      >
        <font-awesome-icon icon="trash-can" class="mr-2"/>{{ translation('view_recycle_bin') }}
      </b-btn>
      <b-btn
        v-if="showDeleted"
        @click="setShowDeleted(false)">
        {{ translation('show_all_f') }}
      </b-btn>
    </div>
    <notifications-list />
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import { mapActions, mapGetters } from 'vuex'
import NotificationsList from '@/components/notifications/NotificationsList.vue'

export default {
  name: 'NotificationsPanel',
  components: { NotificationsList, PageTitle },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('notifications', ['showDeleted'])
  },
  methods: {
    ...mapActions('notifications', ['setShowDeleted'])
  }
}
</script>

<style scoped>

</style>
