<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <div
      v-if="notifications.length > 0"
      class="notifications-list"
    >
      <b-row>
        <notification-card
          v-for="(notification, idx) of notifications"
          :key="idx"
          :notification="notification"/>
      </b-row>
      <infinite-loading @infinite="infiniteHandler" :identifier="notificationsInfiniteId">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
    <div v-if="notifications.length === 0">
      <span v-if="showDeleted">{{ translation('no_deleted_notifications') }}</span>
      <span v-if="!showDeleted">{{ translation('no_notifications') }}</span>
    </div>
  </b-overlay>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'
import NotificationCard from '@/components/notifications/NotificationCard.vue'

export default {
  name: 'NotificationsList',
  components: { NotificationCard, InfiniteLoading },
  computed: {
    ...mapGetters(['busy', 'translation']),
    ...mapGetters('notifications', ['notifications', 'notificationsInfiniteId', 'showDeleted']),
    ...mapGetters('clients', ['clientInfo'])
  },
  created () {
    this.initClient(this.$route.params.id)
      .then(() => {
        this.initNotifications()
      })
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('notifications', ['initNotifications', 'loadNotifications']),
    infiniteHandler ($state) {
      this.loadNotifications()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
