<template>
  <b-col
    cols="12"
    sm="6"
    md="6"
    lg="4"
    xl="3"
    class="category-item package-item ribbon-item mb-4"
  >
    <b-card
      no-body
      border-variant="secondary"
      :class="{ 'shadow-lg': hovering }"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <b-card-body class="px-0 pb-0">
        <div class="mb-1 text-truncate">
          <span
            class="name-link"
            :id="'user-link-' + voucher.id_voucher"
            @click="showUserInfo = !showUserInfo"
          >
            <font-awesome-icon icon="user-alt" class="icon-link"/>
            <span class="ml-2">{{ voucher.owner_info.full_name }}</span>
          </span>
        </div>
        <b-popover
          :target="'user-link-' + voucher.id_voucher"
          :title="voucher.owner_info.full_name"
          triggers="hover"
          placement="top"
          :show.sync="showUserInfo"
          :content="voucher.owner_info.full_name"
        >
          <a
            v-if="voucher.owner_info.phone"
            :href="'tel:' + voucher.owner_info.phone"
             class="name-link d-block my-3"
          >
            <font-awesome-icon icon="phone" class="icon-link"/>
            <span class="ml-2">{{ voucher.owner_info.phone }}</span>
          </a>
          <a
            v-if="voucher.owner_info.email"
            :href="'mailto:' + voucher.owner_info.email"
             class="name-link d-block mb-3"
          >
            <font-awesome-icon icon="envelope" class="icon-link"/>
            <span class="ml-2">{{ voucher.owner_info.email }}</span>
          </a>
        </b-popover>
        <div
          v-if="voucher.voucher_host === client"
          class="d-flex justify-content-between"
        >
          <div
            v-if="canReserve"
            class="name-link mb-1"
            @click="reserve"
          >
            <font-awesome-icon icon="calendar" class="icon-link"/>
            <span class="ml-2 text-truncate">Reservar cita</span>
          </div>
          <div
            v-if="canModifyAppointment"
            class="name-link mb-1"
            @click="reserve"
          >
            <font-awesome-icon icon="calendar" class="icon-link"/>
            <span class="ml-2 text-truncate">Modificar cita</span>
          </div>
          <div
            v-if="canBeValidated"
            class="name-link mb-1"
            @click="validate"
          >
            <font-awesome-icon icon="check-double" class="icon-link"/>
            <span class="ml-2 text-truncate">Validar</span>
          </div>
        </div>
        <div
          v-if="voucher.id_status === 'c' || voucher.id_status === 'd'"
          class="mb-1 info-placeholder"
        />
        <hr>
        <status-line :voucher="voucher"/>

        <div class="image-container">
          <img :src="voucherImage" class="card-img" alt="...">
          <font-awesome-icon
            icon="desktop"
            class="preview-link icon-link"
            size="lg"
            @click="launchPreview"
          />
        </div>

        <div class="ad-name my-1">
          {{ voucher.ad_info.name }}
        </div>
        <!--<div class="mb-2 mb-1">
          <span>Influencer:</span>
          <font-awesome-icon icon="user-alt" class="icon-link ml-1"/>
          <span class="ml-1 icon-link">{{ voucher.creator_info.full_name }}</span>
        </div>-->

        <div class="mb-1 d-flex">
          <div>Origen:</div>
          <div
            class="name-link ml-1 text-secondary text-truncate"
            :id="'client-link-' + voucher.id_voucher"
            @click="showClientInfo = !showClientInfo"
          >
            {{ voucher.client_info.name }}
          </div>
          <div
            class="name-link"
            @click="showClientInfo = !showClientInfo"
          >
            <font-awesome-icon
              icon="phone"
              class="icon-link ml-2"
            />
          </div>
        </div>
        <b-popover
          :target="'client-link-' + voucher.id_voucher"
          title="Contactar empresa"
          triggers="hover"
          placement="top"
          :show.sync="showClientInfo"
          :content="voucher.client_info.name"
        >
          <strong>{{ voucher.client_info.name }}</strong>
          <a
            v-if="voucher.client_info.phone_number"
            :href="'tel:' + voucher.client_info.phone_number"
            class="name-link d-block my-3"
          >
            <font-awesome-icon icon="phone" class="icon-link"/>
            <span class="ml-2">{{ voucher.client_info.phone_number }}</span>
          </a>
          <a
            v-if="voucher.client_info.email"
            :href="'mailto:' + voucher.client_info.email"
            class="name-link d-block mb-3"
          >
            <font-awesome-icon icon="envelope" class="icon-link"/>
            <span class="ml-2">{{ voucher.client_info.email }}</span>
          </a>
        </b-popover>
        <div class="mb-1 text-truncate">
          <span>Fecha descarga:</span>
          <time-slot :value="voucher.created"/>
        </div>
        <div class="d-flex justify-content-between mt-2">
          <div class="value-line mb-1">
            <span>Id:</span>
            <span class="ml-1 text-secondary">{{ voucher.id_voucher }}</span>
          </div>
        </div>
        <div v-if="rejectedAndNotAssisted">
          <font-awesome-icon icon="circle" class="text-dark"/>
          <span class="ml-2">No asistió a la cita</span>
        </div>
        <div
          v-if="hasContracted"
          class="mb-1"
        >
          <font-awesome-icon icon="circle" class="text-info"/>
          <span class="ml-2 text-truncate">Ha contratado</span>
        </div>
      </b-card-body>
      <b-card-footer v-if="footerVissible">
        <div
          class="d-flex justify-content-between mt-2"
        >
          <div
            v-if="canResend"
            class="name-link mb-1"
            @click="resend"
          >
            <font-awesome-icon icon="envelope" class="icon-link"/>
            <span class="ml-2 text-truncate">Reenviar</span>
          </div>
          <div
            v-if="canReject"
            class="name-link mb-1"
            @click="reject"
            title="Rechazado por el cliente"
          >
            <font-awesome-icon icon="ban" class="text-danger"/>
            <span class="ml-2 text-truncate">Rechazar</span>
          </div>
          <div
            v-if="canUndoReject"
            class="name-link mb-1"
            @click="undoReject"
            title="Deshacer rechazo del cliente"
          >
            <font-awesome-icon icon="check" class="text-success"/>
            <span class="ml-2 text-truncate">No rechazar</span>
          </div>
          <div
            v-if="canContract"
            class="name-link mb-1"
            @click="contract"
            title="El cliente ha contratado el servicio"
          >
            <font-awesome-icon icon="money-bill" class="icon-link"/>
            <span class="ml-2 text-truncate">Contratar</span>
          </div>
        </div>
      </b-card-footer>
    </b-card>
  </b-col>
</template>

<script>
import TimeSlot from '../common/TimeSlot'
import StatusLine from './StatusLine'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'VoucherCard',
  components: { StatusLine, TimeSlot },
  props: ['voucher'],
  data () {
    return {
      showUserInfo: false,
      showClientInfo: false,
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['siteUrl']),
    ...mapGetters('clients', ['client']),
    ...mapGetters('vouchers', ['rejectSuccess', 'resendSuccess']),
    voucherImage () {
      return this.voucher.ad_info.image
    },
    hasContracted () {
      return this.voucher.contracted_at && this.voucher.contracted_at > '0000-00-00 00:00:00'
    },
    hasReserved () {
      return this.voucher.reservation && this.voucher.reservation > '0000-00-00 00:00:00'
    },
    canBeValidated () {
      return this.voucher.id_status === 'p' || (this.voucher.id_status === 'v' && this.voucher.reserved)
    },
    canContract () {
      return !this.hasContracted && (this.voucher.id_status === 'c' || this.voucher.id_status === 'v')
    },
    canResend () {
      return this.voucher.id_status === 'p' || this.voucher.id_status === 'v'
    },
    canReject () {
      return this.voucher.id_status === 'p' || this.didNotAssist
    },
    canUndoReject () {
      return this.voucher.id_status === 'j'
    },
    canReserve () {
      return this.voucher.id_status === 'p'
    },
    canModifyAppointment () {
      return this.voucher.id_status === 'v' && this.voucher.reserved
    },
    rejectedAndNotAssisted () {
      return this.voucher.id_status === 'j' && this.hasReserved
    },
    didNotAssist () {
      return this.voucher.id_status === 'v' && this.voucher.reservation && this.voucher.reservation <= this.now
    },
    footerVissible () {
      return this.canResend || this.canReject || this.canUndoReject || this.canContract
    },
    now () {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    ...mapActions('vouchers', ['setCurrentVoucher', 'resendVoucher', 'rejectVoucher', 'undoVoucherReject', 'contractVoucher']),
    launchPreview () {
      const url = this.siteUrl + '/sponsor/viewAd/' + this.client + '/0/' + this.voucher.id_ad
      const win = window.open(url, '_blank')
      win.focus()
    },
    reserve () {
      this.setCurrentVoucher(this.voucher)
      this.$bvModal.show('reservation-modal')
    },
    validate () {
      this.setCurrentVoucher(this.voucher)
      this.$bvModal.show('validation-modal')
    },
    showResult (data) {
      if (data.success === 'true') {
        this.makeToast(data.heading, data.message, 'success')
      } else {
        this.makeToast(data.heading, data.message, 'danger')
      }
    },
    resend () {
      this.resendVoucher(this.voucher.id_voucher)
        .then(response => {
          return response.json()
        })
        .then(data => {
          this.showResult(data)
        })
    },
    contract () {
      this.contractVoucher(this.voucher)
        .then(data => {
          this.showResult(data)
        })
    },
    reject () {
      this.rejectVoucher(this.voucher)
        .then(data => {
          this.showResult(data)
        })
    },
    undoReject () {
      this.undoVoucherReject(this.voucher)
        .then(data => {
          this.showResult(data)
        })
    }
  },
  watch: {
    resendSuccess () {
      // console.log('Changed')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border: 1px solid #88BD2F;
    font-size: 15px;
  }

  .name-link {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .info-placeholder {
    height: 24px;
  }

  .ad-name {
    font-size: 17px;
  }

  hr {
    color: #88BD2F;
  }

  .value-line {
    line-height: 1;
  }

  .image-container {
    position: relative;
  }

  .preview-link {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: white;
    color: #88BD2F;
    padding: 0.2rem;
  }
</style>
