<template>
  <li>
    <b-link
      class="dropdown-item iframe-link"
      v-if="category.external_url"
      @click.stop="showCategory()"
    >
      {{ category.name }}
    </b-link>
    <b-link
      class="dropdown-item"
      v-if="category.url"
      :href="category.url"
      target="_blank"
    >
      {{ category.name }}
    </b-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryLink',
  props: ['category'],
  computed: {
    ...mapGetters(['production']),
    ...mapGetters('clients', ['client'])
  },
  methods: {
    categoryUrl (category) {
      return category.url ? this.fullUrl(category.url) : null
    },
    toURL (category) {
      if (this.production) {
        if (!category.external_url && !category.external_url) {
          return `/w/category/${this.client}/${category.id_category}`
        }
        return category.external_url ? `/w/external/${this.client}/${category.id_category}` : null
      } else {
        if (!category.external_url && !category.external_url) {
          return `/category/${this.client}/${category.id_category}`
        }
        return category.external_url ? `/external/${this.client}/${category.id_category}` : null
      }
    },
    showCategory () {
      const toUrl = this.toURL(this.category)
      const externalUrl = this.categoryUrl(this.category)
      if (toUrl) {
        // this.$router.push(toUrl)
        window.location.replace(toUrl)
      } else if (externalUrl) {
        window.open(externalUrl)
      }
    }
  }
}
</script>

<style scoped>

</style>
