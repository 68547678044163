<template>
  <div v-if="showPrice">
    <div
      v-if="isMobileView && inCard"
      class="price-block"
    >
      <div
        v-if="(showOnlyValueCond || showOnlyPriceCond) && price > 0"
        class="text-center price-default-text current-price"
      >
        {{ price | toCurrency }}
      </div>
      <div
        v-if="!showOnlyPriceCond && !showOnlyValueCond && (ad.includedGifts || !showOnlyValueCond && ad.promo_price === ad.price)"
        class="text-center price-default-text current-price"
      >
        {{ ad.full_price }}
      </div>
      <div
        v-if="!showOnlyPriceCond && !showOnlyValueCond && !ad.includedGifts && ad.promo_price < ad.price"
        class="text-center price-default-text current-price"
      >
        {{ ad.full_price }}
      </div>
    </div>
    <div v-if="!isMobileView && (!alignment || alignment === 'right')" class="price-block">
      <div v-if="showOnlyValueCond && price > 0">
        <b-form-row>
          <b-col class="price-label pr-1 text-right">
            {{ ad.valued_at_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text current-price">
            {{ price | toCurrency }}
          </b-col>
        </b-form-row>
      </div>
      <div v-if="showOnlyPriceCond">
        <b-form-row>
          <b-col class="price-label pr-1 text-right">
            {{ ad.price_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text current-price">
            {{ ad.price | toCurrency }}
          </b-col>
        </b-form-row>
      </div>
      <div v-if="!showOnlyPriceCond && !showOnlyValueCond && (ad.includedGifts || !showOnlyValueCond && ad.promo_price === ad.price)">
        <b-form-row>
          <b-col class="price-label pr-1 text-right">
            {{ ad.price_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text current-price">
            {{ ad.full_price }}
          </b-col>
        </b-form-row>
        <b-form-row v-if="ad.units > 1">
          <b-col class="price-label pr-1 text-right">
            {{ ad.units_price_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text small-price">
            ({{ ad.unit_price_friendly }})
          </b-col>
        </b-form-row>
      </div>
      <div v-if="!showOnlyPriceCond && !showOnlyValueCond && !ad.includedGifts && ad.promo_price < ad.price">
        <b-form-row>
          <b-col class="price-label pr-1 text-right">
            {{ ad.price_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text previous-price">
            {{ ad.full_price }}
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="price-label pr-1 text-right">
            {{ ad.promo_price_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text highlighted current-price">
            {{ ad.full_promo_price }}
          </b-col>
        </b-form-row>
        <b-form-row v-if="ad.units > 1">
          <b-col class="price-label pr-1 text-right">
            {{ ad.units_price_label }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text small-price highlighted">
            ({{ ad.unit_price_friendly }})
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="price-label pr-1 text-right">
            {{ translation('you_save') }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text highlighted small-price">
            {{ ad.saves }}
          </b-col>
        </b-form-row>
        <b-form-row v-if="ad.stock <= 5"
        >
          <b-col class="price-label pr-1 text-right">
            {{ translation('stock') }}:
          </b-col>
          <b-col class="pl-0 text-left price-default-text highlighted small-price">
            {{ ad.stock }}
          </b-col>
        </b-form-row>
      </div>
    </div>
    <div v-if="alignment === 'left'" class="price-block d-flex">
      <div v-if="showOnlyValueCond">
        <div class="d-flex">
          <div class="price-label pr-1">
            {{ ad.valued_at_label }}:
          </div>
          <div class="pl-0 text-left price-default-text current-price">
            {{ price | toCurrency }}
          </div>
        </div>
      </div>
      <div v-if="showOnlyPriceCond">
        <div class="d-flex">
          <div class="price-label pr-1">
            {{ ad.price_label }}:
          </div>
          <div class="pl-0 text-left price-default-text current-price">
            {{ ad.price | toCurrency }}
          </div>
        </div>
      </div>
      <div v-if="!showOnlyPriceCond && !showOnlyValueCond && (ad.includedGifts || !showOnlyValueCond && ad.promo_price === ad.price)">
        <div class="d-flex">
          <div class="price-label pr-1">
            {{ ad.price_label }}:
          </div>
          <div class="pl-0 text-left price-default-text current-price">
            {{ ad.full_price }}
          </div>
        </div>
        <div class="d-flex" v-if="ad.units > 1">
          <div class="price-label pr-1">
            {{ ad.units_price_label }}:
          </div>
          <div class="pl-0 text-left price-default-text small-price">
            ({{ ad.unit_price_friendly }})
          </div>
        </div>
      </div>
      <div v-if="!showOnlyPriceCond && !showOnlyValueCond && !ad.includedGifts && ad.promo_price < ad.price">
        <div class="d-flex">
          <div class="price-label pr-1">
            {{ ad.price_label }}:
          </div>
          <div class="pl-0 text-left price-default-text previous-price">
            {{ ad.full_price }}
          </div>
        </div>
        <div class="d-flex">
          <div class="price-label pr-1">
            {{ ad.promo_price_label }}:
          </div>
          <div class="pl-0 text-left price-default-text highlighted current-price">
            {{ ad.full_promo_price }}
          </div>
        </div>
        <div class="d-flex" v-if="ad.units > 1">
          <div class="price-label pr-1">
            {{ ad.units_price_label }}:
          </div>
          <div class="pl-0 text-left price-default-text small-price highlighted">
            ({{ ad.unit_price_friendly }})
          </div>
        </div>
        <div class="d-flex">
          <div class="price-label pr-1">
            {{ translation('you_save') }}:
          </div>
          <div class="pl-0 text-left price-default-text highlighted small-price">
            {{ ad.saves }}
          </div>
        </div>
        <div class="d-flex" v-if="ad.stock <= 5">
          <div class="price-label pr-1">
            {{ translation('stock') }}:
          </div>
          <div class="pl-0 text-left price-default-text highlighted small-price">
            {{ ad.stock }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PriceBlock',
  props: ['ad', 'section', 'showOnlyValue', 'showOnlyPrice', 'alignment', 'inCard'],
  computed: {
    ...mapGetters(['translation', 'isMobileView']),
    showPrice () {
      return this.ad && (this.ad.id_type === 'a' || this.ad.id_type === 'p' || this.ad.id_type === 'v')
    },
    showOnlyPriceCond () {
      return this.showOnlyPrice || (this.section === 'gifts' && this.ad.id_type === 'a')
    },
    showOnlyValueCond () {
      return this.showOnlyValue || (this.section === 'vouchers' && this.ad.id_type === 'v')
    },
    price () {
      if (this.ad.includedGifts) {
        return this.ad.count * this.ad.price
      }
      return this.ad.price
    }
  }
}
</script>

<style lang="scss" scoped>
  .price-label {
    font-size: 0.9rem;
  }

  .previous-price {
    text-decoration: line-through;
  }

  @media screen and (max-width: 500px) {
    .price-default-text {
      font-size: 0.8rem;
    }
  }
</style>
