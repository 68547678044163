<template>
  <div class="mb-2">
    <div
      v-if="voucher.id_status === 'p'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-warning"/>
        <span class="status-name ml-2 text-truncate">{{ voucher.status_friendly }}</span>
      </div>
      <div class="text-truncate ml-2">
        <span class="ml-4">Faltan</span>
        <remaining-days :days="remaining"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'd'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-danger"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'c'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-primary"/>
        <span class="status-name ml-2 text-truncate">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.validated"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'v' && !voucher.reserved"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-success"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.validated"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'v' && voucher.reservation && voucher.reservation > now"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-success"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.reservation"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'v' && voucher.reservation && voucher.reservation <= now"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-dark"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.reservation"/>
      </div>
    </div>
    <div
      v-if="voucher.id_status === 'j'"
      class="d-flex justify-content-between"
    >
      <div class="text-truncate">
        <font-awesome-icon icon="circle" class="text-secondary"/>
        <span class="status-name ml-2">{{ voucher.status_friendly }}</span>
      </div>
      <div class="ml-2">
        <time-slot :value="voucher.rejected_at"/>
      </div>
    </div>
  </div>
</template>

<script>
import RemainingDays from './RemainingDays'
import TimeSlot from '../common/TimeSlot'
import moment from 'moment'

export default {
  name: 'StatusLine',
  components: { RemainingDays, TimeSlot },
  props: ['voucher'],
  computed: {
    remaining () {
      const dt1 = new Date()
      const dt2 = new Date(this.voucher.created)
      const value = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24))
      if (value === 1) {
        return '1 día'
      }
      if (value > 0) {
        return value + ' días'
      }
      return '0 días'
    },
    now () {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
  .status-name {
    line-height: 1;
  }
</style>
