<template>
  <b-col
    v-if="ad"
    cols="12"
    sm="6"
    md="6"
    lg="4"
    xl="3"
    class="category-item package-item ribbon-item"
    :class="{ 'mb-2': isMobileView, 'mb-3': !isMobileView }"
  >
    <b-card
      no-body
      border-variant="secondary"
      class="d-block d-sm-none"
      :class="{ 'shadow-lg': hovering }"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <b-card-body class="px-0 pb-0">
        <ad-card-header :ad="ad" :nameMargin="true"/>
        <b-media class="mb-3">
          <template #aside>
            <b-link @click.stop="addAd()">
              <b-img :src="ad.preview" width="120" alt=""></b-img>
            </b-link>
          </template>
          <div class="">
            <h5 class="text-left">{{ ad.name }}</h5>
            <price-block :ad="ad" :showOnlyValue="true"/>
            <b-form-row v-if="ad.distance_friendly" class="mb-2">
              <b-col class="distance-label text-right">Distancia:</b-col>
              <b-col class="distance-label pl-0">{{ ad.distance_friendly }}</b-col>
            </b-form-row>
          </div>
        </b-media>
        <b-button-group class="w-100 text-right">
          <b-button
            class="btn view-btn pt-2"
            :style="customBandStyles"
            @click.stop="addAd()"
          >
            {{ translation('select') }}
          </b-button>
        </b-button-group>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      border-variant="secondary"
      class="d-none d-sm-block"
      :class="{ 'shadow-lg': hovering }"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <b-card-body class="px-0 pb-0">
        <ad-card-header :ad="ad" :nameMargin="true"/>
        <b-link @click.stop="addAd()">
          <b-img :src="ad.preview" alt="" fluid/>
        </b-link>
        <div class="p-3">
          <div class="pack-name">
            <h5 class="text-left">{{ ad.name }}</h5>
          </div>
          <price-block :ad="ad" :showOnlyValue="true"/>
          <b-form-row v-if="ad.distance_friendly" class="mb-2">
            <b-col class="distance-label text-right">Distancia:</b-col>
            <b-col class="distance-label pl-0">{{ ad.distance_friendly }}</b-col>
          </b-form-row>
          <div v-if="showSummary">
            {{ ad.summary }}
          </div>
        </div>
        <b-button-group class="w-100 text-right">
          <b-button
            class="btn view-btn pt-2"
            :style="customBandStyles"
            @click.stop="addAd()"
          >
            {{ translation('select') }}
          </b-button>
        </b-button-group>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import AdCardHeader from '@/components/ads/AdCardHeader'
import PriceBlock from '@/components/ads/PriceBlock'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdSelectItem',
  components: { PriceBlock, AdCardHeader },
  props: ['ad'],
  data () {
    return {
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'isMobileView']),
    ...mapGetters('clients', ['customBandStyles']),
    showSummary () {
      return this.ad.id_type === 'i'
    }
  },
  methods: {
    ...mapActions('aggregatableAds', ['addSelected']),
    addAd () {
      this.addSelected(this.ad)
      this.$emit('change', this.ad)
    }
  }
}
</script>

<style lang="scss" scoped>
.distance-label {
  font-size: 0.9rem;
}

.view-btn {
  &:hover {
    opacity: 0.7;
  }
}
</style>
