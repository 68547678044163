<template>
  <div id="ad-select" class="mt-3">
    <b-card no-body class="select-card overflow-hidden border-0" v-if="ad">
      <b-row no-gutters>
        <b-col md="3">
          <b-card-img :src="ad.preview" alt=""/>
          <div class="price-block mt-3">
            <div class="mb-3">
              <span class="price-label">{{ translation('amount_of_your_purchase') }}:</span> <span class="current-price">{{ selectedPrice | toCurrency }}</span>
            </div>
            <div v-if="totalValue > selectedPrice" class="mb-3">
              <span class="price-label">{{ translation('gifts_valued_at') }}:</span> {{ giftValue | toCurrency }}
            </div>
            <div v-if="youSavePercent > 0" class="mb-3">
              <span class="price-label">{{ translation('you_get_a_discount_of') }}:</span> {{ youSavePercent | percent(0) }}
            </div>
            <div v-if="totalValue > selectedPrice" class="mb-3">
              <span class="price-label">{{ translation('value_including_gifts') }}:</span> {{ totalValue | toCurrency }}
            </div>
          </div>
          <div
            v-if="!invited"
            class="btn add-to-cart-btn d-flex"
            :style="buttonStyles(hovering)"
          >
            <div class="form-inline">
              <count-select :count="itemsCount" :max="maxUnits" @change="changeCount"/>
            </div>
            <div
              class="ml-2"
              @click.stop="addProductToCart"
            >
              <font-awesome-icon icon="cart-plus" class="mt-1"/>
              <span class="ml-2">{{ translation('add_to_cart') }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="9">
          <b-card-body :title="title" class="px-0 px-md-4 pt-4 pt-md-0">
            <ad-card-header :ad="ad"/>
            <item-description :text="ad.description" :summary="ad.large_summary"/>
            <div v-if="selectedAds.length > 0" class="mt-4">
              <h4>Tus regalos</h4>
              <b-row>
                 <ad-selected-item
                   v-for="sel of selectedAds"
                   :key="sel.id_ad"
                   :ad="sel"
                   @select="setSelected"
                   @change="setSelected(null)"
                 />
              </b-row>
            </div>
            <ad-selected-item-details
              v-if="selected"
              :ad="selected"
            />
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <ad-select-list v-if="ad" :parentAd="ad"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdCardHeader from '@/components/ads/AdCardHeader'
import AdSelectList from '@/components/ads/AdSelectList'
import AdSelectedItem from '@/components/ads/AdSelectedItem'
import AdSelectedItemDetails from '@/components/ads/AdSelectedItemDetails'
import CountSelect from '@/components/cart/CountSelect'
import { scroller } from 'vue-scrollto/src/scrollTo'
import ItemDescription from '@/components/common/ItemDescription.vue'

export default {
  name: 'AdSelect',
  data () {
    return {
      showDescription: false,
      hovering: false,
      selectedCount: {},
      selected: null
    }
  },
  components: { ItemDescription, CountSelect, AdSelectedItemDetails, AdSelectedItem, AdSelectList, AdCardHeader },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('ads', ['ads', 'currentAd']),
    ...mapGetters('auth', ['invited']),
    ...mapGetters('clients', ['buttonStyles', 'client']),
    ...mapGetters('aggregatableAds', ['selectedAds', 'selectedList', 'totalValue', 'youSaveValue', 'selectedChanged', 'selectedPrice', 'currentCount', 'giftValue', 'youSavePercent']),
    ad () {
      return this.currentAd
    },
    title () {
      return `Al comprar "${this.ad.name}" puedes seleccionar regalos con tu compra`
    },
    maxUnits () {
      return this.ad.stock
    },
    itemsCount: {
      get () {
        return this.currentCount
      },
      set (value) {
        this.setCurrentCount(value)
      }
    }
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('ads', ['initAds', 'setDoNotSelectGifts']),
    ...mapActions('aggregatableAds', ['setCurrentCount', 'resetSelected']),
    ...mapActions('clients', ['initClient']),
    ...mapActions('cart', ['addToCart']),
    addProductToCart: function () {
      // console.log('price: ', this.ad.org_to_pay)
      this.addToCart({
        id: this.ad.id_ad,
        price: parseFloat(this.ad.org_to_pay),
        count: this.itemsCount,
        includedGifts: this.selectedList
      })
        .then(() => {
          this.$router.back()
        })
      // var message = this.translation('added_to_cart')
      // message_box1(this.translation('information'), message.replace('%s', this.ad,name), {timeout: 1500, type: 'success'})
    },
    setSelected (ad) {
      this.selected = ad === null || this.selected === ad ? null : ad
    },
    changeCount: function (count) {
      this.itemsCount = count
    },
    gotoTop () {
      const scroll = scroller()
      scroll('#top-div')
    }
  },
  created () {
    if (!this.clientInfo) {
      this.initClient(this.$route.params.client)
    }
    this.resetSelected()
      .then(() => {
        this.initAds({
          id_ad: this.$route.params.ad
        })
      })
    this.setSidebarVisibility(false)
    this.setDoNotSelectGifts(false)
  },
  mounted () {
    this.gotoTop()
  }
}
</script>

<style lang="scss" scoped>
  .price-label {
    font-size: 0.9rem;
  }

  .current-price {
    color: #840b02;
  }

  .add-to-cart-btn {
    &:hover {
      opacity: 0.7;
    }
  }
</style>
