<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <div>
      <sales-card
        v-for="(sale, idx) of sales"
        :key="idx"
        :sale="sale"/>
    </div>
    <infinite-loading @infinite="infiniteHandler" :identifier="salesInfiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-overlay>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import SalesCard from '@/components/sales/SalesCard'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SalesList',
  components: { SalesCard, InfiniteLoading },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('sales', ['sales', 'salesInfiniteId']),
    ...mapGetters('clients', ['clientInfo'])
  },
  created () {
    this.initClient(this.$route.params.id)
      .then(() => {
        this.initSales()
      })
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('sales', ['initSales', 'loadSales']),
    infiniteHandler ($state) {
      this.loadSales()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
