export default {
  'SET_SALES' (state, sales) {
    state.sales = sales
  },
  'ADD_SALES' (state, sales) {
    sales.forEach(ad => {
      state.sales.push(ad)
    })
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'SET_ORDER' (state, order) {
    state.order = order
  },
  'SET_ORDER_FIELD' (state, orderField) {
    state.orderField = orderField
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.salesInfiniteId += 1
  }
}
