<template>
  <b-modal
    id="reservation-modal"
    title="Reservar"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div v-if="voucher">
      <div class="mb-3">
        {{ voucher.ad_info.name }}
      </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text>
                <font-awesome-icon icon="user-alt"/>
              </b-input-group-text>
            </template>
            <div class="form-control static-text text-truncate">
              {{ voucher.owner_info.full_name }}
            </div>
          </b-input-group>
        </b-form-group>
        <b-form-row>
          <b-col cols="8">
            <b-form-group>
              <b-form-datepicker
                v-model="reservationDate"
                placeholder="Seleccione la fecha"
                :min="min"
                :max="max"
                locale="es"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group>
              <b-form-timepicker
                v-model="reservationTime"
                placeholder="Seleccione la hora"
                label-close-button="Cerrar"
                locale="es"/>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group
          :state="codeState"
          label="Código"
          label-for="code-input"
        >
          <b-form-input
            id="code-input"
            v-model="code"
            :state="codeState"
            required
            @input="resetStatus"
          />
          <b-form-invalid-feedback v-if="!code">
            El código es obligatorio
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="wrongCode">
            El código es incorrecto
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        v-if="!busy"
        size="sm"
        variant="success"
        @click="ok()"
      >
        <font-awesome-icon icon="check"/>
        Reservar
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        <font-awesome-icon icon="ban"/>
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ReservationModal',
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // one year
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 12)
    maxDate.setDate(15)

    return {
      min: today,
      max: maxDate,
      reservationDate: today,
      reservationTime: '9:00',
      code: '',
      codeState: null,
      wrongCode: false
    }
  },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('vouchers', ['currentVoucher']),
    voucher () {
      return this.currentVoucher
    },
    reservation () {
      const date = moment(this.reservationDate).format('YYYY-MM-DD')
      return moment(`${date} ${this.reservationTime}`).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    ...mapActions('vouchers', ['initVouchers', 'reserveVoucher']),
    resetStatus () {
      this.wrongCode = false
    },
    resetModal () {
      this.codeState = null
      this.wrongCode = false
      if (this.voucher && this.voucher.reservation && this.voucher.reservation !== '0000-00-00 00:00:00') {
        const reservationArray = this.voucher.reservation.split(' ')
        this.reservationDate = reservationArray[0]
        this.reservationTime = reservationArray[1]
      } else {
        this.reservationDate = moment().format('YYYY-MM-DD')
        this.reservationTime = '9:00'
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      if (!this.code) {
        this.codeState = false
      } else {
        this.codeState = null
      }
      return valid && this.codeState !== false
    },
    handleSubmit () {
      if (!this.checkFormValidity()) {
        return
      }
      this.$bvModal.hide('reservation-modal')
      const data = {
        id: this.voucher.id_voucher,
        reservationDate: this.reservationDate,
        reservationTime: this.reservationTime,
        code: this.code
      }
      this.reserveVoucher(data)
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.success === 'true') {
            this.initVouchers()
            this.resetModal()
            this.makeToast(data.heading, data.message, 'success')
            this.$nextTick(() => {
              this.$bvModal.hide('reservation-modal')
            })
          } else {
            this.wrongCode = this.code.length > 0
            this.codeState = false
            if (data.heading && data.message) {
              this.makeToast(data.heading, data.message, 'danger')
            }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
