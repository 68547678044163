import Vue from 'vue'

export default {
  setCategories: ({ commit }, categories) => {
    commit('SET_CATEGORIES', categories)
  },
  setSelectedCategories: ({ commit, getters }, selectedCategories) => {
    commit('SET_SELECTED_CATEGORIES', selectedCategories)
  },
  addSelected: ({ commit }, id) => {
    commit('ADD_SELECTED', id)
  },
  removeSelected: ({ commit }, id) => {
    commit('REMOVE_SELECTED', id)
  },
  initCategories: ({ commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      Vue.http.post(rootGetters.siteUrl + '/admin/product_categories/getCategoriesTree')
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get categories!'))
        })
        .then(data => {
          commit('SET_CATEGORIES', data.categories)
          commit('SET_BUSY', false, { root: true })
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get categories!'))
        })
    })
  },
  updateCategories: ({ commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const params = {
        categories: JSON.stringify(getters.categoriesList)
      }
      Vue.http.post(rootGetters.siteUrl + '/admin/product_categories/updateCategories', params)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not update categories!'))
        })
    })
  }
}
