<template>
  <div class="purchase-card">
    <div class="d-block d-sm-none mb-3">
      <b-img :src="item.image" class="mb-3 w-100" :alt="item.name"></b-img>
      <div class="d-flex justify-content-between">
        <div class="">
          <div class="mb-2">
            <b-link href="#">{{ item.name }}</b-link>
          </div>
          <div class="mb-2">
            <span>{{ translation('owner') }}: {{ item.commercial_name }}</span>
          </div>
        </div>
      </div>
      <b-card no-body>
        <b-card-body class="pb-0">
          <purchase-ad
            v-for="ad of item.validations.ads"
            :key="ad.id_ad"
            :item="ad"
          />
        </b-card-body>
      </b-card>
    </div>
    <b-media class="d-none d-sm-block mb-3">
      <template #aside>
        <b-img :src="item.image" width="120" :alt="item.name"></b-img>
      </template>
      <div class="d-flex justify-content-between">
        <div class="">
          <div class="mb-2">
            <b-link href="#">{{ item.name }}</b-link>
          </div>
          <div class="mb-2">
            <span>{{ translation('owner') }}: {{ item.commercial_name }}</span>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <purchase-ad
          v-for="ad of item.validations.ads"
          :key="ad.id_ad"
          :item="ad"
        />
      </div>
    </b-media>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PurchaseAd from '@/components/purchases/PurchaseAd'

export default {
  name: 'PurchasePack',
  components: { PurchaseAd },
  props: ['item'],
  computed: {
    ...mapGetters(['translation'])
  }
}
</script>

<style scoped>

</style>
