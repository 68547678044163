import Vue from 'vue'

export default {
  setApproved: ({ commit }, approved) => {
    commit('SET_APPROVED', approved)
  },
  setApprovedCount: ({ commit }, approvedCount) => {
    commit('SET_APPROVED_COUNT', approvedCount)
  },
  setToApprove: ({ commit }, toApprove) => {
    commit('SET_TO_APPROVE', toApprove)
  },
  setToApproveCount: ({ commit }, toApproveCount) => {
    commit('SET_TO_APPROVE_COUNT', toApproveCount)
  },
  setPending: ({ commit }, pending) => {
    commit('SET_PENDING', pending)
  },
  setPendingCount: ({ commit }, pendingCount) => {
    commit('SET_PENDING_COUNT', pendingCount)
  },
  initSummary: ({ commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getSummary', { user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get summary!'))
        })
        .then(data => {
          commit('SET_APPROVED_COUNT', data.summary.approved)
          commit('SET_TO_APPROVE_COUNT', data.summary.to_approve)
          commit('SET_PENDING_COUNT', data.summary.pending)
          commit('users/SET_CURRENT_USER', data.user_id, { root: true })
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get summary!'))
        })
    })
  },
  initApproved: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_APPROVED_PAGE', 0)
      commit('UPDATE_APPROVED_INFINITE_ID')
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getApproved', getters.approvedRequestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get approved relations!'))
        })
        .then(data => {
          commit('SET_APPROVED', data.approved)
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get approved relations!'))
        })
    })
  },
  loadApprovedPage: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_APPROVED_PAGE', getters.approvedPage + 1)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getApproved', getters.approvedRequestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get approved relations!'))
        })
        .then(data => {
          commit('ADD_APPROVED', data.approved)
          resolve(data.approved)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get approved relations!'))
        })
    })
  },
  initToApprove: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_TO_APPROVE_PAGE', 0)
      commit('UPDATE_TO_APPROVE_INFINITE_ID')
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getToApprove', getters.toApproveRequestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get relations to be approved!'))
        })
        .then(data => {
          commit('SET_TO_APPROVE', data.to_approve)
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get relations to be approved!'))
        })
    })
  },
  loadToApprovePage: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_TO_APPROVE_PAGE', getters.toApprovePage + 1)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getToApprove', getters.toApproveRequestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get relations to be approved!'))
        })
        .then(data => {
          commit('ADD_TO_APPROVE', data.to_approve)
          resolve(data.to_approve)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get relations to be approved!'))
        })
    })
  },
  initPending: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', 0)
      commit('UPDATE_PENDING_INFINITE_ID')
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getPending', getters.pendingRequestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get pending relations!'))
        })
        .then(data => {
          commit('SET_PENDING', data.pending)
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get pending relations!'))
        })
    })
  },
  loadPendingPage: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', getters.pendingPage + 1)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getPending', getters.pendingRequestData)
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get pending relations!'))
        })
        .then(data => {
          commit('ADD_PENDING', data.pending)
          resolve(data.pending)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get pending relations!'))
        })
    })
  },
  createRequest: ({ commit, dispatch, rootGetters }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', 0)
      // dispatch('refreshSuggested')
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/request', { user_id: user.user_id, user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not request a relation!'))
        })
        .then(data => {
          // commit('REMOVE_SUGGESTED', user.user_id)
          dispatch('initSummary')
          dispatch('updateSuggested', {
            user_id: user.user_id,
            is_approved: false,
            is_rejected: false,
            is_pending: true,
            is_to_be_approved: false,
            no_relation: false
          })
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not request a relation!'))
        })
    })
  },
  cancelRequest: ({ commit, dispatch, rootGetters }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', 0)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/cancel', { user_id: user.user_id, user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not cancel a relation!'))
        })
        .then(data => {
          commit('CANCEL_PENDING', user.user_id)
          dispatch('updateSuggested', {
            user_id: user.user_id,
            is_approved: false,
            is_rejected: false,
            is_pending: false,
            is_to_be_approved: false,
            no_relation: true
          })
          dispatch('initSummary')
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not cancel a relation!'))
        })
    })
  },
  removeRelation: ({ commit, dispatch, rootGetters }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', 0)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/cancel', { user_id: user.user_id, user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not cancel a relation!'))
        })
        .then(data => {
          commit('CANCEL_APPROVED', user.user_id)
          dispatch('updateSuggested', {
            user_id: user.user_id,
            is_approved: false,
            is_rejected: false,
            is_pending: false,
            is_to_be_approved: false,
            no_relation: true
          })
          dispatch('initSummary')
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not cancel a relation!'))
        })
    })
  },
  rejectRequest: ({ commit, dispatch, rootGetters }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', 0)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/reject', { user_id: user.user_id, user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not cancel a relation!'))
        })
        .then(data => {
          commit('REMOVE_TO_APPROVE', user.user_id)
          dispatch('updateSuggested', {
            user_id: user.user_id,
            is_approved: false,
            is_rejected: true,
            is_pending: false,
            is_to_be_approved: false,
            no_relation: false
          })
          dispatch('initSummary')
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not cancel a relation!'))
        })
    })
  },
  approveRequest: ({ commit, dispatch, rootGetters }, user) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      commit('SET_PENDING_PAGE', 0)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/approve', { user_id: user.user_id, user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not approve a relation!'))
        })
        .then(data => {
          commit('REMOVE_TO_APPROVE', user.user_id)
          dispatch('updateSuggested', {
            user_id: user.user_id,
            is_approved: true,
            is_rejected: false,
            is_pending: false,
            is_to_be_approved: false,
            no_relation: false
          })
          dispatch('initSummary')
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not approve a relation!'))
        })
    })
  },
  setSuggested: ({ commit }, suggested) => {
    commit('SET_SUGGESTED', suggested)
  },
  initSuggested: ({ commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true)
      Vue.http.post(rootGetters.siteUrl + '/admin/relations/getSuggestions', { user_at_wall: rootGetters['clients/client'] })
        .then(response => {
          commit('SET_BUSY', false)
          return response.json()
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get suggested relations!'))
        })
        .then(data => {
          commit('SET_SUGGESTED', data.users)
          commit('SET_BUSY', false)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false)
          reject(new Error('Could not get suggested relations!'))
        })
    })
  },
  updateSuggested: ({ getters, commit }, user) => {
    const suggested = getters.suggestedById(user.user_id)
    if (suggested) {
      for (const field in user) {
        // eslint-disable-next-line no-prototype-builtins
        if (user.hasOwnProperty(field)) {
          suggested[field] = user[field]
        }
      }
    }
  },
  removeSuggested: ({ commit }, userId) => {
    commit('REMOVE_SUGGESTED', userId)
  },
  refreshSuggested: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('SET_SUGGESTED', [])
      dispatch('initSuggested')
        .then(response => {
          resolve(response)
        }, () => {
          reject(new Error('Could not get suggested relations!'))
        })
    })
  },
  setBusy: ({ commit }, busy) => {
    commit('SET_BUSY', busy)
  }
}
