<template>
  <b-card class="mt-3" no-body>
    <b-card-header>
      <h4 class="mb-0">{{ ad.name }}</h4>
    </b-card-header>
    <b-card-body>
      <b-card no-body class="overflow-hidden border-0" v-if="ad">
        <b-row no-gutters>
          <b-col md="4">
            <b-img :src="ad.preview" alt="" thumbnail/>
          </b-col>
          <b-col md="8">
            <b-card-body>
              <ad-card-header :ad="ad" :nameMargin="false"/>
              <div class="description"
                   v-if="showDescription"
                   v-html="ad.description"
              />
              <div class="summary"
                   v-if="!showDescription"
              >
                {{ ad.large_summary }}
                <b-link v-if="ad.description.length > summaryLength" @click="showDescription = !showDescription">
                  ver más...
                </b-link>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-card-body>
  </b-card>
</template>

<script>
import AdCardHeader from '@/components/ads/AdCardHeader'
import { mapGetters } from 'vuex'

export default {
  name: 'AdSelectedItemDetails',
  components: { AdCardHeader },
  props: ['ad'],
  data () {
    return {
      showDescription: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'summaryLength'])
  }
}
</script>

<style scoped>

</style>
