<template>
  <div
    v-show="visible"
    v-if="users.length > 0"
    class="suggested-users mb-3"
  >
    <splide
      id="suggested-splide"
      :options="splideOptions"
    >
      <splide-slide
        v-for="user of users"
        :key="user.user_id"
      >
        <suggested-user-card :user="user"/>
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { mapActions, mapGetters } from 'vuex'
import SuggestedUserCard from '@/components/clients/SuggestedUserCard'

export default {
  name: 'SuggestedUsers',
  components: {
    SuggestedUserCard,
    Splide,
    SplideSlide
  },
  data () {
    return {
      options: {
        direction: 'rtl',
        perPage: 5,
        autoplay: true
      }
    }
  },
  mounted () {
    this.initSuggested()
      .then(() => {
        // console.log('users: ', this.suggested)
      })
  },
  computed: {
    ...mapGetters(['windowWidth']),
    ...mapGetters('users', ['selectedUser']),
    ...mapGetters('relations', ['suggested']),
    users () {
      const users = []
      this.suggested.forEach(user => {
        if (user.image) {
          users.push(user)
        }
      })
      return users
    },
    visible () {
      return this.selectedUser === null
    },
    splideOptions () {
      // console.log('this.windowWidth: ', this.windowWidth)
      let perPage = 2
      if (this.windowWidth > 2200) {
        perPage = 7
      } else if (this.windowWidth > 1200) {
        perPage = 5
      } else if (this.windowWidth > 1000) {
        perPage = 4
      } else if (this.windowWidth > 500) {
        perPage = 3
      }
      return {
        direction: 'rtl',
        perPage: perPage,
        autoplay: true
      }
    }
  },
  methods: {
    ...mapActions('relations', ['initSuggested'])
  }
}
</script>

<style lang="scss" scoped>
  @import "~@splidejs/splide/dist/css/themes/splide-default.min.css";

  #suggested-splide {
    margin-left: -1rem;
    margin-right: -1rem;
  }
</style>
