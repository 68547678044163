<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <h4 class="my-3">Escoge tus regalos</h4>
    <aggregatable-filters-bar/>
    <google-map :markers="markers"/>
    <b-row>
      <ad-select-item
        v-for="(ad, idx) of aggregatableAds"
        :key="idx"
        :ad="ad"
        @change="filterAds"
      />
    </b-row>
    <infinite-loading @infinite="infiniteHandler" :identifier="adsInfiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import AdSelectItem from '@/components/ads/AdSelectItem'
import AggregatableFiltersBar from '@/components/ads/AggregatableFiltersBar'
import GoogleMap from '@/components/maps/GoogleMap'

export default {
  name: 'AdSelectList',
  components: { GoogleMap, AggregatableFiltersBar, AdSelectItem, InfiniteLoading },
  props: ['parentAd'],
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('aggregatableAds', ['aggregatableAds', 'adsInfiniteId', 'markers', 'giftValue']),
    priceLimit () {
      return this.parentAd.price - this.parentAd.promo_price - this.giftValue
    }
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('aggregatableAds', ['initAggregatableAds', 'loadAggregatableAds', 'resetFilters']),
    infiniteHandler ($state) {
      this.loadAggregatableAds({
        exclude: this.parentAd.id_ad
      })
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    },
    filterAds () {
      this.initAggregatableAds({
        exclude: this.parentAd.id_ad
      })
    }
  },
  created () {
    this.resetFilters()
    this.initAggregatableAds({
      exclude: this.parentAd.id_ad
    })
  }
}
</script>

<style scoped>

</style>
