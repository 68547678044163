import Vue from 'vue'

export default {
  initPurchases: ({ commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', 0)
      commit('UPDATE_INFINITE_ID')
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/admin/sales/get_purchases`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get purchases!'))
        })
        .then(data => {
          commit('SET_PURCHASES', data.purchases)
          resolve(data)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get purchases!'))
        })
    })
  },
  loadPurchases: ({ getters, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      commit('SET_PAGE', getters.page + 1)
      commit('SET_INFO_WINDOW_OPEN', false, { root: true })
      Vue.http.post(`${rootGetters.siteUrl}/admin/sales/get_purchases`, getters.requestData)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          return response.json()
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get purchases!'))
        })
        .then(data => {
          commit('ADD_PURCHASES', data.purchases)
          resolve(data.purchases)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not get purchases!'))
        })
    })
  },
  setCurrentAd: ({ commit }, ad) => {
    commit('SET_CURRENT_AD', ad)
  },
  setFilters: ({ commit, dispatch }, filters) => {
    commit('SET_FILTERS', filters)
    dispatch('initSales')
  },
  setOrder: ({ commit, dispatch }, order) => {
    commit('SET_ORDER', order)
    dispatch('initSales')
  },
  setOrderField: ({ commit, dispatch }, orderField) => {
    commit('SET_ORDER_FIELD', orderField)
    dispatch('initSales')
  },
  transfer: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        ...payload
      }
      Vue.http.post(`${rootGetters.siteUrl}/admin/sales/transfer`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not transfer ad!'))
        })
    })
  },
  validateAd: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        code: payload.code
      }
      Vue.http.post(`${rootGetters.siteUrl}/order/validate`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not validate ad!'))
        })
    })
  },
  reserveAd: ({ commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true, { root: true })
      const data = {
        id_client: rootGetters['clients/client'],
        user_at_wall: rootGetters['clients/client'],
        id_voucher: payload.id,
        code: '',
        reservation_date: payload.reservationDate,
        reservation_time: payload.reservationTime
      }
      Vue.http.post(`${rootGetters.siteUrl}/order/reserve`, data)
        .then(response => {
          commit('SET_BUSY', false, { root: true })
          resolve(response)
        }, () => {
          commit('SET_BUSY', false, { root: true })
          reject(new Error('Could not reserve ad!'))
        })
    })
  }
}
