import { render, staticRenderFns } from "./SalesPanel.vue?vue&type=template&id=7a27b5fc&scoped=true&"
import script from "./SalesPanel.vue?vue&type=script&lang=js&"
export * from "./SalesPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a27b5fc",
  null
  
)

export default component.exports