export default {
  currentUser: 0,
  selectedUser: null,
  users: [],
  usersCount: 0,
  perPage: 16,
  page: 0,
  infiniteId: +new Date() + 100000,
  filters: {
    name: '',
    activity: '',
    distance: '',
    city: '',
    not_collaborating: 1
  },
  filterValues: {
    cities: []
  }
}
