export default {
  'SET_ADS' (state, ads) {
    state.ads = ads
  },
  'SET_CURRENT_AD' (state, ad) {
    state.currentAd = ad
  },
  'ADD_ADS' (state, ads) {
    ads.forEach(ad => {
      state.ads.push(ad)
    })
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'RESET_FILTERS' (state, filters) {
    state.filters = {
      name: '',
      distance: null,
      categories: []
    }
  },
  'SET_ORDER' (state, payload) {
    state.orderFields[payload.section].order = payload.order
  },
  'SET_ORDER_FIELD' (state, payload) {
    state.orderFields[payload.section].field = payload.field
  },
  'SET_AD_TYPES' (state, adTypes) {
    state.adTypes = adTypes
  },
  'SET_ONLY_ALLOWING_GIFTS' (state, value) {
    state.onlyAllowingGifts = value
  },
  'SET_ALLOWED_AT_GIFTS' (state, value) {
    state.allowedAtGifts = value
  },
  'SET_ALLOWED_AT_MARKETPLACE' (state, value) {
    state.allowedAtMarketplace = value
  },
  'SET_ONLY_VISIBLE_AT_MY_WALLS' (state, value) {
    state.onlyVisibleAtMyWalls = value
  },
  'SET_DO_NOT_SELECT_GIFTS' (state, value) {
    state.doNotSelectGifts = value
  },
  'SET_SHOW_PROMO_PRICE' (state, value) {
    state.showPromoPrice = value
  },
  'SET_ADS_COUNT' (state, value) {
    state.adsCount = value
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'UPDATE_INFINITE_ID' (state) {
    state.adsInfiniteId += 1
  },
  'UPDATE_IMAGE' (state, value) {
    state.currentAd.image = value
  },
  'UPDATE_VIEWING_ADD' (state, value) {
    // console.log('updateViewingAd ', value)
    state.viewingAd = value
  },
  'UPDATE_AD' (state, payload) {
    if (payload.id) {
      const ad = state.ads.find(ad => parseInt(ad.id_ad) === parseInt(payload.id))
      for (const field in payload) {
        if (field !== 'id') {
          // console.log('field updated ', payload.id)
          ad[field] = payload[field]
        }
      }
    }
  }
}
