<template>
  <div
    v-if="showMap && markers.length > 0"
    class="mb-3"
  >
    <gmap-map
      :center='center'
      :zoom='12'
      style='width:100%;  height: 400px;'
    >
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false">
        <ad-marker :ad="adInfo"/>
      </gmap-info-window>
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :icon="{ url: m.icon }"
        :ref="`marker${index}`"
        @click="toggleInfoWindow(m, index)"
      />
    </gmap-map>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdMarker from '@/components/ads/AdMarker'

export default {
  name: 'GoogleMap',
  components: { AdMarker },
  props: ['markers'],
  data () {
    return {
      center: null,
      adInfo: null,
      infoWindowPos: null,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  computed: {
    ...mapGetters(['location', 'infoWindowOpen', 'showMap']),
    infoWinOpen: {
      set (value) {
        this.setInfoWindowOpen(value)
      },
      get () {
        return this.infoWindowOpen
      }
    }
  },
  created () {
    this.center = {
      lat: this.location.latitude,
      lng: this.location.longitude
    }
  },
  mounted () {
    this.infoWinOpen = false
  },
  methods: {
    ...mapActions(['setInfoWindowOpen']),
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position
      this.adInfo = marker.adInfo

      if (this.currentMidx === idx) {
        // check if its the same marker that was selected if yes toggle
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
      this.center = marker.position
    }
  }
}
</script>

<style scoped>

</style>
