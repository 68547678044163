<template>
  <b-modal
    v-if="terms"
    id="terms-modal"
    size="lg"
    :title="translation('terms_of_use')"
  >
    <div v-html="terms"/>
    <template v-slot:modal-footer="{ cancel }">
      <b-button
        size="sm"
        @click="cancel()"
      >
        {{ translation('close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TermsModal',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('clients', ['terms'])
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
