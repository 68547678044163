import { render, staticRenderFns } from "./PurchasesPanel.vue?vue&type=template&id=8d038b82&scoped=true&"
import script from "./PurchasesPanel.vue?vue&type=script&lang=js&"
export * from "./PurchasesPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d038b82",
  null
  
)

export default component.exports