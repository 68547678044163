export default {
  promos: state => {
    return state.promos
  },
  promosCount: state => {
    return state.promosCount
  },
  perPage: state => {
    return state.perPage
  },
  infiniteId: state => {
    return state.infiniteId
  },
  page: state => {
    return state.page
  },
  requestData: (state, getters) => {
    return {
      ...getters.filters,
      promo_yes: 1,
      offset: getters.page * getters.perPage,
      per_page: getters.perPage
    }
  },
  filters: state => {
    return state.filters
  },
  busy: state => {
    return state.busy
  }
}
