import mixins from '@/mixins'

export default {
  production: state => {
    return state.production
  },
  siteUrl: state => {
    return state.production ? state.siteUrl : state.testingUrl
  },
  fullUrl: (state) => (url) => {
    return `${state.siteUrl}/${url}`
  },
  homeUrl: (state) => {
    return state.homeUrl
  },
  showFilters: state => {
    return state.showFilters
  },
  showListFilters: state => {
    return state.showListFilters
  },
  isMobileView: state => {
    return state.windowWidth <= 500
  },
  isTabletView: state => {
    return state.windowWidth <= 1200
  },
  windowHeight: state => {
    return state.windowHeight
  },
  windowWidth: state => {
    return state.windowWidth
  },
  navbarHeight: state => {
    return state.navbarHeight
  },
  footerHeight: state => {
    return state.footerHeight
  },
  containerHeight: state => {
    return state.windowHeight - state.footerHeight - state.navbarHeight
  },
  siteName: state => {
    return state.siteName
  },
  language: state => {
    return state.language
  },
  imageUrl: (state) => (image) => {
    if (String(image).search(/^https?:\//) === -1) {
      // console.log('imageUrl: ', image)
      return state.siteUrl + '/' + image
    }
    return image
  },
  location: state => {
    return state.location
  },
  translations: state => {
    return state.translations
  },
  translation: state => (name) => {
    return mixins.hasKey(state.translations, name) ? state.translations[name] : ''
  },
  infoWindowOpen: state => {
    return state.infoWindowOpen
  },
  showMap: state => {
    return state.showMap
  },
  showCategories: state => {
    return state.showCategories
  },
  contentPadding: state => {
    if (state.sideBarVisible) {
      return `${state.sidebarWidth + 20}px`
    }
    return '1rem'
  },
  sidebarWidth: state => {
    return state.sidebarWidth
  },
  activePage: state => {
    return state.activePage
  },
  sideBarActive: state => {
    return state.sideBarActive
  },
  sideBarVisible: state => {
    return state.windowWidth > 768 ? state.sideBarVisible : false
  },
  busy: state => {
    return state.busy
  },
  isRouterAlive: state => {
    return state.isRouterAlive
  },
  summaryLength: state => {
    return state.summaryLength
  },
  slug: state => {
    return state.slug
  },
  theme: state => {
    return state.theme
  },
  noAuth: state => {
    return state.noAuth
  },
  exclusiveSite: state => {
    return state.exclusiveSite
  }
}
