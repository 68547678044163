export default {
  ads: state => {
    return state.ads
  },
  currentAd: state => {
    return state.currentAd
  },
  getById: (state) => (id) => {
    return state.ads.find(ad => parseInt(ad.id_ad) === parseInt(id))
  },
  markers: state => {
    const result = []
    state.ads.forEach(ad => {
      if (parseFloat(ad.gps_latitude) || parseFloat(ad.gps_longitude)) {
        const marker = {
          position: {
            lat: parseFloat(ad.gps_latitude),
            lng: parseFloat(ad.gps_longitude)
          }
        }
        if (ad.icon) {
          marker.icon = ad.icon
        }
        marker.adInfo = ad
        result.push(marker)
      }
    })
    return result
  },
  filters: state => {
    return state.filters
  },
  requestData: (state, getters, rootState, rootGetters) => (payload) => {
    let filters
    if (payload && payload.id_ad) {
      filters = {
        id_ad: payload.id_ad
      }
    } else {
      if (payload) {
        filters = {
          ...payload,
          ...state.filters,
          offset: getters.page * getters.perPage,
          limit: getters.perPage
        }
      } else {
        filters = {
          ...state.filters,
          offset: getters.page * getters.perPage,
          limit: getters.perPage
        }
      }
      filters.id_type = getters.adTypes
      if (getters.onlyAllowingGifts === true) {
        filters.can_aggregate_yes = 1
        filters.allowed_at_gifts = 1
      } else if (getters.onlyAllowingGifts === false) {
        filters.can_aggregate_no = 1
      }
      if (getters.allowedAtMarketplace) {
        filters.allowed_at_marketplace = 1
      }
      filters.order = getters.order(payload.section)
      filters.order_field = getters.orderField(payload.section)
      if (getters.onlyVisibleAtMyWalls) {
        filters.only_visible_at_my_walls = getters.onlyVisibleAtMyWalls + 0
      }
    }
    filters.id_lang = rootGetters.language
    filters.user_at_wall = filters.id_client
    filters.id_client = rootGetters['clients/client']
    const location = rootGetters.location
    if (location) {
      filters.gps_latitude = location.latitude
      filters.gps_longitude = location.longitude
    }
    return filters
  },
  order: (state) => (section) => {
    // eslint-disable-next-line no-prototype-builtins
    return section && state.orderFields.hasOwnProperty(section) ? state.orderFields[section].order : ''
  },
  orderField: (state) => (section) => {
    // eslint-disable-next-line no-prototype-builtins
    return section && state.orderFields.hasOwnProperty(section) ? state.orderFields[section].field : ''
  },
  adTypes: state => {
    return state.adTypes
  },
  onlyAllowingGifts: state => {
    return state.onlyAllowingGifts
  },
  onlyVisibleAtMyWalls: state => {
    return state.onlyVisibleAtMyWalls
  },
  allowedAtGifts: state => {
    return state.allowedAtGifts
  },
  allowedAtMarketplace: state => {
    return state.allowedAtMarketplace
  },
  doNotSelectGifts: state => {
    return state.doNotSelectGifts
  },
  showPromoPrice: state => {
    return state.showPromoPrice
  },
  adsCount: state => {
    return state.adsCount
  },
  perPage: state => {
    return state.perPage
  },
  page: state => {
    return state.page
  },
  adsInfiniteId: state => {
    return state.adsInfiniteId
  },
  orderOptions: (state, getters, rootState, rootGetters) => (section) => {
    const options = []
    state.orderOptions.forEach(option => {
      if (section !== 'info' || option.value !== 'discount_amount') {
        options.push({
          value: option.value,
          text: rootGetters.translation(option.text)
        })
      }
    })
    return options
  },
  distanceOptions: (state, getters, rootState, rootGetters) => {
    const options = [...state.distanceOptions]
    options[0].text = rootGetters.translation(options[0].text)
    return options
  },
  viewingAd: state => {
    return state.viewingAd
  }
}
