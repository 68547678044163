<template>
  <b-modal
    id="product-validation-modal"
    title="Validar"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div>
      <div class="mb-3" v-if="product">
        {{ product.name }}
      </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col>
            <b-form-group
              label="Número de pedido"
              label-for="order-input"
            >
              <b-input
                id="order-input"
                v-model="order"
                required
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :state="codeState"
              label="Código"
              label-for="code-input"
            >
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="code"
                  :state="codeState"
                  id="code-input"
                  required
                  @input="resetStatus"
                />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="validateCode">
                    <font-awesome-icon icon="search"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback v-if="!code">
                El código es obligatorio
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="wrongCode">
                El código es incorrecto
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <div v-if="product">
          <hr>
          <product-found :product="product" :fullName="fullName"/>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        v-if="!busy && codeState"
        size="sm"
        variant="success"
        @click="ok()"
      >
        <font-awesome-icon icon="check"/>
        Validar
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        <font-awesome-icon icon="ban"/>
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductFound from '@/components/products/ProductFound'

export default {
  name: 'ProductValidationModal',
  components: { ProductFound },
  data () {
    return {
      code: '',
      codeState: null,
      wrongCode: false,
      order: '',
      fullName: ''
    }
  },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('products', ['currentProduct']),
    product () {
      return this.currentProduct
    }
  },
  methods: {
    ...mapActions('products', ['initProducts', 'validateProduct', 'checkIfValid', 'setCurrentProduct']),
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.codeState = valid
      return valid
    },
    resetModal () {
      this.fullName = ''
      this.code = ''
      this.order = ''
      this.codeState = null
      this.wrongCode = false
      this.setCurrentProduct(null)
    },
    resetStatus () {
      this.wrongCode = false
    },
    validateCode () {
      this.checkIfValid({ code: this.code, order: this.order })
        .then(data => {
          if (data.success === 'true') {
            this.fullName = data.owner_name
            this.codeState = true
          } else {
            this.wrongCode = true
            this.codeState = false
          }
        })
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$bvModal.hide('product-validation-modal')
      const data = {
        order: this.order,
        code: this.code
      }
      this.validateProduct(data)
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.success === 'true') {
            this.initProducts()
            this.resetModal()
            this.makeToast(data.heading, data.message, 'success')
            this.$nextTick(() => {
              this.$bvModal.hide('product-validation-modal')
            })
          } else {
            this.wrongCode = true
            this.codeState = false
            this.makeToast(data.heading, data.message, 'danger')
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
