<template>
  <b-nav-item
    v-if="clientInfo && adsCount > 0"
    :to="`/cart/${client}`"
    class="text-right"
  >
    <font-awesome-icon icon="cart-plus" class="mr-1"/>
    <span class="d-none d-sm-inline-block">{{ translation('my_selection') }} </span>
    <div class="d-inline-block">({{ totalAmount | toCurrency }})</div>
  </b-nav-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CartStatus',
  props: ['idClient'],
  computed: {
    ...mapGetters(['language', 'translation']),
    ...mapGetters('clients', ['client', 'clientInfo']),
    ...mapGetters('cart', ['adsCount', 'totalAmount'])
  },
  methods: {
    ...mapActions('cart', ['initAds'])
  },
  created () {
    if (String(this.$route.path).match(/\/cart\//) === null) {
      if (this.client) {
        this.initAds()
      }
    }
  }
}
</script>

<style scoped>

</style>
