export default {
  approved: state => {
    return state.approved
  },
  approvedCount: state => {
    return state.approvedCount
  },
  approvedPage: state => {
    return state.approvedPage
  },
  approvedInfiniteId: state => {
    return state.approvedInfiniteId
  },
  approvedRequestData: (state, getters, rootState, rootGetters) => {
    return {
      offset: getters.approvedPage * getters.perPage,
      per_page: getters.perPage,
      user_at_wall: rootGetters['clients/client']
    }
  },
  toApprove: state => {
    return state.toApprove
  },
  toApproveCount: state => {
    return state.toApproveCount
  },
  toApprovePage: state => {
    return state.toApprovePage
  },
  toApproveInfiniteId: state => {
    return state.toApproveInfiniteId
  },
  toApproveRequestData: (state, getters, rootState, rootGetters) => {
    return {
      offset: getters.toApprovePage * getters.perPage,
      per_page: getters.perPage,
      user_at_wall: rootGetters['clients/client']
    }
  },
  pending: state => {
    return state.pending
  },
  pendingCount: state => {
    return state.pendingCount
  },
  pendingPage: state => {
    return state.pendingPage
  },
  pendingInfiniteId: state => {
    return state.pendingInfiniteId
  },
  pendingRequestData: (state, getters, rootState, rootGetters) => {
    return {
      offset: getters.pendingPage * getters.perPage,
      per_page: getters.perPage,
      user_at_wall: rootGetters['clients/client']
    }
  },
  summary: state => {
    return state.summary
  },
  suggested: state => {
    return state.suggested
  },
  suggestedById: (state) => (id) => {
    return state.suggested.find(user => parseInt(user.user_id) === parseInt(id))
  },
  perPage: state => {
    return state.perPage
  },
  busy: state => {
    return state.busy
  }
}
