<template>
  <b-modal
    id="validation-modal"
    title="Validar"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div v-if="voucher">
      <div class="mb-3">
        {{ voucher.ad_info.name }}
      </div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text>
                <font-awesome-icon icon="user-alt"/>
              </b-input-group-text>
            </template>
            <div class="form-control static-text text-truncate">
              {{ voucher.owner_info.full_name }}
            </div>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :state="codeState"
          label="Código del voucher"
          label-for="code-input"
        >
          <b-form-input
            id="code-input"
            v-model="code"
            :state="codeState"
            required
            @input="resetStatus"
          />
          <b-form-invalid-feedback v-if="!code">
            El código es obligatorio
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="wrongCode">
            El código es incorrecto
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        v-if="!busy"
        size="sm"
        variant="success"
        @click="ok()"
      >
        <font-awesome-icon icon="check"/>
        Validar
      </b-button>
      <b-button
        size="sm"
        @click="cancel()"
      >
        <font-awesome-icon icon="ban"/>
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ValidationModal',
  data () {
    return {
      code: '',
      codeState: null,
      wrongCode: false
    }
  },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('vouchers', ['currentVoucher']),
    voucher () {
      return this.currentVoucher
    }
  },
  methods: {
    ...mapActions('vouchers', ['initVouchers', 'validateVoucher']),
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.codeState = valid
      return valid
    },
    resetModal () {
      this.code = ''
      this.codeState = null
      this.wrongCode = false
    },
    resetStatus () {
      this.wrongCode = false
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$bvModal.hide('validation-modal')
      const data = {
        id: this.voucher.id_voucher,
        code: this.code
      }
      this.validateVoucher(data)
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.success === 'true') {
            this.initVouchers()
            this.makeToast(data.heading, data.message, 'success')
            this.$nextTick(() => {
              this.$bvModal.hide('validation-modal')
            })
          } else {
            this.wrongCode = true
            this.codeState = false
            this.makeToast(data.heading, data.message, 'danger')
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
