export default {
  aggregatableAds: state => {
    return state.aggregatableAds
  },
  getById: (state) => (id) => {
    return state.aggregatableAds.find(ad => parseInt(ad.id_ad) === parseInt(id))
  },
  selectedAds: state => {
    return state.selectedAds
  },
  selectedCount: (state) => (id) => {
    const key = 'ad_' + id
    return Object.keys(state.selectedCount).includes(key) ? state.selectedCount[key] : 0
  },
  currentCount: state => {
    return state.currentCount
  },
  selectedList: (state, getters) => {
    const result = []
    state.selectedAds.forEach(ad => {
      result.push({
        id: ad.id_ad,
        count: getters.selectedCount(ad.id_ad)
      })
    })
    return result
  },
  selectedChanged: state => {
    return state.selectedChanged
  },
  giftValue: (state) => {
    let result = 0
    if (!state.selectedChanged) {
      state.selectedAds.forEach(ad => {
        const key = 'ad_' + ad.id_ad
        result += (ad.price * state.selectedCount[key])
      })
    }
    return result
  },
  giftPromoValue: (state) => {
    let result = 0
    if (!state.selectedChanged) {
      state.selectedAds.forEach(ad => {
        const key = 'ad_' + ad.id_ad
        const price = ad.promo_price > 0 ? ad.promo_price : ad.price
        result += (price * state.selectedCount[key])
      })
    }
    return result
  },
  selectedPrice: (state, getters, rootState, rootGetters) => {
    const currentAd = rootGetters['ads/currentAd']
    return currentAd ? currentAd.price * state.currentCount : 0
  },
  selectedPromoPrice: (state, getters, rootState, rootGetters) => {
    const currentAd = rootGetters['ads/currentAd']
    return currentAd ? currentAd.promo_price * state.currentCount : 0
  },
  maxPrice: (state, getters) => {
    return getters.selectedPrice - getters.selectedPromoPrice - getters.giftPromoValue
  },
  totalValue: (state, getters) => {
    return getters.selectedPrice + getters.giftValue
  },
  youSaveValue: (state, getters) => {
    return getters.selectedPrice - getters.giftValue
  },
  youSavePercent: (state, getters) => {
    return getters.giftValue / getters.selectedPrice
  },
  onlyVisibleAtMyWalls: state => {
    return state.onlyVisibleAtMyWalls
  },
  markers: state => {
    const result = []
    state.aggregatableAds.forEach(ad => {
      if (parseFloat(ad.gps_latitude) || parseFloat(ad.gps_longitude)) {
        const marker = {
          position: {
            lat: parseFloat(ad.gps_latitude),
            lng: parseFloat(ad.gps_longitude)
          }
        }
        if (ad.icon) {
          marker.icon = ad.icon
        }
        marker.adInfo = ad
        result.push(marker)
      }
    })
    return result
  },
  filters: state => {
    return state.filters
  },
  requestData: (state, getters, rootState, rootGetters) => (payload) => {
    let filters
    if (payload) {
      filters = {
        ...payload,
        ...state.filters,
        maxPrice: getters.maxPrice,
        aggregatable: 1,
        offset: getters.page * getters.perPage,
        limit: getters.perPage
      }
    } else {
      filters = {
        ...state.filters,
        maxPrice: getters.maxPrice,
        aggregatable: 1,
        offset: getters.page * getters.perPage,
        limit: getters.perPage
      }
    }
    filters.id_type = getters.adTypes
    filters.id_lang = rootGetters.language
    filters.order = getters.order
    filters.order_field = getters.orderField
    filters.only_visible_at_my_walls = getters.onlyVisibleAtMyWalls + 0
    filters.id_client = rootGetters['clients/client']
    const location = rootGetters.location
    if (location) {
      filters.gps_latitude = location.latitude
      filters.gps_longitude = location.longitude
    }
    return filters
  },
  order: state => {
    return state.order
  },
  orderField: state => {
    return state.orderField
  },
  adTypes: state => {
    return state.adTypes
  },
  perPage: state => {
    return state.perPage
  },
  page: state => {
    return state.page
  },
  adsInfiniteId: state => {
    return state.adsInfiniteId
  },
  orderOptions: (state, getters, rootState, rootGetters) => {
    const options = []
    state.orderOptions.forEach(option => {
      options.push({
        value: option.value,
        text: rootGetters.translation(option.text)
      })
    })
    return options
  },
  distanceOptions: (state, getters, rootState, rootGetters) => {
    const options = [...state.distanceOptions]
    options[0].text = rootGetters.translation(options[0].text)
    return options
  }
}
