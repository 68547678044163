<template>
  <address>
    <div
      v-if="showAddress"
      class="contact-content mb-2 d-flex"
    >
      <font-awesome-icon icon="map-marker"/>
      <b-link
        :href="googleMapsLink"
        target="_blank"
        class="ml-2"
      >
        {{ ad.address }}
      </b-link>
    </div>
    <div
      v-if="ad.phone_number"
      class="contact-content mb-2 d-flex">
      <font-awesome-icon icon="phone"/>
      <b-link
        :href="`tel:${ad.phone_number}`"
        class="ml-2"
      >
        {{ ad.phone_number }}
      </b-link>
    </div>
    <div
      v-if="ad.email"
      class="contact-content mb-2 d-flex"
    >
      <font-awesome-icon icon="envelope-open"/>
      <b-link
        :href="`mailto:${ad.email}`"
        class="ml-2"
      >
        {{ ad.email }}
      </b-link>
    </div>
    <div
      v-if="ad.url"
      class="contact-content mb-2 d-flex"
    >
      <font-awesome-icon icon="globe"/>
      <div class="ml-2">
        <a :href="ad.url" target="_blank">
          {{ linkName }}
        </a>
      </div>
    </div>
  </address>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdAddress',
  props: ['ad'],
  computed: {
    ...mapGetters(['translation']),
    linkName () {
      return this.ad && this.ad.link_name ? this.ad.link_name : this.translation('view_site')
    },
    showAddress () {
      return (Math.abs(this.ad.gps_latitude) > 0 || Math.abs(this.ad.gps_longitude) > 0) || this.ad.address.length > 0
    },
    googleMapsLink () {
      return `https://www.google.com/maps/search/${String(this.ad.address).replace(/\s/g, '+')}/@${this.ad.gps_latitude},${this.ad.gps_longitude}`
    }
  }
}
</script>

<style scoped>

</style>
