<template>
  <b-col cols="12" sm="6" md="4" lg="4" xl="3" class="mb-3">
    <b-card
      no-body
      border-variant="secondary"
      class="element-card"
      :img-src="getImageUrl(promo.image)"
    >
      <b-card-body class="info-panel">
        <div class="discount-badge" v-if="discount">
          {{ discount }}
        </div>
        <b-card-text>
          {{ promo.name }}
        </b-card-text>
        <b-card-text class="ad-info">
          <div>Precio: {{ promo.price_friendly }}</div>
          <div>Promo: <span class="promo-price">{{ promo.promo_price_friendly }}</span></div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PromoCard',
  props: ['promo'],
  computed: {
    ...mapGetters(['siteUrl']),
    discount () {
      if (this.promo.discount > 0) {
        return '-' + Math.round(this.promo.discount) + '%'
      }
      return ''
    }
  },
  methods: {
    getImageUrl (image) {
      return image ? this.siteUrl + '/' + image : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .element-card {
    position: relative;

    .discount-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background-color: #000000;
      color: #ffffff;
      text-align: center;
      padding: 20px 0;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
</style>
