<template>
  <b-card
    class="promos-list mt-3"
    v-if="promos.length > 0"
    no-body
  >
    <b-card-header>
      <h5 class="mb-0">Promociones</h5>
    </b-card-header>
    <b-card-body class="pb-0">
      <b-row>
        <promo-card
          v-for="promo of promos"
          :key="promo.id_route"
          :promo="promo"
        />
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import PromoCard from './PromoCard'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PromosList',
  components: { PromoCard },
  created () {
    this.setFilters({
      id_owner: this.selectedUser.user_id
    })
    this.initPromos()
  },
  computed: {
    ...mapGetters('promos', ['promos']),
    ...mapGetters('users', ['selectedUser'])
  },
  methods: {
    ...mapActions('promos', ['initPromos', 'setFilters'])
  }
}
</script>

<style scoped>

</style>
