<template>
  <div
    v-show="showListFilters"
    class="ad-filters"
  >
    <div class="d-flex justify-content-between flex-column flex-lg-row">
      <b-form class="mb-3" inline>
        <label class="sr-only" for="name-search">{{ translation('name') }}</label>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            v-model="name"
            id="name-search"
            :placeholder="searchPlaceholder"
            @input="updateFilters"
          />
          <b-input-group-append>
            <b-button variant="outline-success">
              <font-awesome-icon icon="search"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-btn
          class="mb-2 mr-sm-2 mb-sm-0"
          v-if="categoriesVisible && categories.length > 0"
          variant="outline-secondary"
          @click.stop="categoriesVisible = false"
        >
          {{ translation('hide_categories') }}
        </b-btn>
        <b-btn
          class="mb-2 mr-sm-2 mb-sm-0"
          v-if="!categoriesVisible && categories.length > 0"
          variant="outline-secondary"
          @click.stop="categoriesVisible = true"
        >
          {{ translation('show_categories') }}
        </b-btn>
        <b-input-group v-if="markers.length > 0">
          <b-select
            v-model="distance"
            :options="distanceOptions"
            @input="selectDistance"
          />
          <b-input-group-append class="ml-2">
            <b-btn
              v-if="mapVisible"
              variant="outline-secondary"
              @click.stop="mapVisible = false"
            >
              <font-awesome-icon icon="map-location"/>
              {{ translation('hide_map') }}
            </b-btn>
            <b-btn
              v-if="!mapVisible && markers.length > 0"
              variant="outline-secondary"
              @click.stop="mapVisible = true"
            >
              <font-awesome-icon icon="map-location-dot"/>
              {{ translation('show_map') }}
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <b-form class="mb-3" inline>
        <label>{{ translation('sort_by') }}:</label>
        <b-input-group>
          <b-select
            v-model="adsOrderField"
            :options="orderOptions"
            @input="updateOrderField"
            class="ml-0 ml-md-2"
          />
          <b-input-group-append class="ml-2">
            <b-btn
              v-if="order === 'ASC'"
              variant="outline-secondary"
              @click="updateOrder"
            >
              <font-awesome-icon icon="arrow-down-short-wide"/>
            </b-btn>
            <b-btn
              v-if="order === 'DESC'"
              variant="outline-secondary"
              @click="updateOrder"
            >
              <font-awesome-icon icon="arrow-up-wide-short"/>
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </div>
    <categories-selector v-if="categoriesVisible" @change="updateFilters"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoriesSelector from '@/components/categories/CategoriesSelector'

export default {
  name: 'AggregatableFiltersBar',
  components: { CategoriesSelector },
  data () {
    return {
      name: '',
      distance: null
    }
  },
  computed: {
    ...mapGetters(['location', 'translation', 'showMap', 'showCategories', 'showListFilters']),
    ...mapGetters('aggregatableAds', ['order', 'orderField', 'markers', 'distanceOptions', 'orderOptions']),
    ...mapGetters('categories', ['categories', 'selectedCategoriesList']),
    searchPlaceholder () {
      return this.translation('search') + '...'
    },
    adsOrderField: {
      set (value) {
        this.setOrderField(value)
      },
      get () {
        return this.orderField
      }
    },
    mapVisible: {
      set (value) {
        this.setShowMap(value)
      },
      get () {
        return this.showMap
      }
    },
    categoriesVisible: {
      set (value) {
        this.setShowCategories(value)
      },
      get () {
        return this.showCategories
      }
    }
  },
  methods: {
    ...mapActions(['setLocation', 'setShowMap', 'setShowCategories']),
    ...mapActions('aggregatableAds', ['setFilters', 'setOrder', 'setOrderField']),
    selectDistance () {
      if (!this.location) {
        this.setLocation()
          .then(() => {
            this.updateFilters()
          })
      } else {
        this.updateFilters()
      }
    },
    updateFilters () {
      const filters = {
        name: this.name.length > 2 ? this.name : '',
        distance: this.distance,
        categories: this.selectedCategoriesList
      }
      this.setFilters(filters)
    },
    updateOrder () {
      this.setOrder(this.order === 'ASC' ? 'DESC' : 'ASC')
    },
    updateOrderField () {
      this.updateFilters()
    }
  }
}
</script>

<style scoped>

</style>
