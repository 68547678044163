import Vue from 'vue'

export default {
  'SET_VOUCHERS' (state, vouchers) {
    state.vouchers = vouchers
  },
  'ADD_VOUCHERS' (state, vouchers) {
    vouchers.forEach((voucher) => {
      state.vouchers.push(voucher)
    })
  },
  'UPDATE_VOUCHER' (state, voucher) {
    if (voucher) {
      const key = state.vouchers.findIndex(v => v.id_voucher === voucher.id_voucher)
      if (key >= 0) {
        Vue.set(state.vouchers, key, voucher)
      }
    }
  },
  'DELETE_VOUCHER' (state, voucher) {
    if (voucher) {
      const idx = state.vouchers.findIndex(v => v.id_voucher === voucher.id_voucher)
      if (idx >= 0) {
        const vouchers = [...state.vouchers]
        vouchers.splice(idx, 1)
        Vue.set(state, 'vouchers', vouchers)
      }
    }
  },
  'SET_ORDER' (state, order) {
    state.order = order
  },
  'SET_ORDER_FIELD' (state, orderField) {
    state.orderField = orderField
  },
  'SET_CURRENT_VOUCHER' (state, voucher) {
    state.currentVoucher = voucher
  },
  'SET_VOUCHER_HOSTS' (state, voucherHosts) {
    state.voucherHosts = voucherHosts
  },
  'SET_INFLUENCERS' (state, influencers) {
    state.influencers = influencers
  },
  'SET_FILTERS' (state, filters) {
    state.page = 0
    state.filters = filters
  },
  'SET_FILTERS_LOCKED' (state, filtersLocked) {
    state.filtersLocked = filtersLocked
  },
  'SET_PAGE' (state, page) {
    state.page = page
  },
  'SET_PER_PAGE' (state, perPage) {
    state.perPage = perPage
  },
  'SET_SUMMARY' (state, summary) {
    state.summary = summary
  },
  'TOGGLE_FILTER' (state, filterName) {
    const value = state.filters[filterName]
    if (filterName !== 'pending') {
      state.filters.pending = false
    }
    if (filterName !== 'reserved') {
      state.filters.reserved = false
    }
    if (filterName !== 'didNotAssist') {
      state.filters.didNotAssist = false
    }
    if (filterName !== 'disabled') {
      state.filters.disabled = false
    }
    if (filterName !== 'completed') {
      state.filters.completed = false
    }
    if (filterName !== 'contracted') {
      state.filters.contracted = false
    }
    if (filterName !== 'rejected') {
      state.filters.rejected = false
    }
    state.filters[filterName] = !value
    state.activeFilter = state.filters[filterName] ? filterName : ''
  },
  'SET_TOGGLE_FILTER' (state, filter) {
    state.filters.pending = false
    state.filters.reserved = false
    state.filters.didNotAssist = false
    state.filters.disabled = false
    state.filters.completed = false
    state.filters.contracted = false
    state.filters.rejected = false
    state.filters[filter.name] = filter.value
  },
  'RESET_FILTERS' (state) {
    state.filters = {
      pagFilter: '',
      creator: 0,
      idClient: 0,
      voucherHost: 0,
      idOwner: 0,
      idAd: '',
      createdMinDate: '',
      createdMaxDate: '',
      sentMinDate: '',
      sentMaxDate: '',
      validatedMinDate: '',
      validatedMaxDate: '',
      reservedMinDate: '',
      reservedMaxDate: '',
      contractedMinDate: '',
      contractedMaxDate: '',
      pending: false,
      reserved: false,
      didNotAssist: false,
      disabled: false,
      completed: false,
      contracted: false,
      rejected: false
    }
  },
  'SET_FILTER_VALUE' (state, payload) {
    state.filters[payload.field] = payload.value
  },
  'SET_ACTIVE_FILTER' (state, filterName) {
    state.activeFilter = filterName
  },
  'SET_RESEND_SUCCESS' (state, resendSuccess) {
    state.resendSuccess = resendSuccess
  },
  'SET_REJECT_SUCCESS' (state, rejectSuccess) {
    state.rejectSuccess = rejectSuccess
  },
  'SET_RESERVATION_SUCCESS' (state, reservationSuccess) {
    state.reservationSuccess = reservationSuccess
  },
  'SET_VALIDATION_SUCCESS' (state, validationSuccess) {
    state.validationSuccess = validationSuccess
  },
  'SET_ONLY_MY_VOCHERS' (state, onlyMVouchers) {
    state.onlyMyVouchers = onlyMVouchers
  },
  'SET_ACTIVITY_OPTIONS' (state, activities) {
    state.activityOptions = activities
  },
  'SET_OWNER_OPTIONS' (state, owners) {
    state.ownerOptions = owners
  },
  'UPDATE_INFINITE_ID' (state) {
    state.infiniteId += 1
  }
}
