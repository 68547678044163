<template>
  <div
    class="category-item"
    :class="{ 'category-active': hovering, 'contains-activities': hasChildren }"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div
      class="category-link p-1"
      :class="{ 'top-category': parent === null }"
    >
      <b-checkbox
        v-if="category.contains_products"
        v-model="value"
        :name="`category${category.id}`"
        value="1"
        unchecked-value="0"
        class="category-check"
        @change="changeCategory"
      >
        {{ category.name }}
      </b-checkbox>
      <span v-if="!category.contains_products">
        {{ category.name }}
      </span>
    </div>
    <div
      v-if="hasChildren"
      class="sub-activities"
    >
      <category-item
        v-for="subCategory of category.children"
        :key="subCategory.category"
        :category="subCategory"
        :parent="category"
        @change="childChange(subCategory.id)"
        class="ml-3"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryItem',
  props: ['category', 'parent'],
  data () {
    return {
      value: 0,
      hovering: false
    }
  },
  computed: {
    ...mapGetters('categories', ['isSelected']),
    childrenCount () {
      return this.category.children ? this.category.children.length : 0
    },
    hasChildren () {
      return this.childrenCount > 0
    }
  },
  methods: {
    ...mapActions('categories', ['addSelected', 'removeSelected', 'setSelectedCategories']),
    changeCategory () {
      const id = parseInt(this.category.id)
      if (parseInt(this.value) === 1) {
        this.addSelected(id)
      } else {
        this.removeSelected(id)
      }
      this.$emit('change', id)
    },
    childChange (id) {
      this.$emit('change', id)
    }
  },
  created () {
    this.value = this.isSelected(parseInt(this.category.id)) + 0
  }
}
</script>

<style lang="scss" scoped>
  .top-category {
    font-weight: bold;
  }

  .selected {
    background-color: #88BD2F;
    color: #FFFFFF;
  }

  .category-link, .category-check {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  /*.category-check {
    input, label {
      &:hover {
        opacity: 0.7;
        cursor: grabbing;
      }
    }
  }*/
</style>
