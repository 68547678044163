<template>
  <div class="d-flex justify-content-between flex-column flex-lg-row">
    <b-form class="mb-3" inline>
      <label class="sr-only" for="name-search">{{ translation('name') }}</label>
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-input
          v-model="name"
          id="name-search"
          :placeholder="searchPlaceholder"
          @input="updateFilters"
        />
        <b-input-group-append>
          <b-button variant="outline-success">
            <font-awesome-icon icon="search"/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-form class="mb-3" inline>
      <label>{{ translation('sort_by') }}:</label>
      <b-input-group>
        <b-select
          v-model="adsOrderField"
          :options="orderOptions"
          @input="updateOrderField"
          class="ml-0 ml-md-2"
        />
        <b-input-group-append class="ml-2">
          <b-btn
            v-if="order === 'ASC'"
            variant="outline-secondary"
            @click="updateOrder"
          >
            <font-awesome-icon icon="arrow-down-short-wide"/>
          </b-btn>
          <b-btn
            v-if="order === 'DESC'"
            variant="outline-secondary"
            @click="updateOrder"
          >
            <font-awesome-icon icon="arrow-up-wide-short"/>
          </b-btn>
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PurchaseFiltersBar',
  data () {
    return {
      name: '',
      orderOptions: [
        { value: 'created', text: 'sell_date' },
        { value: 'total_paid', text: 'price' }
      ]
    }
  },
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('ads', ['order', 'orderField']),
    searchPlaceholder () {
      return this.translation('search') + '...'
    },
    adsOrderField: {
      set (value) {
        this.setOrderField(value)
      },
      get () {
        return this.orderField
      }
    }
  },
  methods: {
    ...mapActions('ads', ['setFilters', 'setOrder', 'setOrderField']),
    updateFilters () {
      const filters = {
        name: this.name.length > 2 ? this.name : ''
      }
      this.setFilters(filters)
    },
    updateOrder () {
      this.setOrder(this.order === 'ASC' ? 'DESC' : 'ASC')
    },
    updateOrderField () {
      this.updateFilters()
    }
  }
}
</script>

<style scoped>

</style>
