import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import VueResource from 'vue-resource'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue2Filters from 'vue2-filters'
import { library } from '@fortawesome/fontawesome-svg-core'
import PortalVue from 'portal-vue'

import {
  faUserAlt, faPhone, faEnvelope, faCalendar, faClock, faPrint, faCircle, faSearch, faCalendarAlt, faDesktop, faMapLocation, faMapLocationDot, faHouse,
  faCheckDouble, faBan, faCheck, faChevronLeft, faChevronRight, faSyncAlt, faMoneyBill, faTrashCan, faCartPlus, faEye, faArrowDownShortWide, faArrowUpWideShort,
  faLink, faUnlink, faPlusCircle, faUser, faEnvelopeSquare, faUserPlus, faMoneyBills, faCheckCircle, faCalendarDays, faCogs, faGift, faGifts, faUsers, faSignOut, faCircleInfo,
  faArrowUpFromBracket, faHandshakeSimple, faSackDollar, faCalendarCheck, faXmark, faDownload, faMapMarker, faEnvelopeOpen, faGlobe, faStore, faLock, faSliders, faEnvelopesBulk
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserAlt)
library.add(faPhone)
library.add(faEnvelope)
library.add(faCalendar)
library.add(faClock)
library.add(faPrint)
library.add(faCircle)
library.add(faSearch)
library.add(faCalendarAlt)
library.add(faDesktop)
library.add(faMapLocation)
library.add(faMapLocationDot)
library.add(faCheckDouble)
library.add(faBan)
library.add(faCheck)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faSyncAlt)
library.add(faMoneyBill)
library.add(faTrashCan)
library.add(faCartPlus)
library.add(faEye)
library.add(faArrowDownShortWide)
library.add(faArrowUpWideShort)
library.add(faLink)
library.add(faUnlink)
library.add(faPlusCircle)
library.add(faUser)
library.add(faEnvelopeSquare)
library.add(faUserPlus)
library.add(faMoneyBills)
library.add(faCheckCircle)
library.add(faCalendarDays)
library.add(faCogs)
library.add(faGift)
library.add(faGifts)
library.add(faUsers)
library.add(faSignOut)
library.add(faArrowUpFromBracket)
library.add(faHandshakeSimple)
library.add(faSackDollar)
library.add(faCalendarCheck)
library.add(faXmark)
library.add(faDownload)
library.add(faMapMarker)
library.add(faEnvelopeOpen)
library.add(faGlobe)
library.add(faStore)
library.add(faLock)
library.add(faSliders)
library.add(faEnvelopesBulk)
library.add(faCircleInfo)
library.add(faHouse)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(PortalVue)
Vue.use(VueResource)
Vue.http.options.emulateJSON = true

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false
  },
  number: {
    format: '0',
    thousandsSeparator: ',',
    decimalSeparator: '.'
  },
  bytes: {
    decimalDigits: 2
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: '.'
  },
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false
  },
  pluralize: {
    includeNumber: false
  },
  ordinal: {
    includeNumber: false
  }
}
Vue.use(Vue2Filters, Vue2FiltersConfig)

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
})

Vue.mixin({
  methods: {
    ...mixins,
    makeToast (title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: 'b-toaster-top-right',
        solid: true,
        autoHideDelay: 7000
      })
    }
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    key: store.state.googleMapsKey,
    libraries: 'places'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
