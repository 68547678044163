<template>
  <div class="product-found">
    <b-form-group v-if="product">
      <product-small-info :product="product"/>
    </b-form-group>
    <b-form-group>
      <b-input-group>
        <template v-slot:prepend>
          <b-input-group-text>
            <font-awesome-icon icon="user-alt"/>
          </b-input-group-text>
        </template>
        <div
          class="form-control static-text text-truncate"
        >
          {{ fullName }}
        </div>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import ProductSmallInfo from '@/components/products/ProductSmallInfo'
export default {
  name: 'ProductFound',
  props: ['product', 'fullName'],
  components: { ProductSmallInfo }
}
</script>

<style scoped>

</style>
