<template>
  <div id="to-approve-list">
    <b-row>
      <user-card
        v-for="user in toApproveList"
        :key="user.user_id"
        :user="user"
        :list-type="'to_approve'"
      />
    </b-row>
    <infinite-loading @infinite="infiniteHandler" :identifier="toApproveInfId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import UserCard from './UserCard'
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ToApproveList',
  components: { UserCard, InfiniteLoading },
  computed: {
    ...mapGetters('relations', ['toApprove', 'toApproveInfiniteId']),
    toApproveInfId () {
      return this.toApproveInfiniteId
    },
    toApproveList () {
      return this.toApprove
    }
  },
  methods: {
    ...mapActions('relations', ['loadToApprovePage']),
    infiniteHandler ($state) {
      this.loadToApprovePage()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
