export default {
  approved: [],
  approvedCount: 0,
  toApprove: [],
  toApproveCount: 0,
  pending: [],
  pendingCount: 0,
  suggested: [],
  perPage: 16,
  approvedPage: 0,
  approvedInfiniteId: +new Date(),
  toApprovePage: 0,
  toApproveInfiniteId: +new Date() + 200000,
  pendingPage: 0,
  pendingInfiniteId: +new Date() + 50000,
  busy: false
}
