<template>
  <b-overlay :show="busy" rounded="sm" variant="light">
    <b-row>
      <voucher-item
        v-for="(voucher, idx) of vouchers"
        :key="idx"
        :voucher="voucher"/>
    </b-row>
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-overlay>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

import { mapActions, mapGetters } from 'vuex'
import VoucherItem from '@/components/vouchers/VoucherItem'

export default {
  name: 'VouchersItemsList',
  components: { VoucherItem, InfiniteLoading },
  computed: {
    ...mapGetters(['busy']),
    ...mapGetters('vouchers', ['vouchers', 'infiniteId']),
    ...mapGetters('clients', ['clientInfo'])
  },
  created () {
    this.clearFilters()
    this.setOnlyMyVouchers(true)
    this.initClient(this.$route.params.id)
      .then(() => {
        this.initVouchers()
      })
  },
  methods: {
    ...mapActions('clients', ['initClient']),
    ...mapActions('vouchers', ['initVouchers', 'loadPage', 'setOnlyMyVouchers', 'clearFilters']),
    infiniteHandler ($state) {
      this.loadPage()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
