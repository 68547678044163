<template>
  <video-player class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                customEventName="customstatechangedeventname"

                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ended="onPlayerEnded($event)"
                @waiting="onPlayerWaiting($event)"
                @playing="onPlayerPlaying($event)"
                @loadeddata="onPlayerLoadeddata($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                @canplay="onPlayerCanplay($event)"
                @canplaythrough="onPlayerCanplaythrough($event)"

                @statechanged="playerStateChanged($event)"
                @ready="playerReadied"/>
</template>

<script>

// Similarly, you can also introduce the plugin resource pack you want to use within the component
// import 'some-videojs-plugin'

import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

import { videoPlayer } from 'vue-video-player'

export default {
  name: 'VideoPlayerItem',
  props: ['video'],
  components: { videoPlayer },
  data () {
    return {
      playerOptions: {
        // videojs options
        muted: true,
        autoplay: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          src: this.video
        }]/* ,
        poster: '/static/images/author.jpg' */
      }
    }
  },
  mounted () {
    // console.log('this is current player instance object', this.player)
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    // listen event
    onPlayerPlay (player) {
      // console.log('player play!', player)
    },
    onPlayerPause (player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged (playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied (player) {
      // console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerEnded (player) {
    },
    onPlayerWaiting (player) {
    },
    onPlayerPlaying (player) {
    },
    onPlayerLoadeddata (player) {
    },
    onPlayerTimeupdate (player) {
    },
    onPlayerCanplay (player) {
    },
    onPlayerCanplaythrough (player) {
    }
  }
}
</script>

<style lang="scss">
  .video-js {
    width: 100%;
  }
</style>
